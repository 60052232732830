<template lang="pug">
.step-progress__wrapper
  .step-progress__wrapper-before(
    :style='{"background-color": passiveColor, height: lineThickness + "px" }'
  )
  .step-progress__bar
    .step-progress__step(
      v-for='(step, index) in steps'
      @click="routerLinkTo(step)"
      @keyup.enter="routerLinkTo(step)"
      :style=`{
        "--activeColor" : activeColor,
        "--passiveColor" : passiveColor,
        "--activeBorder" : activeThickness + "px",
        "--passiveBorder" : passiveThickness + "px"
      }`
      :class=`{
        "step-progress__step--active": index === currentStep,
        "step-progress__step--valid": index < currentStep,
        "step-progress__step--not-allowed": !isNavigationAllowed(step),
      }`
    )
      span {{ index + 1 }}
      .step-progress__step-icon(:class='iconClass')
      //.step-progress__step-label {{ step }}
  .step-progress__wrapper-after(
    :style=`{
      transform: "scaleX(" + scaleX + ") translateY(-50%) perspective(1000px)",
      "background-color": activeColor,
      height: lineThickness + "px"
    }`
  )

</template>

<script>
export default {
  name: "StepProgress",
  props: {
    steps: {
      type: Array,
      default() {
        return [];
      },
      validator(val) {
        return val && val.length > 0;
      },
    },
    currentStep: {
      required: true,
      type: Number,
      default: 0,
    },
    iconClass: {
      type: String,
      default: "fa fa-check",
    },
    activeColor: {
      type: String,
      default: "var(--deepblue)",
    },
    passiveColor: {
      type: String,
      default: "#737373",
    },
    activeThickness: {
      type: Number,
      default: 3,
    },
    passiveThickness: {
      type: Number,
      default: 2,
    },
    lineThickness: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    isNavigationAllowed(step) {
      if (step === "InputProductInfo") {
        return true;
      }
      if (step === "InputReason") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category
        ) {
          return true;
        }
      }
      if (step === "InputProductNumbers") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting
        ) {
          return true;
        }
      }
      if (step === "InputWhereInfo") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting
        ) {
          return true;
        }
      }
      if (step === "InputContactInfo") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting &&
          this.$store.state.report.boughtWhere &&
          (this.$store.state.report.linkInfos.length ||
            this.$store.state.report.storeInfos.length)
        ) {
          return true;
        }
      }
    },
    routerLinkTo(step) {
      if (step === "InputProductInfo") {
        this.$router.push({ name: step });
      }
      if (step === "InputReason") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category
        ) {
          this.$router.push({ name: step });
        }
      }
      if (step === "InputProductNumbers") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting
        ) {
          this.$router.push({ name: step });
        }
      }
      if (step === "InputWhereInfo") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting
        ) {
          this.$router.push({ name: step });
        }
      }
      if (step === "InputContactInfo") {
        if (
          this.$store.state.report.productName &&
          this.$store.state.report.productCategory.category &&
          this.$store.state.report.reasonForReporting &&
          this.$store.state.report.boughtWhere &&
          (this.$store.state.report.linkInfos.length ||
            this.$store.state.report.storeInfos.length)
        ) {
          this.$router.push({ name: step });
        }
      }
    },
  },
  computed: {
    scaleX() {
      let step = this.currentStep;
      if (step < 0) {
        step = 0;
      } else if (step >= this.steps.length) {
        step = this.steps.length - 1;
      }
      return step / (this.steps.length - 1);
    },
  },
};
</script>

<style scoped lang="sass">
.step-progress
  &__wrapper
    margin: 0 5%
    position: relative

  &__wrapper-before
    content: ''
    position: absolute
    left: 0
    top: 50%
    height: 12px
    width: 100%
    background-color: gray
    transform: translateY(-50%) perspective(1000px)

  &__wrapper-after
    content: ''
    position: absolute
    left: 0
    top: 50%
    height: 12px
    width: 100%
    background-color: red
    transform: scaleX(0) translateY(-50%) perspective(1000px)
    transform-origin: left center
    transition: transform .5s ease

  &__bar
    width: 100%
    display: flex
    height: 100px
    justify-content: space-between
    align-items: center

  &__step
    z-index: 2
    position: relative
    cursor: pointer
    --activeColor: red
    --passiveColor: gray
    --activeBorder: 5px
    --passiveBorder: 5px
    span
      color: var(--passiveColor)
      transition: .3s ease
      display: block
      font-size: 28px !important
      transform: translate3d(0,0,0) scale(0.8) perspective(1000px)
      font-weight: 900
      text-align: center
      opacity: 1

      @media (max-width: 991.98px)
        font-size: 24px !important
      @media (max-width: 767.98px)
        font-size: 28px !important
      @media (max-width: 479.98px)
        font-size: 20px !important

    &--active
      span
        color: var(--activeColor)
      .step-progress__step-label
        color: var(--activeColor)
      .step-progress__step-icon
        opacity: 1
    &--valid
      .step-progress__step-icon
        opacity: 1
        transform: translate3d(-50%, -50%, 0) scale(0.8) perspective(1000px)
      span
        color: white
        opacity: 1
        transform: translate3d(0, 0, 0) scale(0.8) perspective(1000px)
      .step-progress__step-label
        color: var(--activeColor)
    &--not-allowed
      cursor: not-allowed
    &--allowed
      cursor: pointer
    &:after
      content: ""
      position: absolute
      z-index: -1
      left: 50%
      top: 50%
      transform: translate(-50%, -50%) perspective(1000px)
      width: 50px
      height: 50px
      background-color: #fff
      border-radius: 50%
      border: var(--passiveBorder) solid var(--passiveColor)
      transition: .3s ease

      @media (max-width: 991.98px)
        width: 40px
        height: 40px
      @media (max-width: 767.98px)
        width: 50px
        height: 50px
      @media (max-width: 479.98px)
        width: 35px
        height: 35px

    &--active:after
      border: var(--activeBorder) solid var(--activeColor)
    &--valid:after
      background-color: var(--activeColor)
      border: var(--activeBorder) solid var(--activeColor)

  &__step-label
    position: absolute
    top: calc(100% + 25px)
    left: 50%
    transform: translateX(-50%) perspective(1000px)
    white-space: nowrap
    font-size: 18px
    font-weight: 600
    color: gray
    transition: .3s ease

  &__step-icon
    font-size: 22px
    color: #fff
    position: absolute
    left: 50%
    top: 50%
    transition: transform .3s ease
    opacity: 0
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px)

    @media (max-width: 991.98px)
      font-size: 19px
    @media (max-width: 767.98px)
      font-size: 22px
    @media (max-width: 479.98px)
      font-size: 16px
</style>
