<template>
  <div
    id="footer"
    role="complementary"
    :class="{
      'footer-img-container': true,
      'hide-during-report': hidden,
    }"
  >
    <div class="row g-4 footer-text-container">
      <div class="col-sm-8 text-start mb-4">
         <img
          :src="require('/src/assets/graphics/produkterdkSIK_Logo_DARK.svg')"
          alt="baggrund_halvcirkel_footer.svg"
          style="height: 50px"
          />
          <div class="web-description col-md-4 pt-3"> {{ footerContent.description }}</div>
      </div>
        <div class="col-sm-2 text-start mb-4">
          <ArrowTitle
            :title="footerContent.title_column_01"
            fontSize="1em"
            paddingTop="1px"
            pixelSize="25px"
            class="mb-2"
          />
          <p>{{ footerContent.name }}</p>
          <p>{{ footerContent.address }}</p>
          <p>{{ footerContent.zip }}</p>
          <a
            :href="'mailto:' + footerContent.email"
            :aria-label="'Sikkerhedsstyrelsens e-mail: ' + footerContent.email"
            ><p>{{ footerContent.email }}</p></a
          >
          <a
            :href="'tel:' + footerContent.phone"
            :aria-label="
              'Sikkerhedsstyrelsens telefonnummer: ' + footerContent.phone
            "
          >
            <p>{{ footerContent.phone }}</p>
          </a>
        </div>
        <div class="col-sm-2 text-start">
          <ArrowTitle
            :title="footerContent.title_column_03"
            fontSize="1em"
            paddingTop="1px"
            pixelSize="25px"
            class="mb-2"
          />
          <!-- TODO: error if just do .data from start -->
          <div
            v-for="data in footerContent.sub_pages"
            :key="data.name"
          >
          <div
          class="info-links-footer"
          v-for="subPage in data"
          :key="subPage.name"
          >
          <router-link
          :to="{ name: subPage.attributes.name }"
          :aria-label="subPage.attributes.display_name"
          ><p>{{ subPage.attributes.display_name }}</p></router-link
          >
        </div>
          <div
            class="info-links-footer"
            v-html="footerContent.accessability"
          ></div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";
import { useStore } from "vuex";

export default {
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  components: { ArrowTitle },
  setup() {
    const store = useStore();

    const {
      strapiData: footerContent,
      error: errorFooterContent,
      load: loadFooterContent,
    } = getStrapiData("footer-content");

    if (!store.state.general.footerContent.length > 0) {
      loadFooterContent().then(() => {
        store.commit("general/setFooterContent", {
          footerContent: footerContent.value,
        });
      });
    }

    return { errorFooterContent };
  },
  computed: {
    footerContent: {
      get() {
        return this.$store.state.general.footerContent;
      },
    },
  },
};
</script>

<style lang="scss">
#footer {
  color: black;
  background-color: var(--white);
  width: 100%;
  padding: 40px 5% 30px 5%;
}
#footer p {
  margin: 0 0 0 0;
  font-size: 0.85em;
}
#footer a {
  font-weight: bold;
  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}
.footer-img-container {
  position: relative;
  display: inline-block;
  width: 100%;
  border-top: 10px solid var(--deepblue);
  z-index: 1;
}
.footer-img-container .background-image-footer {
  pointer-events: none;
  position: absolute;
  max-height: 90%;
  max-width: 50%;
  z-index: -1;
  left: 50%;
  bottom: 0;
}
.web-description {
  font-family: Sora-light;  
  font-size: 0.85em;
}
@media only screen and (max-width: 575.98px) {
  .col-sm.text-center {
    text-align: left !important;
  }
  .col-sm.text-end {
    text-align: left !important;
  }
  .footer-img-container {
    border-top: none;
    border-bottom: 10px solid var(--deepblue);
  }
  .footer-img-container .background-image-footer {
    display: none;
  }
}
@media only screen and (min-width: 576px) {
  .col-sm.mb-4 {
    margin-bottom: 0 !important;
  }
}
</style>
