<template>
  <FetchError :onlyText="true" v-if="error" />
  <div v-else>
    <div v-if="infoCards.length">
      <div class="info-card-list wide-container">
        <div class="row" >
          <div
            v-for="(infoCard, index) in sorted(infoCards)"
            :key="index"
            class="col-md-4"
            :class="{'between-cards': notFirst(index)}"
          >
            <ContentInfoCard :infoCard="infoCard" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>Henter data...</div>
  </div>
</template>

<script>
import ContentInfoCard from "./ContentInfoCard.vue";
import getStrapiData from "@/composables/getStrapiDataCollection";
import FetchError from "@/components/FetchError.vue";
import { useStore } from "vuex";

export default {
  components: { ContentInfoCard, FetchError },
  setup() {
    const store = useStore();

    const { strapiData: infoCards, error, load } = getStrapiData(
      "content-info-cards"
    );

    if (!store.state.content.infoCards.length > 0) {
      load().then(() => {
        store.commit("content/setInfoCards", { infoCards: infoCards.value });
      });
    }
    return { error };
  },
  methods: {
    notFirst(index) {
      return index;
    },
    sorted(array) {
      return array.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
  computed: {
    infoCards: {
      get() {
        return this.$store.state.content.infoCards;
      },
    },
  },
};
</script>

<style>
.info-card-list .col-md-4 .info-card-body {
  background: var(--lightblue);
  color: black;
  height: 340px;
}

.info-card-list {
  max-width: 100%;
  justify-content: center;
  display: flex;
}

.between-cards {
  padding-left: 7px !important;
}

@media (max-width: 1600px) {
  .info-card-list .col-md-4 .info-card-body {
  min-height: 310px;
}
}
</style>
