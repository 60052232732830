<template>
  <div class="card product-card">
    <div v-if="productImageUrl" class="card-img-container">
      <img :src="productImageUrl" alt="Produktbillede" class="card-img" />
    </div>
    <div v-else class="card-img-container">
      <img
        :src="require('/src/assets/graphics/placeholder.svg')"
        alt="Placeholder, produktbillede"
        class="card-img"
      />
    </div>

    <div class="card-img-overlay">
      <div
        :class="{
          'product-compliance': true,
          'product-compliance-dangerous': productCard.complianceType.name.includes(
            'Farlig'
          ),
        }"
      >
        {{ productCard.complianceType.name }}
      </div>
    </div>
    <div>
      <p class="product-name">{{ productCard.name }}</p>
      <p class="product-type">
        Kategori:
        {{
          productCategoryName
        }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["productCard"],
  data() {
    return {
      productImageUrl: "",
    };
  },
  mounted() {
    if (this.productCard.productPictures.length > 0) {
      this.productCard.productPictures.forEach((element) => {
        if (element.productPictureCategory.name === "BILLPROD") {
          if (this.productImageDict[element.pictureId]) {
            this.productImageUrl = this.productImageDict[element.pictureId];
          } else {
            this.getProductPicture(element.pictureId);
          }
        }
      });
    }
  },
  methods: {
    getProductPicture(pictureId) {
      axios
        .get(process.env.VUE_APP_PRODUCTS_API + "picture/" + pictureId, {
          responseType: "blob",
        })
        .then((response) => {
          this.productImageUrl = URL.createObjectURL(response.data);
          this.$store.commit("products/addImageUrl", {
            id: pictureId,
            url: this.productImageUrl,
          });
        })
        .catch((error) => {
          console.log(
            "Error from " +
              process.env.VUE_APP_PRODUCTS_API +
              "picture/" +
              pictureId,
            error
          );
          this.productImageUrl = "";
        });
    },
  },
  computed: {
    productCategoryName() {
      if(this.productCard.productCategoryNames.find(obj => obj !== undefined)) {
        return this.productCard.productCategoryNames.filter(obj => obj !== undefined).join(', ');
      } else {
        return "Ikke angivet";
      }
    },
    productImageDict: {
      get() {
        return this.$store.state.products.productImageDict;
      },
    },
  },
};
</script>

<style lang="scss">
.card.product-card {
  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}
.product-compliance {
  position: absolute;
  color: white;
  font-family: sora-semibold;
  font-size: 0.85em;
  padding: 3px 10px;
  left: 0;
  top: 0;
  background: var(--mediumblue);
  border-radius: 0 0 10px 0;
}
.product-compliance-dangerous {
  background: var(--deepblue);
}
.product-name {
  font-size: 1em;
  padding: 15px 0 0 0;
  margin: 0;
  font-family: sora-semibold;
}
.product-type {
  font-size: 0.85em;
  padding: 5px 0 0 0;
  margin: 0;
  color: black;
}
.product-card .card-img {
  border-radius: 0px;
}
.card-img-container {
  position: relative;
  width: 100%;
  padding-bottom: 85%;
}
.card-img-container .card-img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}
</style>
