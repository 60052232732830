<template>
  <div class="fact-box">
    <ArrowTitle class="mb-2" :title="title" />
    <div v-html="body" class="inherit-link-styling"></div>
  </div>
</template>

<script>
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";
export default {
  props: {
    title: {
      type: String,
      default: "Titel",
    },
    body: {
      type: String,
      default: "<p>Beskrivelse</p>",
    },
  },
  components: { ArrowTitle },
};
</script>

<style lang="scss">
.fact-box {
  background-color: var(--lightgreyopaque);
  padding: 20px;
  margin-top: 50px;
}
</style>
