<template>
  <div class="page-not-found-container" role="main">
    <div class="row d-flex justify-content-center">
      <img
        :src="require('/src/assets/graphics/Pagenotfound_illu.svg')"
        alt="Illustration, siden ikke fundet"
        style="width: 80%; max-height: 50vh"
      />
    </div>
    <div v-if="!onlyImg" style="max-width: 100vh; margin: auto">
      <h1 class="page-title" style="margin-top: 50px; text-align: center">
        {{ errorTitle }}
      </h1>
      <p class="page-description" style="text-align: center">
        {{ errorMsg }}
      </p>
    </div>
    <div v-if="!onlyImg" class="d-flex justify-content-center">
      <slot>
        <button
          type="button"
          class="btn btn-primary"
          :aria-label="btnText"
          @click="$router.go()"
          @keyup.enter="$router.go()"
        >
          {{ btnText }}
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorTitle: {
      type: String,
      default: "Der er sket en teknisk fejl",
    },
    errorMsg: {
      type: String,
      default:
        "Der er desværre sket en teknisk fejl. Du kan prøve at genindlæse siden her: ",
    },
    onlyImg: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "Genindlæs siden",
    },
  },
};
</script>
