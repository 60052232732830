//Initialize default state for product listings
const getDefaultState = () => {
  return {
    infoCards: [],
  };
};

// Define state (allows for reset of state to default state)
const state = getDefaultState();

const mutations = {
  setInfoCards(state, payload) {
    state.infoCards = payload.infoCards;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default { namespaced: true, state, mutations, actions };
