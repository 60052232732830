//Initialize default state for product listings
const getDefaultState = () => {
  return {
    page: 1,
    productsPerPage: 36,
    totalNumberProducts: 0,
    searchTerm: "",
    filtersInput: {
      complianceFilters: [],
      categoryFilters: [],
      authorityFilters: [],
    },
    productCards: {},
    complianceDict: {},
    categoryDict: {},
    productImageDict: {},
  };
};

// Define state (allows for reset of state to default state)
const state = getDefaultState();

const mutations = {
  setPage(state, payload) {
    state.page = payload.page;
  },
  setTotalNumberProducts(state, payload) {
    state.totalNumberProducts = payload.totalNumberProducts;
  },
  setSearchTerm(state, payload) {
    state.searchTerm = payload.searchTerm;
  },
  setComplianceFilters(state, payload) {
    state.filtersInput.complianceFilters = payload.complianceFilters;
  },
  setCategoryFilters(state, payload) {
    state.filtersInput.categoryFilters = payload.categoryFilters;
  },
  setAuthorityFilters(state, payload) {
    state.filtersInput.authorityFilters = payload.authorityFilters;
  },
  setProductCards(state, payload) {
    state.productCards = payload.productCards;
  },
  setComplianceDict(state, payload) {
    state.complianceDict = payload.complianceDict;
  },
  setCategoryDict(state, payload) {
    state.categoryDict = payload.categoryDict;
  },
  addImageUrl(state, payload) {
    state.productImageDict[payload.id] = payload.url;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default { namespaced: true, state, mutations, actions };
