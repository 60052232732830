<template>
  <div class="arrow-svg-container">
    <button
      @click="navigatePrevious"
      @keyup.enter="navigatePrevious"
      :class="{ 'btn-previous': true, opaque: isFirst }"
      type="button"
      aria-label="Forrige"
    >
      <svg
        version="1.1"
        id="reportBackBtn"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 62 70"
        style="enable-background: new 0 0 62 70; transform: rotate(180deg)"
        xml:space="preserve"
      >
        <g>
          <line class="st0" x1="61.9" y1="35" x2="-0.3" y2="35" />
          <path class="st0" d="M61.9,35c-13.8,0-25,11.2-25,25" />
          <path class="st1" d="M61.9,35c-13.8,0-25-11.2-25-25" />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    navigatePrevious() {
      if (this.$route.name === "InputContactInfo") {
        this.$router.push({ name: "InputWhereInfo" });
      } else if (this.$route.name === "InputWhereInfo") {
        this.$router.push({ name: "InputProductNumbers" });
      } else if (this.$route.name === "InputProductNumbers") {
        this.$router.push({ name: "InputReason" });
      } else if (this.$route.name === "InputReason") {
        this.$router.push({ name: "InputProductInfo" });
      }
    },
  },
  computed: {
    isFirst() {
      return this.$route.name === "InputProductInfo";
    },
  },
};
</script>

<style>
.btn-previous {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.btn-previous.opaque {
  opacity: 0.5;
  cursor: default !important;
}
.btn-previous .st0 {
  fill: none;
  stroke: #6ebfff;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
.btn-previous .st1 {
  fill: none;
  stroke: #0038f0;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
.arrow-svg-container {
  max-width: 50px;
  margin: 0 auto !important;
}
</style>
