// Import all stores
import { createStore } from "vuex";
import report from "./report/reportStore.js";
import legislation from "./legislation/legislationStore.js";
import products from "./products/productsStore.js";
import content from "./content/contentStore.js";
import general from "./general/generalStore.js";

// Create Vuex store with named modules
const store = createStore({
  modules: {
    report,
    legislation,
    products,
    content,
    general,
  },
});

export default store;
