<template>
  <div
    class="label-with-help"
    style="display: block !important; margin-top: 30px !important"
  >
    <label>{{ strapiData.title }}</label>
  </div>
  <label class="sub-label">{{ strapiData.description }}</label>
  <div class="input-with-help" style="background-color: transparent !important">
    <!-- Using ref to set fileInput to null after each change event, thereby allowing user to add file, remove file, and add the SAME file again -->
    <label class="upload-files" style="cursor: pointer; margin: 0px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        fill="currentColor"
        class="bi bi-plus custom-file-input"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>
      <input
        type="file"
        ref="fileInput"
        @change="chooseFiles"
        multiple
        style="display: none"
        name="file"
        :accept="acceptedFileTypes"
      />
    </label>
    <HelpBtn :helpText="strapiData.title_help" style="margin-left: auto" />
  </div>

  <label class="sub-label" style="margin-bottom: 20px">{{
    strapiData.description_file_types
  }}</label>
  <div v-if="fileList.length">
    <div class="choices-with-help">
      <label class="your-choices">{{ strapiData.attached_title }}</label>
      <HelpBtn :helpText="strapiData.attached_help" />
    </div>
  </div>
  <div v-for="displayChosenFile in fileList" :key="displayChosenFile.name">
    <div class="remove-choices align-items-center d-flex">
      <div class="added-choices">
        <button
          type="button"
          class="added-choices-open-file"
          :aria-label="'Åbn filen ' + displayChosenFile.name"
          @click="openFile(displayChosenFile)"
          style="
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
          "
        >
          {{ displayChosenFile.name }}
        </button>
      </div>
      <button
        class="btn btn-close btn-sm"
        type="button"
        :aria-label="'Fjern filen' + displayChosenFile.name"
        @click="removeChosenFile(displayChosenFile, strapiData.remove_message)"
        @keyup.enter="
          removeChosenFile(displayChosenFile, strapiData.remove_message)
        "
      ></button>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import HelpBtn from "@/components/widgets/HelpBtn.vue";

export default {
  emits: ["updatefiles"],
  props: ["fileList"],
  components: { HelpBtn },
  data() {
    return {
      chosenFiles: this.fileList,
      fileSizeLimit: 25, // in MiB
      totalSize: 0,
      acceptedFileTypes: [
        "image/*",
        "video/*",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.oasis.opendocument.text",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
        "text/csv",
        ".heif",
        ".heic",
      ],
      acceptedFileExtensions:[ // Since .heif and .heic files are not recognized as mime-types by the browser, we need to specifically denote that they are OK. 
        ".heif",
        ".heic",
      ]
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("file-upload");
    load();
    return { strapiData, error };
  },
  methods: {
    isFileTypeValid(file) {
      // Check MIME type of files (extension check too easily fooled)
      let extension = "." + file.name.split('.').pop();
      console.log(extension);

      return file.type.split("/")[0] === "image" ||
        file.type.split("/")[0] === "video" || // Split to handle wild-cards in acceptedFileTypes
        this.acceptedFileTypes.includes(file.type) || 
        this.acceptedFileExtensions.includes(extension)
    },
    chooseFiles(event) {
      for (const file of event.target.files) {
        const fileSize = file.size / 1024 / 1024; // fileSize in MiB
        this.totalSize += fileSize;
        // Check if file is one of the accepted types
        if (!this.isFileTypeValid(file)) {
          alert(
            'Filen "' + file.name + '" er ikke af en type, som vi understøtter.'
          );
          this.totalSize -= fileSize;
          // Check if file is above size limit
        } else if (fileSize > this.fileSizeLimit) {
          alert(
            'Størrelsen på filen "' +
              file.name +
              "(" +
              fileSize.toFixed(2) +
              " MB)" +
              '" overskrider grænsen for upload på ' +
              this.fileSizeLimit +
              " MB."
          );
          this.totalSize -= fileSize;
          // Check if file makes total size exceed limit
        } else if (this.totalSize > this.fileSizeLimit) {
          alert(
            'Størrelsen på filen "' +
              file.name +
              '" (' +
              fileSize.toFixed(2) +
              " MB) gør, at størrelsen på dine vedhæftede filer samlet (" +
              this.totalSize.toFixed(2) +
              " MB) overskrider grænsen for upload på " +
              this.fileSizeLimit +
              " MB."
          );
          this.totalSize -= fileSize;
        } else {
          this.chosenFiles.push(file);
          console.log("Adding file: ", file);
        }
      }
      this.$emit("updatefiles", this.chosenFiles);
      this.$refs.fileInput.value = null;
    },
    removeChosenFile(chosenFile, message) {
      if (confirm(message)) {
        this.chosenFiles = this.chosenFiles.filter((item) => {
          return chosenFile !== item;
        });
        this.totalSize -= chosenFile.size / 1024 / 1024; // fileSize in MiB
        console.log("Removing file: ", chosenFile);
        this.$emit("updatefiles", this.chosenFiles);
      }
    },
    openFile(file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
  },
};
</script>

<style>
.upload-files {
  display: inline-block;
  width: fit-content;
}
.choices-with-help {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
}
.added-choices-open-file:hover {
  color: var(--deepblue);
  transform: scale(1.025);
}
</style>
