<template>
  <div class="product-info" role="main">
    <FetchError
      v-if="errorSingleProduct"
      errorMsg="Der er desværre sket en teknisk fejl, eller også eksisterer produktet med det givne ID ikke i vores database. Du kan prøve at genindlæse siden eller gå tilbage til listevisningen her:"
    >
      <button
        type="button"
        class="btn btn-primary mx-3"
        aria-label="Genindlæs siden"
        @click="$router.go()"
        @keyup.enter="$router.go()"
      >
        Genindlæs siden
      </button>
      <button
        type="button"
        class="btn btn-primary mx-3"
        aria-label="Gå til listevisning"
        @click="$router.push({ name: 'ListProducts' })"
        @keyup.enter="$router.push({ name: 'ListProducts' })"
      >
        Gå til listevisning
      </button>
    </FetchError>
    <div v-else>
      <div v-if="productCard">
        <div class="row g-4">
          <!-- Left panel start -->
          <div
            class="col-md-5 left-panel-full-page"
            v-if="!isWindowSmall"
            style="padding-right: 10% !important"
          >
            <MultiImageDisplay
              :productCard="productCard"
              :complianceType="
                getComplianceTypeDisplayName(productCard.complianceType.name)
              "
            />
            <router-link
              :to="{ name: 'ListProducts' }"
              class="d-inline-flex mt-5 inherit-link-styling"
            >
              <ArrowTitle :title="strapiData.view_more_products" />
            </router-link>
          </div>
          <!-- Left panel end -->
          <!-- Right panel -->
          <div class="col-md-7 right-panel-full-page">
            <p style="color: var(--darkgrey)">
              Dato: {{ date(productCard.publicDate) }}
            </p>
            <h1 class="page-title">{{ productCard.name }}</h1>

            <div v-if="isWindowSmall" class="mt-4">
              <MultiImageDisplay
                :productCard="productCard"
                :complianceType="
                  getComplianceTypeDisplayName(productCard.complianceType.name)
                "
              />
            </div>

            <p style="color: var(--darkgrey); margin-top: 30px">
              Kategori:
              {{
                getProductCategory(productCategories)
                  ? getProductCategory(productCategories)
                  : "Ikke angivet"
              }}
            </p>
            <h2>
              {{
                getComplianceTypeDisplayName(productCard.complianceType.name)
                  ? getComplianceTypeDisplayName(
                      productCard.complianceType.name
                    )
                  : "Ikke angivet"
              }}
            </h2>
            <p>
              {{
                getReasonDescription(fileClassGroups)
                  ? getReasonDescription(fileClassGroups)
                  : "Ikke angivet"
              }}
            </p>
            <div v-if="productCard.reason && productCard.reason !== '-'">
              <div
                v-html="
                  hyperlink2AnchorTag(removeBreaksStyles(productCard.reason))
                "
              ></div>
            </div>
            <div class="fact-box" style="margin-top: 30px">
              <ArrowTitle class="mb-2" :title="strapiData.consumer_action" />
              <div
                v-html="
                  getGuidelinesDescription(consumerGuidelines)
                    ? getGuidelinesDescription(consumerGuidelines)
                    : '<p>Ikke angivet</p>'
                "
              ></div>
            </div>
            <p style="color: var(--darkgrey); margin-top: 30px">
              Myndighed:
              {{
                productCard.sourceType.name
                  ? productCard.sourceType.name
                  : "Ikke angivet"
              }}
            </p>
            <h2 style="margin: 40px 0 30px 0">
              {{ strapiData.details_title }}
            </h2>
            <div class="row">
              <div
                v-for="element in listInfo(productCard)"
                :key="element.id"
                class="col-lg-4 col-sm-4 col-md-6 col-6 product-numbers-list"
              >
                <p style="font-weight: bold; margin-bottom: 0">
                  {{ element.displayName }}
                </p>
                <p>
                  {{ element.value ? element.value : "-" }}
                </p>
              </div>
            </div>
            <div v-if="isWindowSmall">
              <router-link
                :to="{ name: 'ListProducts' }"
                class="d-inline-flex mt-3"
              >
                <ArrowTitle :title="strapiData.view_more_products" />
              </router-link>
            </div>
          </div>
          <!-- Right panel end -->
        </div>
      </div>
      <div v-else>
        <p>Henter data...</p>
      </div>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import getSingleProduct from "@/composables/getSingleProduct";
import moment from "moment";
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";
import MultiImageDisplay from "@/components/products/MultiImageDisplay.vue";
import { useRoute } from "vue-router";
import FetchError from "@/components/FetchError.vue";

export default {
  components: { ArrowTitle, MultiImageDisplay, FetchError },
  data() {
    return {
      isWindowSmall: true,
    };
  },
  setup() {
    const route = useRoute();

    const { strapiData, error, load } = getStrapiData("product-info");

    const {
      strapiData: fileClassGroups,
      error: errorFileClassGroups,
      load: loadFileClassGroups,
    } = getStrapiDataCollection("file-class-groups");

    const {
      strapiData: consumerGuidelines,
      error: errorConsumerGuidelines,
      load: loadConsumerGuidelines,
    } = getStrapiDataCollection("consumer-guidelines");

    const {
      strapiData: productCategories,
      error: errorProductCategories,
      load: loadProductCategories,
    } = getStrapiDataCollection("product-categories");

    const {
      strapiData: complianceTypeDisplayNames,
      error: errorComplianceTypeDisplayNames,
      load: loadComplianceTypeDisplayNames,
    } = getStrapiDataCollection("compliance-types");

    load();
    loadFileClassGroups();
    loadConsumerGuidelines();
    loadProductCategories();
    loadComplianceTypeDisplayNames();

    const { productCard, errorSingleProduct, loadSingleProduct } =
      getSingleProduct(route.params.productId);

    loadSingleProduct();

    return {
      strapiData,
      error,
      fileClassGroups,
      errorFileClassGroups,
      consumerGuidelines,
      errorConsumerGuidelines,
      productCategories,
      errorProductCategories,
      productCard,
      errorSingleProduct,
      complianceTypeDisplayNames,
      errorComplianceTypeDisplayNames,
    };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
    getComplianceTypeDisplayName(codeName) {
      let displayName = "";
      if (this.complianceTypeDisplayNames.length) {
        this.complianceTypeDisplayNames.forEach((element) => {
          if (codeName.toLowerCase() == element.wz_code.toLowerCase()) {
            displayName = element.display_name_singular;
          }
        });
      }
      return displayName;
    },
    removeBreaksStyles(html) {
      return html
        .replace(/<br>/g, "")
        .replace(/style="[^"]*"/g, "")
        .replace(/style='[^']*'/g, "");
    },
    hyperlink2AnchorTag(text) {
      let urlMatcher =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

      return text.replace(urlMatcher, (match, index) =>
        text.substring(0, index).endsWith('href="')
          ? this.startsWithHttps(match)
          : `<a href="${this.startsWithHttps(match)}">${match}</a>`
      );
    },
    startsWithHttps(url) {
      return url.startsWith("http") ? url : "https://" + url;
    },
    getProductCategory(productCategories) {
      let category = "";
      if (this.productCard.productCategoryTypes) {
        let subjectArea = this.productCard.productCategoryTypes.name; // e.g. "LEGETOEJ"
        if (productCategories.length) {
          productCategories.forEach((element) => {
            if (
              subjectArea.toLowerCase().includes(element.wz_code.toLowerCase())
            ) {
              category = element.product_category_display.display_name;
            }
          });
        }
      }
      return category;
    },
    getReasonDescription(fileClassGroups) {
      let description = "";
      let group = this.productCard.fileClassGroup.name; // e.g. "PRT-04"
      if (fileClassGroups.length) {
        fileClassGroups.forEach((element) => {
          if (group.toLowerCase().includes(element.group.toLowerCase())) {
            description = element.description;
          }
        });
      }
      return description;
    },
    getGuidelinesDescription(consumerGuidelines) {
      let guidelines = "";
      let findingType = this.productCard.findingType.name; // e.g. "OKTILTAG"
      let reaction = this.productCard.reaction.name; // e.g. "PB-TTRÆK"
      if (consumerGuidelines.length) {
        consumerGuidelines.forEach((element) => {
          if (
            findingType
              .toLowerCase()
              .includes(element.finding_type.data.attributes.type.toLowerCase()) &&
            reaction
              .toLowerCase()
              .includes(element.sik_reaction.data.attributes.reaction.toLowerCase())
          ) {
            guidelines = element.guidelines;
          }
        });
      }
      return guidelines;
    },
    date(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    listInfo(productCard) {
      let eanNum = "";
      let batchNum = "";
      let serialNum = "";
      let productNum = "";
      let productionYear = "";

      if (productCard.productNumbers.length) {
        productCard.productNumbers.forEach((element) => {
          if (element.productNumberType.name.toLowerCase() == "ean") {
            eanNum = element.number;
          } else if (element.productNumberType.name.toLowerCase() == "batch") {
            batchNum = element.number;
          } else if (element.productNumberType.name.toLowerCase() == "serial") {
            serialNum = element.number;
          } else if (
            element.productNumberType.name.toLowerCase() == "product"
          ) {
            productNum = element.number;
          } else if (
            element.productNumberType.name.toLowerCase() == "productionyear"
          ) {
            productionYear = element.number;
          }
        });
      }

      let responsibleParty = productCard.responsibleParties.find(
        (obj) => obj.responsibilityType?.name === "1.oms"
      );
      let responsibleCompany = "";
      if (responsibleParty !== undefined)
        responsibleCompany = responsibleParty.company.name;
      console.log(responsibleCompany);

      let infoArray = [
        { id: 1, displayName: "EAN-nummer", value: eanNum },
        { id: 2, displayName: "Batch-nummer", value: batchNum },
        {
          id: 3,
          displayName: "Type",
          value: this.getComplianceTypeDisplayName(
            productCard.complianceType.name
          ),
        },
        { id: 4, displayName: "Serienummer", value: serialNum },
        // { id: 5, displayName: "Fabrikant", value: productCard.manufacturer },
        // { id: 6, displayName: "Importør", value: productCard.importer },
        { id: 7, displayName: "Varenummer", value: productNum },
        // { id: 8, displayName: "Forhandler", value: productCard.vendor },
        { id: 9, displayName: "Produktionsår", value: productionYear },
        {
          id: 10,
          displayName: "Ansvarlig virksomhed",
          value: responsibleCompany,
        },
      ];

      return infoArray;
    },
  },
};
</script>

<style>
.product-info .card-img {
  border-radius: 0px;
}
.product-info .fact-box ul {
  list-style: none;
}
.product-info .product-numbers-list p {
  font-size: 0.85em;
}

@media only screen and (min-width: 576px) {
  .product-info .product-numbers-list p {
    font-size: 1em;
  }
}
</style>
