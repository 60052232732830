<template>
  <div>
    <button
      @click="navigatePrevious"
      @keyup.enter="navigatePrevious"
      aria-label="Tilbage"
      :class="{ 'btn-previous': true, opaque: isFirst }" 
      class="btn btn-secondary"
      style="padding: 5px 35px;"
    > Tilbage
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    navigatePrevious() {
      if (this.$route.name === "InputSituation") {
        this.$router.push({ name: "InputProducts" });
      } else if (this.$route.name === "InputGuidelines") {
        this.$router.push({ name: "InputSituation" });
      }
    },
  },
  computed: {
    isFirst() {
      return this.$route.name === "InputProducts";
    },
  },
};
</script>

<style>
.btn-previous {
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  margin: 0;
  padding: 0;
}
.btn-previous.opaque {
  opacity: 0.0;
  cursor: default !important;
}
</style>
