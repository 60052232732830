<template>
  <div class="accordion-box">
    <div class="accordion-title-box d-flex justify-content-between align-items-center link" @click="active = !active">
      <div class="accordion-title">
        {{ title }}
      </div>
      <div class="arrow">
        <div v-if="!active" class="open-button">
          <svg-icon type="mdi" :path="path1" :width="40" :height="40" ></svg-icon>
        </div>
        <div v-else class="close-button">
          <svg-icon type="mdi" :path="path2" :width="40" :height="40" ></svg-icon>
        </div>
      </div>
    </div>
    <div v-if="active" class="accordion-content" v-html="mdtoHTML(description)"></div>
    <div v-if="hasExtraText() && active" class="accordion-content" v-html="mdtoHTML(extraText)"></div>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiChevronDownCircleOutline } from '@mdi/js';
import { mdiChevronUpCircle } from '@mdi/js';
import { marked } from "marked";
import getStrapiData from "@/composables/getStrapiDataFindOne";
import normaliseData from "@/composables/normaliseData";

export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    accordionId: {
      type: Number,
    },   
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      active: false,
      path1: mdiChevronDownCircleOutline,
      path2: mdiChevronUpCircle,
      extraText: "",
    };
  },
  setup(props) {
    const { strapiData, error, load } = getStrapiData("guideline-accordions", props.accordionId);
    
    load();
    return {strapiData, error}
  },
  methods: {
    mdtoHTML(file){
      return marked(file);
    },
    hasExtraText() {
      if(this.strapiData.guideline_extra_texts_bottom){
        if(this.strapiData.guideline_extra_texts_bottom.data.length > 0) {
            this.extraText = this.getExtratext(normaliseData(this.strapiData.guideline_extra_texts_bottom));
        }
      }
        
      
      if (this.extraText != "") return true;
      return false;
    },
    getExtratext(extraList){
      let text = "";
      extraList.forEach(element => {
        text += element.content;
      });
      return text;
    },
  },
};
</script>

<style>
.accordion-title {
  font-family: sora-medium;
  margin-left: 20px;
  padding: 30px 0px;
  font-size: 1.2rem;
}

.accordion-content {
  font-size: 1rem;
  margin: 0px 50px;
}

.open-button {
  color: var(--deepblue);
}

.close-button {
  color: var(--deepblue);
}

@media only screen and (max-width: 767.98px) {
  .accordion-title {
    max-width: 250px;
  }
  .accordion-title-box {
    margin: 5px;
  }
}
</style>


