<template>
  <div
    class="privacy-policy info-page"
    v-html="strapiData.content"
    role="main"
  ></div>
  <FactBox
    :title="strapiData.fact_box_title"
    :body="strapiData.fact_box_body"
    role="complementary"
    :aria-label="strapiData.fact_box_title"
  />
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import FactBox from "../../components/info/FactBox.vue";

export default {
  name: "PrivacyPolicy",
  components: { FactBox },
  setup() {
    const { strapiData, error, load } = getStrapiData("privacy-policy");
    load();
    return { strapiData, error };
  },
};
</script>
