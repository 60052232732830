// Contains async/await fetch to products API filters,
// which returns unique ids of all product categories,
// compliance types, and authority types present in database
import { ref } from "vue";

const getFilters = (path) => {
  // Make reactive in template when used in setup()
  const filterData = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      // Get API URL dependent on environment variable (test or prod)
      let data = await fetch(process.env.VUE_APP_PRODUCTS_API + path);
      if (!data.ok) {
        throw Error("No data available");
      }
      filterData.value = await data.json();
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };
  // Returns the ref'ed data and error as well as the load() function
  return { filterData, error, load };
};

export default getFilters;
