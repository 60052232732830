<template>
    <div class="full-size-image-backdrop" @click.self="closeModal">
        <div class="full-size-image-modal">
            <slot name="full-size-image"></slot>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>

<style>
.full-size-image-backdrop {
    top: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
}
.full-size-image-modal img {
    background: white;
    padding: 15px;
    
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    object-fit: contain;
    max-width: 80%;
    max-height: 80%;
    width: auto;
    height: auto;
}
</style>