<template>
  <div class="icsms info-page" v-html="strapiData.content" role="main"></div>
  <FactBox
    :title="strapiData.documentation_title"
    :body="strapiData.documentation_body"
    role="complementary"
    :aria-label="strapiData.documentation_title"
  />
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import FactBox from "@/components/info/FactBox.vue";

export default {
  name: "Icsms",
  components: { FactBox },
  setup() {
    const { strapiData, error, load } = getStrapiData("icsm");
    load();
    return { strapiData, error };
  },
};
</script>
