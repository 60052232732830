//Initialize default state for general data, e.g. navbar and footer
const getDefaultState = () => {
  return {
    navBarContent: [],
    subPages: [],
    footerContent: [],
  };
};

// Define state (allows for reset of state to default state)
const state = getDefaultState();

const mutations = {
  setSubPages(state, payload) {
    state.subPages = payload.subPages;
  },
  setNavBarContent(state, payload) {
    state.navBarContent = payload.navBarContent;
  },
  setFooterContent(state, payload) {
    state.footerContent = payload.footerContent;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default { namespaced: true, state, mutations, actions };
