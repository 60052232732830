<template>
  <!-- First step in legislation-guide, path: '/regler/guide/1' -->
   <div class="legislation input-products row g-4">
   <!-- Left panel start -->
   <div
        class="col-md-5 left-panel-full-page search-filter"
        style="padding-right: 10% !important"
        >
        <LeftPanel
        :guide="true"
        />
      </div>
      <!-- Left panel end -->
      <!-- Right panel -->
      <div class="col-md-7 right-panel-full-page" role="main">
        <div>
          <h1 class="form-top-title">{{ strapiData.shared_guide_title }}</h1>
          <p class="form-top-description">{{ strapiData.shared_guide_subtitle }}</p>

        </div>
        <div class="card report input-where-info" role="form">
          <form class="form" @keydown.enter.prevent @submit.prevent>
            <div class="form-top">
              <div class="row">
                <div class="col">
                </div>
                <div class="col-8 justify-content-center" style="max-width: 450px; display: flex">
                  <ProgressTracker
                    :activeStep="1"
                    :labels=labels
                    :stepRoutes=stepRoutes
                    />
                </div>
                <div class="col inline-flex" >
                  <LegislationCloseBtn/>
                </div>
              </div>
            </div>
            
            <FormTop
              :title="strapiData.title"
              :description="strapiData.description"
              :hideCloseBtn=true
            />
            <div class="page-description" v-html="strapiData.product_help_text"></div>
            <div>
              <FetchError v-if="fetchError" class="mt-5" />
              <ProductCardList 
                v-else
                :productCards="productCards"
              />

              <div
                class="pagination-container d-flex justify-content-center flex-column align-items-center"

              >
              <div class="page-info">Viser {{ productIndex }} ud af {{ totalNumberProducts }}</div>
                <VPagination
                  v-model="page"
                  :pages="getNumberPages"
                  :hideFirstButton=true
                  :hideLastButton=true
                  @update:modelValue="updateProductCards({ goToFirstPage: false, scrollToTop: true })"
                />
              </div>
            </div> 

            <div class="form-bottom">
              <div class="d-flex justify-content-between">
                  <LegislationBackBtn />
                  <LegislationForwardBtn
                    :disable="!allowNext"
                    @click="handleForward"
                    @keyup.enter="handleForward"
                    data-bs-toggle="modal"
                    data-bs-target="#errorProductModal"
                  />
              </div>
            </div>
          </form>
          <!-- Modal for no product -->
          <!-- TODO: handle uncaught error due to v-if -->
        <div
              v-if="!allowNext"
              class="modal fade"
              id="errorProductModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticErrorBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" >
                <div class="modal-content">
                  <div class="modal-header">
                    <h2 class="modal-title" id="staticErrorBackdropLabel">
                      {{ productModal.modal_title }}
                    </h2>
                  </div>
                  <div class="modal-body">
                    <p>{{ productModal.modal_description }}</p>
                  </div>
                  <div class="modal-footer d-flex justify-content-around">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      {{ productModal.modal_btn_cancel }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      @click="chooseGPSD"
                      @keyup.enter="chooseGPSD"
                    >
                    {{ productModal.modal_btn_no_category }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <BackToTopBtn :btnText="strapiData.back_to_top_btn" />
        </div>
      </div>
      <!-- Right panel end -->
    </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import LegislationBackBtn from "@/components/widgets/LegislationBackBtn.vue";
import LegislationForwardBtn from "@/components/widgets/LegislationForwardBtn.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import LegislationCloseBtn from "@/components/widgets/LegislationCloseBtn.vue";
import ProductCardList from "@/components/legislation/ProductCardList.vue";
import LeftPanel from "@/components/legislation/LeftPanel.vue";
import VPagination from "@hennge/vue3-pagination";
import FetchError from "@/components/FetchError.vue";
import ProgressTracker from "@/components/widgets/ProgressTracker.vue";
import BackToTopBtn from "@/components/widgets/BackToTopBtn.vue";
import { useStore } from "vuex";


export default {
  components: {
    LegislationBackBtn,
    LegislationForwardBtn,
    FormTop,
    LegislationCloseBtn,
    ProductCardList,
    FetchError,
    VPagination,
    ProgressTracker,
    BackToTopBtn,
    LeftPanel,
  },
  data() {
    return {
      fetchError: false,
      stepRoutes: this.$store.state.legislation.stepsProgress,
      labels: this.$store.state.legislation.stepLabels,
      productIndex: "", //TODO correct when less than per page
    };
  },
  setup() {
    const store = useStore();
    const { strapiData, error, load } = getStrapiData("input-product");

    const {
      strapiData: allProductCards,
      error: errorProductCard,
      load: loadProductCards
    } = getStrapiDataCollection("products", true);

    const {
      strapiData: productModal,
      error: errorProductModal,
      load: loadProductModal
    } = getStrapiData("no-category-modal");

    load();
    loadProductModal();

    if (!store.state.legislation.productCards.length > 0) {
      loadProductCards().then(() => {
        // find UKATEGORISERET
        // let ukategoriseretIndex = -1;
        let ukategoriseretId = 0;
        // allProductCards.value.forEach(function callback(value, index) {
        allProductCards.value.forEach(function callback(value) {
          if(value.name_code == "GPSD_GUIDE") {
            // ukategoriseretIndex = index;
            ukategoriseretId = value.id;
          }
        });
        // allProductCards.value.push(allProductCards.value.splice(ukategoriseretIndex, 1)[0]);
        store.commit("legislation/setProductCards", { productCards: allProductCards.value });
        store.commit("legislation/setTotalNumberProducts", { totalNumberProducts: allProductCards.value.length });
        store.commit("legislation/setNoCategoryId", { id: ukategoriseretId });
      });
    }

    return { 
      strapiData, 
      error, 
      errorProductCard,
      productModal,
      errorProductModal,
     };
  },
  beforeMount() {
    this.productIndex = this.productsPerPage;
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  methods: {
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
    handleForward() {
      if(this.allowNext) {
        this.$router.push({ name: "InputSituation" });
      } 
    },
    // TODO tidy up
    updateProductIndex() {
      if(this.page === 1) {
        this.productIndex = this.productsPerPage;
        return;
      }

      var startIndex = (this.page-1)*this.productsPerPage + 1;
      var endIndex = this.page*this.productsPerPage;
      
      if (endIndex > this.totalNumberProducts) {
        endIndex = this.totalNumberProducts;
        if(startIndex == endIndex){
          this.productIndex = endIndex;
          return;
        }
      }
      this.productIndex = startIndex + " - " + endIndex;
    },
    updateProductCards(event) {
      if (event.goToFirstPage) {
        this.page = 1;
      }
      if (event.scrollToTop) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      this.updateProductIndex();
    },
    chooseGPSD() {
      this.$store.commit("legislation/addSelectedProduct", { id: this.noCategoryId });
      this.$router.push({ name: "InputSituation" });
    },
  },
  computed: {
    getNumberPages() {
      return Math.ceil(this.totalNumberProducts / this.productsPerPage);
    },
    page: {
      get() {
        return this.$store.state.legislation.productPage;
      },
      set(value) {
        this.$store.commit("legislation/setProductPage", { productPage: value });
      },
    },
    totalNumberProducts: {
      get() {
        return this.$store.state.legislation.totalNumberProducts;
      },
      set(value) {
        console.log(value);
        this.$store.commit("legislation/setTotalNumberProducts", {
          totalNumberProducts: 14,
        });
      },
    },
    productsPerPage: {
      get() {
        return this.$store.state.legislation.cardsPerPage;
      },
    },
    productCards: {
      get() {
        let startindex = ((this.page-1)*this.productsPerPage);
        return this.$store.state.legislation.productCards.slice(startindex,startindex + this.productsPerPage);
      },
    },
    allowNext() {
      let selectedProducts = this.$store.state.legislation.selectedProducts;
      if ( selectedProducts.length > 0) return true; 
      return false;
    },
    noCategoryId: {
      get() {
        return this.$store.state.legislation.noCategoryId;
      },
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-container {
  margin-bottom: 20px;
}

.page-info{
  font-size: 15px;
  margin-bottom: 5px;
  color: var(--darkergrey);
}

.page-description {
  font-family: sora-regular;
  font-size: 1.25em;
}

.clickable {
  cursor: pointer;
}

@media only screen and (max-width: 767.98px) {
  .page-description {
  font-family: sora-regular;
  font-size: 0.85em;
  padding: 20px 5px 10px 15px;
  }
  .report.card {
    position: static;
  }
}

@media only screen and (max-width: 1000px) {
  .product-description {
  max-width: 350px;
}
}

</style>
