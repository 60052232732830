<template>
  <div class="arrow-svg-container">
    <button
      :class="{
        'btn-next': true,
        'btn-next--not-allowed': disable,
        opaque: isLast,
      }"
      @click="navigateNext"
      @keyup.enter="navigateNext"
      :disable="disable"
      type="button"
      aria-label="Næste"
    >
      <svg
        version="1.1"
        id="reportForwardBtn"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 62 70"
        style="enable-background: new 0 0 62 70"
        xml:space="preserve"
      >
        <g>
          <line class="st0" x1="61.9" y1="35" x2="-0.3" y2="35" />
          <path class="st0" d="M61.9,35c-13.8,0-25,11.2-25,25" />
          <path class="st1" d="M61.9,35c-13.8,0-25-11.2-25-25" />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigateNext() {
      if (!this.disable) {
        if (this.$route.name === "InputReason") {
          this.$router.push({ name: "InputProductNumbers" });
        } else if (this.$route.name === "InputProductNumbers") {
          this.$router.push({ name: "InputWhereInfo" });
        } else if (this.$route.name === "InputWhereInfo") {
          this.$router.push({ name: "InputContactInfo" });
        }
      }
    },
  },
  computed: {
    isLast() {
      return this.$route.name === "InputContactInfo";
    },
  },
};
</script>

<style>
.btn-next {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.btn-next--not-allowed {
  cursor: not-allowed !important;
}
.btn-next.opaque {
  opacity: 0.5;
  cursor: default !important;
}
.btn-next .st0 {
  fill: none;
  stroke: #6ebfff;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
.btn-next .st1 {
  fill: none;
  stroke: #0038f0;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
</style>
