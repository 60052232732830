<template>
  <!-- Final step in legislation-guide, path: '/regler/guide/3' -->
  <div class="legislation guideline row g-4">
    <!-- Left panel start -->
    <div class="col-md-5 left-panel-full-page search-filter" style="padding-right: 10% !important">
      <LeftPanel :guide="true" />
    </div>
    <!-- Left panel end -->
    <!-- Right panel -->
    <div class="col-md-7 right-panel-full-page" role="main">
      <div>
        <h1 class="form-top-title">{{ strapiData.shared_guide_title }}</h1>
        <p class="form-top-description">{{ strapiData.shared_guide_subtitle }}</p>

      </div>
      <div class="card report input-reason" role="form">
        <div class="form-top">
          <div class="row">
            <div class="col">
            </div>
            <div class="col-4 justify-content-center" style="max-width: 450px; display: flex">
              <ProgressTracker :activeStep="3" :labels=labels :stepRoutes=stepRoutes />
            </div>
            <div class="col inline-flex">
              <LegislationCloseBtn />
            </div>
          </div>
        </div>
        <FormTop :title="strapiData.title" :description="strapiData.description" :hideCloseBtn=true />
        <div>
          <div>
            <div class="limitation-card" v-html="strapiData.limitation_statement">
            </div>
          </div>
          <div>
            <div class="role-box" v-if="checkRolePairs()">
              <div style="font-family: sora-medium;">{{ strapiData.role_box_title }}</div>
              <li v-for="(role, product, index) in guideRoleBox" :key="index" style="margin-left: 30px;">
                {{ product }} ({{ role }})
              </li>
            </div>
            <div class="guideline-blocks" v-if="getGuidelineBlocks()">
              <GuideBlock :guideData="displayGuide" />
            </div>
            <div class="download-button align-items-center d-flex" style="margin-top: 30px;">
              <button class=" btn btn-primary justify-content-center download-btn" @click="generatecsv()">
                Download vejledning
              </button>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <div class="d-flex justify-content-between">
            <LegislationBackBtn />
            <div class="close-guide-button">
              <button class="btn btn-primary close-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                style="padding: 5px 35px;">
                Afslut
              </button>
              <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
              </div>
            </div>
          </div>
        </div>
        <BackToTopBtn :btnText="strapiData.back_to_top_btn" />
      </div>
    </div>
    <!-- Right Panel end -->
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import normaliseData from "@/composables/normaliseData";
import LegislationBackBtn from "@/components/widgets/LegislationBackBtn.vue";
import LegislationCloseBtn from "@/components/widgets/LegislationCloseBtn.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import ProgressTracker from "@/components/widgets/ProgressTracker.vue";
import BackToTopBtn from "@/components/widgets/BackToTopBtn.vue";
import GuideBlock from "@/components/legislation/GuideBlock.vue";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";
import { ref } from "vue";
import { useStore } from "vuex";
import LeftPanel from "@/components/legislation/LeftPanel.vue";



async function getGuideBlocks() {

    const { 
      strapiData: guidelineBlocks,
      error: errorGuideBlocks,
      load: loadGuideBlocks 
    } = getStrapiDataCollection("guideline-blocks");

    let guideBlockDict = ref({});

    await loadGuideBlocks().then( () => {
      if (errorGuideBlocks.value) {
        console.log(errorGuideBlocks.value);
      }
      guidelineBlocks.value.forEach(guideBlock => {
        guideBlockDict.value[guideBlock.id] = guideBlock;
      });
    });
  return { guideBlockDict };

}


export default {
  components: {
    LegislationBackBtn,
    ProgressTracker,
    FormTop,
    LegislationCloseBtn,
    BackToTopBtn,
    GuideBlock,
    LeftPanel,
  },
  data() {
    return {
      showErrorMsg: false,
      stepRoutes: this.$store.state.legislation.stepsProgress,
      labels: this.$store.state.legislation.stepLabels,
      productCards: this.$store.state.legislation.productCards,
      guidelineBlocksId: [],
      GPSDGuideBlockId: -1,
      includeGPSDFlag: true,
      GPSDBLOCKTYPE: 4,
      SAERLOVBLOCKTYPE: 1,
      UAFHENGIGBLOCKTYPE: 3,
      ADDONBLOCKTYPE: 2,
      debugFlag: false,
      specialRolePairsId:[],
      allAddOn: true,
    };
  },
  setup() {
    const store = useStore();
    const { strapiData, error, load } = getStrapiData("input-guideline");
   
    load();
    
    if (Object.keys(store.state.legislation.guidelineBlocksDict).length === 0) {
      let guideBlockDict = ref({});

      getGuideBlocks().then((data) => {
        guideBlockDict = data.guideBlockDict.value;
        store.commit("legislation/setGuidelineBlocks", {
          guidelineBlocksDict: guideBlockDict,
        });
      });
    }

    const { 
      strapiData: specialRoles,
      error: errorSpecialRoles,
      load: loadSpecialRoles 
    } = getStrapiDataCollection("product-role-pairs");
   
    let specialrolePairDict = [];
    loadSpecialRoles().then(() => {
      // build and save the special role library
      specialRoles.value.forEach(pair => {
          let data = {};
          data["role"] = pair.role_title.data.attributes.role;
          data["productId"] = pair.product_category_guideline.data.id;
          data['id'] = pair.id;
          specialrolePairDict.push(data);
        });
        store.commit("legislation/setSpecialRolePairDict", {
          specialRolePairDict: specialrolePairDict,
        });
    });

    return { 
      strapiData, 
      error,
      errorSpecialRoles,
     };
  },
  beforeMount() {
    this.computeData();
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    toggleErrorMsg() {
      this.showErrorMsg = true;
    },
    handleForward() {
      if (!this.reason) {
        this.toggleErrorMsg();
      }
    },
    printSelection() {
      console.log("product ids: " + this.selectedProductsId);
      console.log("situation card: ");
      console.log(this.mainSituationCard);
    },
    computeData() {
      this.printSelection();

      let roleBoxContent = {};
      let mainRole = this.getRoles();       
      if (mainRole == "blank") mainRole = "ingenting"; 
      this.selectedProductsId.forEach(id => {
        let productCategory = this.getProductName(id);
        roleBoxContent[productCategory] = mainRole;
      });
      
      
      // get guideblocks
      let situationGuideIds = normaliseData(this.mainSituationCard.guideline_blocks);
      this.updateGuidelineBlockId(situationGuideIds, false);
      console.log("AllGuideIds: " + this.guidelineBlocksId);
      
      // filter out gpsd
      this.guidelineBlocksId.forEach( id => {
        this.GPSDGuideBlockIdAndIncludeFlagCheck(this.guidelineBlocks[id]);
      });

      // add GPSD for all addon situation
      if(this.includeGPSDFlag && this.allAddOn && this.GPSDGuideBlockId > 0)
        this.guidelineBlocksId.push(this.GPSDGuideBlockId);

      // add extra situation guide blocks
      let extraSituation = this.$store.state.legislation.selectedExtraSituation;
      if(extraSituation.id != 0){
        let extraSituationCard = extraSituation.card;
        let extraSituationTriggerData = normaliseData(extraSituationCard.extra_situation_trigger);
        // update role for extra situation
        let roleData = normaliseData(extraSituationCard.role);
        roleBoxContent[extraSituationTriggerData.title] = roleData.role;

        let flatGuidelineBlock = normaliseData(extraSituationCard.guideline_blocks);
        this.updateGuidelineBlockId(flatGuidelineBlock, true);
      }
      this.$store.commit("legislation/setguideRoleBox", { guideRoleBox: roleBoxContent });
      this.checkRolePairs();
    },
    getRoles(){
      
      // get main role from situation card
      let mainRoleData = normaliseData(this.mainSituationCard.role);
      let mainRole = mainRoleData.role; 
      let specialRoles = normaliseData(this.mainSituationCard.exception_role_naming);
      console.log("main role: " + mainRole);
      
      // get special role
      if (specialRoles.length != 0) this.getSpecialRolePairId(specialRoles);

      return mainRole;
    },
    getSpecialRolePairId(specialRolesFromSituation){
      specialRolesFromSituation.forEach(pair => {
        if (!this.specialRolePairsId.includes(pair.id)) this.specialRolePairsId.push(pair.id);
      });
    },
    checkRolePairs() {
      let roleBoxContent = this.guideRoleBox;
      if(this.specialRolePairsId.length != 0) {
        this.specialRolePairsId.forEach(pairId => {
          this.specialrolePairDict.forEach(pair => {
            if(pair.id === pairId){
              let index = this.selectedProductsId.indexOf(pair.productId);
              if(index >= 0) {
                roleBoxContent[this.getProductName(pair.productId)] = pair.role;
              }
            } 
          });
        });
      } 
      this.$store.commit("legislation/setguideRoleBox", { guideRoleBox: roleBoxContent });

      return true;
    },
    getProductName(id) {
      let name = "not found";
      this.productCards.forEach(element => {
        if (element.id === id) {
        name = element.title;
        }
      });
      if(this.debugFlag) console.log("name: " + name);
      return name;
    },
    // fetch the block and check with selected product
    updateGuidelineBlockId(guideIds, extraSituation) {
      if(guideIds.length <=0) return;
      
      if(this.debugFlag) console.log("isExtraSituation: " + extraSituation);
      guideIds.forEach(element => {
        let blockId = element.id;
        let guidelineBlockData = this.guidelineBlocks[blockId];

        // exit when guideline doesnt exist
        if (guidelineBlockData === undefined) {
          return;
        }

        if(this.debugFlag) {
          console.log("guideblock id " + blockId);
          console.log(guidelineBlockData);
        }
        
        // check productid with selected products
        let guideBlockProductId = guidelineBlockData.product_category_guideline.data.id;
        if(this.selectedProductsId.indexOf(guideBlockProductId) >= 0){
          if(!this.isBlockListed(blockId))
            this.guidelineBlocksId.push(blockId);
        }

        // get GPSD block
        if(guideBlockProductId === this.GPSDCategoryId){
          if(!this.isBlockListed(blockId))
            this.GPSDGuideBlockId = blockId;
        }
      });
    },
    isBlockListed(guidelineBlockId){
      return !(this.guidelineBlocksId.indexOf(guidelineBlockId) < 0);
    },
    crossCheckSituationCard(guidelineBlock, situationId){

      if(guidelineBlock.situation_cards.data.length < 1)
      return false;
    
      guidelineBlock.situation_cards.data.forEach( situation => {
        if (situation.id === situationId){
            return true;
          } 
        });
      return false;
    },
    // TODO check for guide / product level
    GPSDGuideBlockIdAndIncludeFlagCheck(guideElement) {
      let guidelineTypeData = normaliseData(guideElement.guideline_type);
      if(guidelineTypeData === undefined) return;

      if(guidelineTypeData.id == this.SAERLOVBLOCKTYPE) this.includeGPSDFlag = false;

      if(guidelineTypeData.id == this.GPSDBLOCKTYPE
        && this.GPSDGuideBlockId < 0)
          this.GPSDGuideBlockId = guideElement.id;

      this.allAddOn = this.allAddOn && (guidelineTypeData.id == this.ADDONBLOCKTYPE);
      
      if(this.debugFlag){
        console.log("GPSDGuideBlockId: ");
        console.log(this.GPSDGuideBlockId);
      }

      console.log("includeGPSDData: " + this.includeGPSDFlag)
      console.log("allAddOn: " + this.allAddOn)
    },
    getBlock(guideId) {
      let guideBlock = {};
  
      let block = this.guidelineBlocks[guideId];
      // skip GPSD
      if(!this.includeGPSDFlag && this.GPSDGuideBlockId == guideId) return {};
      
      guideBlock['title'] = block.title;
      guideBlock['accordions'] = normaliseData(block.guideline_accordions);

      return guideBlock;
    },
    // TODO: add extra texts
    generatecsv() {
      
      let data = this.displayGuide;
      let cleanData = [];
      let miniData = [];
      data.forEach(block => {
        block.accordions.forEach(accordion => {
          accordion.description = this.stripHTML(accordion.description);
          miniData.push(
            {
              title:block.title, 
              accordionTitle: accordion.title,
              accordionDescription: accordion.description
            });
        });
        cleanData.push(block);
        cleanData.push({title:""});
      });
      
      let toPrint = miniData;
      //Options for json2csv conversion, see https://github.com/mrodrig/json-2-csv/wiki/json2csv-Documentation
      // let options = { keys: ["title","accordions.title", "accordions.description"], excelBOM: true, expandArrayObjects:true};
      let options = { keys: ["title","accordionTitle", "accordionDescription"], excelBOM: true, expandArrayObjects:true};
      json2csv(
            toPrint,
            (err, csv) => {
              if (!err) {
                console.log("CSV file generated");
                let blob = new Blob([csv], { type: "text/csv" });
                saveAs(blob, "produkterdk_produkter_guideline.csv");
              }
            },
            options
          );
    },
    stripHTML(html) {
      let doc = new DOMParser().parseFromString(html, "text/html");
      let text = doc.body.textContent || "";
      text = text.replace(/(\r\n|\n|\r)/gm, "");
      return text
    },
    getGuidelineBlocks() {
      if (Object.keys(this.guidelineBlocks).length === 0) 
        return false;
      return true;
    }
  },
  computed: {
    allowNext() {
      if (this.reason) {
        return true;
      } else {
        return false;
      }
    },
    mainSituationCard: {
      get() {
        return this.$store.state.legislation.selectedMainSituation.card;
      },
    },
    selectedProductsId: {
      get() {
        return this.$store.state.legislation.selectedProducts;
      },
    },
    displayGuide: {
      get() {
        console.log("get data");
        let allGuide = [];
        this.guidelineBlocksId.forEach(id => {
          let guideBlock = this.getBlock(id);
          if (Object.keys(guideBlock).length != 0) allGuide.push(guideBlock);
        });
        return allGuide;
      },
    },
    guidelineBlocks: {
      get() {
        return this.$store.state.legislation.guidelineBlocksDict;
      },
    },
    guideRoleBox: {
      get() {
        return this.$store.state.legislation.guideRoleBox;
      },
    },
    specialrolePairDict: {
      get() {
        return this.$store.state.legislation.specialRolePairDict;
      },
    },
    GPSDCategoryId: {
      get() {
        return this.$store.state.legislation.noCategoryId;
      },
    },
  },
};
</script>

<style>

.download-btn {
  min-width: fit-content;
}

.close-btn {
  padding: 0 35px;
}

.limitation-card {
  background-color: var(--lightgrey);
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 20px;
}


@media only screen and (max-width: 767.98px) {
  .limitation-card {
    padding: 15px ;
    margin: 10px;
  }
  .role-box {
    margin: 20px;
  }
  .guideline-blocks{
    margin: 10px;
  }
  .download-button{
    padding: 20px 15px 20px 15px;
  }
}
</style>
