<template>
  <!-- Third step in report-flow, path: '/anmeldelser/3' -->
  <div class="card report input-product-numbers" role="form">
    <form class="form" @keydown.enter.prevent @submit.prevent>
      <FormTop
        :title="strapiData.title"
        :description="strapiData.description"
      />
      <div class="form-body">
        <div class="label-with-help">
          <label>{{ strapiData.label }}</label>
          <HelpBtn :helpText="strapiData.help" />
        </div>
        <label class="sub-label">{{ strapiData.sub_label }}</label>
        <select
          v-model="inputIdType"
          class="id-number-type"
          :aria-label="strapiData.placeholder_type"
        >
          <option value="null" selected disabled hidden>
            {{ strapiData.placeholder_type }}
          </option>
          <option
            v-for="idNumber in numTypes"
            :key="idNumber.idType"
          >
            {{ idNumber.idType }}
          </option>
        </select>

        <input
          type="text"
          v-model="inputIdNum"
          :placeholder="strapiData.placeholder_number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxLength="30"
          style="background-color: var(--lightblue) !important"
        />

        <button
          @click="addIdNum"
          @keyup.enter="addIdNum"
          class="btn btn-primary my-3"
          :disabled="!inputIdNum"
        >
          {{ strapiData.button_text }}
        </button>

        <div v-if="idNumbers.length">
          <div class="label-with-help">
            <label class="your-choices">{{ strapiData.added_title }}</label>
            <HelpBtn :helpText="strapiData.added_help" />
          </div>
        </div>
        <div v-for="idNumber in idNumbers" :key="idNumber">
          <div class="remove-choices align-items-center d-flex">
            <div class="added-choices">
              {{ idNumber.idType }}: {{ idNumber.number }}
            </div>
            <button
              class="btn btn-close btn-sm"
              type="button"
              @click="removeIdNum(idNumber, strapiData.remove_message)"
              @keyup.enter="removeIdNum(idNumber, strapiData.remove_message)"
              aria-label="Fjern nummer"
            ></button>
          </div>
        </div>
        <FileUpload @updatefiles="updateFiles" :fileList="idFiles" />
      </div>
      <div class="form-bottom">
        <div class="row align-items-center">
          <div class="col">
            <ReportBackBtn />
          </div>
          <div class="col-8 justify-content-center" style="max-width: 450px">
            <StepProgress
              :steps="this.$store.state.report.myStepsProgress"
              :current-step="2"
            />
          </div>
          <div class="col">
            <ReportForwardBtn :disable="!allowNext" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HelpBtn from "@/components/widgets/HelpBtn.vue";
import FileUpload from "@/components/FileUpload.vue";
import getStrapiData from "@/composables/getStrapiDataSingle";
import ReportBackBtn from "@/components/widgets/ReportBackBtn.vue";
import ReportForwardBtn from "@/components/widgets/ReportForwardBtn.vue";
import StepProgress from "@/components/input-cards/StepProgress.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import normaliseData from "@/composables/normaliseData";

export default {
  components: {
    HelpBtn,
    FileUpload,
    ReportBackBtn,
    ReportForwardBtn,
    StepProgress,
    FormTop,
  },
  data() {
    return {
      inputIdType: null,
      inputIdNum: "",
      formHeight: null,
    };
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("input-product-number");
    load();
    return { strapiData, error };
  },
  methods: {
    addIdNum() {
      if (this.inputIdNum) {
        //Check if type of number input is already present in list - if not, add to list
        let typePresent = this.idNumbers.some(
          (element) => element.idType == this.inputIdType
        );
        if (!typePresent) {
          let idNumber = {
            idType: this.inputIdType,
            number: this.inputIdNum,
          };
          this.idNumbers.push(idNumber);
          console.log("Adding product number: ", idNumber);
        }
        //Refresh to default selection
        this.inputIdNum = "";
        this.inputIdType = null;
      }
    },
    removeIdNum(idNumber, message) {
      if (confirm(message)) {
        this.idNumbers = this.idNumbers.filter((item) => {
          return idNumber !== item;
        });
        console.log("Removing product number: ", idNumber);
      }
    },
    updateFiles(event) {
      this.idFiles = event;
    },
  },
  computed: {
    allowNext() {
      return true;
    },
    idNumbers: {
      get() {
        return this.$store.state.report.idNumbers;
      },
      set(value) {
        this.$store.commit("report/setIdNumbers", { idNumbers: value });
      },
    },
    idFiles: {
      get() {
        return this.$store.state.report.idFiles;
      },
      set(value) {
        this.$store.commit("report/setIdFiles", { idFiles: value });
      },
    },
    numTypes() {
      return normaliseData(this.strapiData.num_types);
    }
  },
};
</script>
