<template>
  <div class="product-card-list my-5" v-if="!loading">
    <div class="row gy-5">
      <div
        v-for="productCard in productCards"
        :key="productCard.id"
        :class="{ 'col-6': isWindowSmall, 'col-4': !isWindowSmall }"
      >
        <router-link
          :to="{
            name: 'ProductInfo',
            params: { productId: productCard.id },
          }"
          :alt="productCard.name"
        >
          <ProductCard :productCard="productCard" />
        </router-link>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center" v-if="loading">
    <div
      class="spinner-border m-5"
      role="status"
      style="color: var(--deepblue); width: 5rem; height: 5rem"
    ></div>
  </div>
</template>

<script>
import ProductCard from "@/components/products/ProductCard.vue";

export default {
  props: ["productCards", "loading"],
  components: { ProductCard },
  data() {
    return {
      isWindowSmall: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 1200;
    },
  },
};
</script>
