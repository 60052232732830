// Contains routes to all views related to the report flow
// Import views
import Report from "@/views/report/Report.vue";
import InputProductInfo from "@/views/report/input-cards/InputProductInfo.vue";
import InputProductNumbers from "@/views/report/input-cards/InputProductNumbers.vue";
import InputContactInfo from "@/views/report/input-cards/InputContactInfo.vue";
import InputReason from "@/views/report/input-cards/InputReason.vue";
import InputWhereInfo from "@/views/report/input-cards/InputWhereInfo.vue";
import InputConfirmation from "@/views/report/input-cards/InputConfirmation.vue";
// Import Vuex store to enable checks for navigation guards
import store from "@/store";

// Define routes and display names
const reportRoutes = [
  {
    path: "/anmeldelser",
    name: "Report",
    component: Report,
    meta: {
      displayName: "Anmeld farlige produkter",
    },
    children: [
      {
        path: "1",
        name: "InputProductInfo",
        component: InputProductInfo,
        meta: {
          displayName: "Anmeldelse",
        },
      },
      {
        path: "2",
        name: "InputReason",
        component: InputReason,
        meta: {
          displayName: "Anmeldelse",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.report.productName &&
              store.state.report.productCategory.category
            )
          ) {
            // Go to start of report flow if mandatory fields not filled
            next({ name: "Report" });
          } else {
            // Although it might not seem so, this 'next()' is necessary!
            next();
          }
        },
      },
      {
        path: "3",
        name: "InputProductNumbers",
        component: InputProductNumbers,
        meta: {
          displayName: "Anmeldelse",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.report.productName &&
              store.state.report.productCategory.category &&
              store.state.report.reasonForReporting
            )
          ) {
            next({ name: "Report" });
          } else {
            next();
          }
        },
      },
      {
        path: "4",
        name: "InputWhereInfo",
        component: InputWhereInfo,
        meta: {
          displayName: "Anmeldelse",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.report.productName &&
              store.state.report.productCategory.category &&
              store.state.report.reasonForReporting
            )
          ) {
            next({ name: "Report" });
          } else {
            next();
          }
        },
      },
      {
        path: "5",
        name: "InputContactInfo",
        component: InputContactInfo,
        meta: {
          displayName: "Anmeldelse",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.report.productName &&
              store.state.report.productCategory.category &&
              store.state.report.reasonForReporting &&
              store.state.report.boughtWhere &&
              (store.state.report.linkInfos.length ||
                store.state.report.storeInfos.length)
            )
          ) {
            next({ name: "Report" });
          } else {
            next();
          }
        },
      },
      {
        path: "succes",
        name: "InputConfirmation",
        component: InputConfirmation,
        meta: {
          displayName: "Anmeldelse",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.report.productName &&
              store.state.report.productCategory.category &&
              store.state.report.reasonForReporting &&
              store.state.report.boughtWhere &&
              (store.state.report.linkInfos.length ||
                store.state.report.storeInfos.length)
            )
          ) {
            next({ name: "Report" });
          } else {
            next();
          }
        },
      },
    ],
  },
];

export default reportRoutes;
