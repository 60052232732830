<template>
    <div class="box">
        <div class="bar stay" style="background-color: rgb(115, 115, 115); height: 4px;"></div>
        <!-- TODO make it work for different steps and add routing for done and move it back-->
        <div v-if="activeStep > 1" class="bar-move-progress" :style="{ width: doneBarLength() + '%' }" :class="{'move-progress': middle()}"></div>
        <div class="all move" :style="{ width: labels.length * 100 + 'px' }">
            <div v-for="(item, index) in labels" :key="index" class="col">
                <div class="box">
                    <div v-if="index + 1 < activeStep" class="circle done" @click="redirectUser(index)" >
                        <div class="checkmark"></div>
                    </div>
                    <div v-else-if="index + 1 === activeStep" class="circle active">{{ index + 1 }}</div>
                    <div v-else class="circle passive">{{ index + 1 }}</div>
                    <div style="font-size: small;padding-top: 10%;">{{ item }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props:  {
    steps: {
        type: Number,
        default: 1,
    },
    activeStep: {
        type: Number,
        default: 1,
    },
    labels: {
        type: Array,
    },
    stepRoutes: {
        type: Array,
    },
},
methods: {
    redirectUser(index) {
        var route = this.stepRoutes[index];
        this.$router.push({name: route });
    },
    doneBarLength() {
        // simplify later
        var totalLength = (((this.labels.length*2)-2)/(this.labels.length*2)) *100;
        var perBetween = totalLength/(this.labels.length -1);
        return perBetween*(this.activeStep-1);
    },
    // TODO: remove hardcoded stuff
    middle() {
        return this.activeStep == 2;
    }
},
};
</script>
<style>
.circle {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 6px;
    background: #fff;
    text-align: center;
    font-size: 20px !important;
}

.active {
    border: 3px solid var(--deepblue);
    color: var(--deepblue);
    font-weight: 900;
}

.done {
    background: var(--deepblue);
    color: white;
    cursor: pointer;
}

.passive {
    border: 3px solid #737373;
    color: #737373;
    font-weight: 900;
    cursor: not-allowed;
}

.bar {
    /* top: 28%;
    position: relative; */
    margin-left: 10%;
    width: 70% !important;
}

.stay {
    margin-top: 40px;
}

.move {
    position: relative;
    bottom: 40px;
}

.bar-move-progress {
    position: relative;
    bottom: 4px;
    background-color: var(--deepblue); 
    height: 4px;
}

.move-progress {
    right: 12%;
}

.all {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
}

.checkmark {
  position: relative;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 25%;
  top: 50%;
  height: 23px;
  width: 15px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  color: #fff;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>