// Contains routes to all views related to the legislation flow
// Import views
import Legislation from "@/views/legislation/Legislation.vue";
import LegislationCategory from "@/views/legislation/LegislationCategory.vue";
import LegislationSearch from "@/views/legislation/LegislationSearch.vue";
import InputProducts from "@/views/legislation/input-cards/InputProducts.vue";
import InputGuidelines from "@/views/legislation/input-cards/InputGuidelines.vue";
import InputSituation from "@/views/legislation/input-cards/InputSituation.vue";
// Import Vuex store to enable checks for navigation guards
import store from "@/store";

// Define routes and display names
const legislationRoutes = [
  {
    path: "/regler",
    name: "Legislation",
    component: Legislation,
    meta: {
      displayName: "Regler for produktsikkerhed",
    },
    children: [
      {
        path: "guide/1",
        name: "InputProducts",
        component: InputProducts,
        meta: {
          displayName: "Guide",
        },
      },
      {
        path: "guide/2",
        name: "InputSituation",
        component: InputSituation,
        meta: {
          displayName: "Guide",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          if (
            !(
              store.state.legislation.selectedProducts.length != 0
            )
          ) {
            // Go to start of report flow if mandatory fields not filled
            next({ name: "InputProducts" });
          } else {
            // Although it might not seem so, this 'next()' is necessary!
            next();
          }
        },
      },
      {
        path: "guide/3",
        name: "InputGuidelines",
        component: InputGuidelines,
        meta: {
          displayName: "Guide",
        },
        // Navigation guard
        beforeEnter: (to, from, next) => {
          
          if (
            !(
              store.state.legislation.selectedProducts.length != 0 &&
              store.state.legislation.selectedMainSituation 
            )
          ) {
            next({ name: "InputProducts" });
          } else {
            next();
          }
        },
      },
      {
        // Use unique id from database as route
        path: "lovomraader/:categoryId",
        props: true,
        name: "LegislationCategory",
        component: LegislationCategory,
        meta: {
          displayName: "Lovområder",
        },
        // BUG: fix browser forward backward
        beforeEnter: (to, from, next) => {
          if (
            (
              to.params.categoryId !=
              from.params.categoryId
            )
          ) {
            store.commit("legislation/setSelectedCategory", { selectedCategory: to.params.categoryId });
          } 
            next();
          
        },
      },
      {
        // Search result page
        path: "search=:search",
        props: true,
        name: "LegislationSearch",
        component: LegislationSearch,
        meta: {
          displayName: "Søgeresultater",
        },
      },
    ],
  }
];

export default legislationRoutes;
