<template>
  <div :class="{ 'form-top-img-container': !isConfirmation }">
    <div>
      <!-- just close button -->
      <div class="d-flex justify-content-end">
        <button
          :class="{
            btn: true,
            'btn-close': true,
          }"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          type="button"
          aria-label="Luk guide"
        ></button>
      </div>

      <!-- Modal for closing report -->
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="staticBackdropLabel">
                {{ strapiData.modal_title }}
              </h2>
            </div>
            <div class="modal-body">
              <p>{{ strapiData.modal_description }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-around">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                @click="closeReport"
                @keyup.enter="closeReport"
              >
                {{ strapiData.modal_btn_close }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {{ strapiData.modal_btn_continue }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  props: {
    title: {
      type: String,
      default: "Titel",
    },
    description: {
      type: String,
      default: "Beskrivelse",
    },
    isConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWindowSmall: false,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("close-guide-modal");
    load();
    return { strapiData, error };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    closeReport() {
      this.$store.dispatch("legislation/resetState");
      this.$router.push({ name: "Home" });
    },
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
  },
};
</script>

<style lan="scss">
</style>
