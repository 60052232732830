<template>
  <div class="no-results-box" v-if="!loading">
    <p class="dynamic-results">
      {{ title }}
      {{
        $store.state.products.filtersInput.complianceFilters.length === 0
          ? "produkter"
          : ""
      }}
    </p>
    <h4
      class="dynamic-results"
      v-if="$store.state.products.filtersInput.complianceFilters.length > 0"
    >
      {{ getDisplayNames("compliance") }}
    </h4>
    <p v-if="$store.state.products.searchTerm" class="dynamic-results">
      {{ subTitle }}
    </p>
    <h4 v-if="$store.state.products.searchTerm" class="dynamic-results">
      "{{ $store.state.products.searchTerm }}"
    </h4>
    <p
      v-if="$store.state.products.filtersInput.categoryFilters.length > 0"
      class="dynamic-results"
    >
      under
      {{
        getDisplayNames("category").length > 1 ? "kategorierne" : "kategorien"
      }}
    </p>
    <h4
      class="dynamic-results"
      v-if="$store.state.products.filtersInput.categoryFilters.length > 0"
    >
      {{ getDisplayNames("category") }}
    </h4>
    <div v-html="body" style="margin-top: 30px" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
  methods: {
    getDisplayNames(type) {
      let dict = {};
      let filters = [];
      let displayNames = [];
      if (type === "compliance") {
        dict = this.$store.state.products.complianceDict;
        filters = this.parseStrings(
          this.$store.state.products.filtersInput.complianceFilters
        );
      } else if (type === "category") {
        dict = this.$store.state.products.categoryDict;
        filters = this.parseStrings(
          this.$store.state.products.filtersInput.categoryFilters
        );
      }
      if (filters.length > 0) {
        filters.forEach((filterId) => {
          for (const [key, value] of Object.entries(dict)) {
            if (value.wzIds.length > 0) {
              value.wzIds.forEach((wzId) => {
                if (wzId === filterId) {
                  if (!displayNames.includes(key)) {
                    displayNames.push(key);
                  }
                }
              });
            }
          }
        });
      }
      console.log(displayNames);
      return displayNames;
    },
    parseStrings(stringArray) {
      let numberArray = [];
      if (stringArray.length) {
        stringArray.forEach((element) => {
          if (element) {
            element.split(",").map((item) => numberArray.push(Number(item)));
          }
        });
      }
      return numberArray;
    },
  },
};
</script>

<style lang="scss">
.no-results-box {
  background-color: var(--lightgreyopaque);
  margin-top: 20px;
  padding: 40px;
  border: 2px dashed var(--mediumblue);
  p.dynamic-results {
    text-align: center;
    color: var(--deepblue);
    margin-bottom: 10px;
  }
  h4.dynamic-results {
    text-align: center;
    color: var(--darkgrey);
    margin-bottom: 10px;
  }
}
</style>
