<template>
  <!-- First step in report-flow, path: '/anmeldelser/1' -->
  <div class="card report input-product-info" role="form">
    <form class="form" @keydown.enter.prevent @submit.prevent>
      <FormTop
        :title="strapiData.title"
        :description="strapiData.description"
      />
      <div class="form-body">
        <div class="label-with-help">
          <label>{{ strapiData.name_label }}</label>
          <span class="report-input-required">Obligatorisk</span>
        </div>
        <label class="sub-label"
          ><div v-html="strapiData.name_sub_label"></div
        ></label>
        <div
          class="input-with-help d-flex align-items-center"
          style="background-color: var(--lightblue)"
        >
          <input
            required
            type="text"
            v-model="productName"
            :placeholder="strapiData.name_placeholder"
          />
          <HelpBtn :helpText="strapiData.name_help" />
        </div>
        <div class="error" v-if="!productName && showProductNameErrorMsg">
          {{ strapiData.error_submit_name }}
        </div>

        <div class="label-with-help">
          <label>{{ strapiData.brand_label }}</label>
        </div>
        <label class="sub-label">{{ strapiData.brand_sub_label }}</label>
        <div class="input-with-help">
          <input
            type="text"
            v-model="brandName"
            :placeholder="strapiData.brand_placeholder"
          />
          <HelpBtn :helpText="strapiData.brand_help" />
        </div>
        <div class="label-with-help">
          <label>{{ strapiData.category_label }}</label
          ><span class="report-input-required">Obligatorisk</span>
        </div>
        <label class="sub-label">{{ strapiData.category_sub_label }}</label>

        <div class="category-container">
          <div class="input-with-help" v-if="!isMappedCategoryChosen">
            <input
              type="text"
              required
              :placeholder="strapiData.category_placeholder"
              @keydown.down="moveDownList"
              @keydown.up="moveUpList"
              @keydown.enter="selectOnEnter"
              :value="inputCategory"
              @input="handleInputCategory"
              v-show="!isMappedCategoryChosen"
            />
            <HelpBtn :helpText="strapiData.category_help" />
          </div>
          <div
            class="error"
            v-if="
              !(inputCategory || isMappedCategoryChosen) &&
              showProductCategoryErrorMsg
            "
          >
            {{ strapiData.error_submit_category }}
          </div>
          <div
            class="container-category-choice d-flex align-items-center"
            v-if="isMappedCategoryChosen"
          >
            <div class="remove-category-choice">
              <div class="added-category-choice d-flex align-items-center">
                <span
                  >{{ productCategory.category }} -
                  {{ productCategory.type }}</span
                >
                <button
                  class="btn btn-close btn-sm"
                  style="margin-left: 7px; transform: scale(0.8)"
                  type="button"
                  @click="removeChosenCategory"
                  @keyup.enter="removeChosenCategory"
                  aria-label="Fjern valgt kategori"
                ></button>
              </div>
            </div>
            <HelpBtn :helpText="strapiData.category_help" />
          </div>
          <ul
            v-if="filteredCategories.length > 0 && !isMappedCategoryChosen"
            class="auto-suggest"
          >
            <li
              v-for="(mapping, index) in filteredCategories"
              :key="mapping"
              :ref="setListRef"
              @click="selectOnClick(mapping)"
              @keyup.enter="selectOnClick(mapping)"
              @mouseover="this.current = index"
              :class="{
                active: isActive(index),
                'suggestions-list': true,
              }"
            >
              <span>{{ mapping.category }} - {{ mapping.type }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="form-bottom">
        <div class="row align-items-center">
          <div class="col">
            <ReportBackBtn />
          </div>
          <div class="col-8 justify-content-center" style="max-width: 450px">
            <StepProgress
              :steps="this.$store.state.report.myStepsProgress"
              :current-step="0"
            />
          </div>
          <div class="col justify-content-center">
            <ReportForwardBtn
              :disable="!allowNext"
              @click="handleForward"
              @keyup.enter="handleForward"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HelpBtn from "@/components/widgets/HelpBtn.vue";
import getStrapiData from "@/composables/getStrapiDataSingle";
import testMapping from "@/assets/test-mapping.json";
import ReportBackBtn from "@/components/widgets/ReportBackBtn.vue";
import ReportForwardBtn from "@/components/widgets/ReportForwardBtn.vue";
import StepProgress from "@/components/input-cards/StepProgress.vue";
import FormTop from "@/components/input-cards/FormTop.vue";

export default {
  mappings: testMapping,
  components: {
    HelpBtn,
    ReportBackBtn,
    ReportForwardBtn,
    StepProgress,
    FormTop,
  },
  data() {
    return {
      showProductNameErrorMsg: false,
      showProductCategoryErrorMsg: false,
      current: 0,
      listRefs: [],
      productCategory: this.$store.state.report.productCategory,
      inputCategory: this.$store.state.report.productCategory.category,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("input-product-info");
    load();
    return { strapiData, error };
  },
  beforeUpdate() {
    this.listRefs = [];
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    handleInputCategory(e) {
      // Instead of v-model, use combination of :value and @input to
      // get full functionality of dynamic search on mobile, see
      // https://github.com/vuejs/vue/issues/8231
      this.inputCategory = e.target.value;
      this.current = 0;
    },
    toggleProductNameErrorMsg() {
      this.showProductNameErrorMsg = true;
    },
    toggleProductCategoryErrorMsg() {
      this.showProductCategoryErrorMsg = true;
    },
    removeChosenCategory() {
      this.isMappedCategoryChosen = false;
      // For some reason, resetting using this.$store.dispatch("resetProductCategory");
      // causes list items to disappear, so using manual reset instead
      this.$store.commit("report/setProductCategory", {
        productCategory: { category: "", type: "", group: "", email: "" },
      });
      this.inputCategory = "";
    },
    selectOnClick(mapping) {
      this.productCategory = mapping;
      this.current = 0;
      this.isMappedCategoryChosen = true;
      this.$store.commit("report/setProductCategory", {
        productCategory: this.productCategory,
      });
      this.inputCategory = "";
    },
    moveDownList() {
      if (this.filteredCategories.length > 0 && !this.isMappedCategoryChosen) {
        if (this.current < this.filteredCategories.length - 1) {
          this.current++;
        }
        this.fixScrolling(this.current);
      }
    },
    moveUpList() {
      if (this.filteredCategories.length > 0 && !this.isMappedCategoryChosen) {
        if (this.current > 0) {
          this.current--;
        }
        this.fixScrolling(this.current);
      }
    },
    selectOnEnter() {
      if (this.filteredCategories.length > 0 && !this.isMappedCategoryChosen) {
        this.productCategory = this.filteredCategories[this.current];
        this.current = 0;
        this.isMappedCategoryChosen = true;
        this.$store.commit("report/setProductCategory", {
          productCategory: this.productCategory,
        });
        this.inputCategory = "";
      }
    },
    isActive(index) {
      return index === this.current;
    },
    // manually set refs for list items
    setListRef(el) {
      if (el) {
        this.listRefs.push(el);
      }
    },
    // use refs for list items to automatically scroll chosen item into view
    fixScrolling() {
      this.listRefs[this.current].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },
    commitNonMappedToStore() {
      return new Promise((resolve) => {
        this.$store.commit("report/setProductCategory", {
          productCategory: {
            category: this.inputCategory,
            type: "",
            group: "",
            email: this.strapiData.default_email_non_mapped,
          },
        });
        resolve();
      });
    },
    handleForward() {
      if (!this.isMappedCategoryChosen) {
        this.commitNonMappedToStore().then(() => {
          if (this.allowNext) {
            this.$router.push({ name: "InputReason" });
          }
        });
      } else {
        if (this.allowNext) {
          this.$router.push({ name: "InputReason" });
        }
      }
      if (!this.productName) {
        this.toggleProductNameErrorMsg();
      }
      if (!(this.inputCategory || this.isMappedCategoryChosen)) {
        this.toggleProductCategoryErrorMsg();
      }
    },
  },
  computed: {
    filteredCategories() {
      return this.$options.mappings.filter((mapping) => {
        if (this.inputCategory.length >= 1) {
          return mapping.category
            .toLowerCase()
            .includes(this.inputCategory.toLowerCase());
        }
      });
    },
    allowNext() {
      if (
        this.productName &&
        (this.inputCategory || this.isMappedCategoryChosen)
      ) {
        return true;
      } else {
        return false;
      }
    },
    productName: {
      get() {
        return this.$store.state.report.productName;
      },
      set(value) {
        this.$store.commit("report/setProductName", { productName: value });
      },
    },
    brandName: {
      get() {
        return this.$store.state.report.brandName;
      },
      set(value) {
        this.$store.commit("report/setBrandName", { brandName: value });
      },
    },
    isMappedCategoryChosen: {
      get() {
        return this.$store.state.report.isMappedCategoryChosen;
      },
      set(value) {
        this.$store.commit("report/setIsMappedCategoryChosen", {
          isMappedCategoryChosen: value,
        });
      },
    },
  },
};
</script>

<style>
.auto-suggest {
  color: black;
  background: var(--lightblue);
  list-style-type: none;
  padding: 0px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
}
.auto-suggest .suggestions-list {
  padding: 10px 6px 10px 6px;
}
.auto-suggest .suggestions-list:hover {
  cursor: pointer;
}
.auto-suggest .suggestions-list.active {
  background: var(--mediumblue);
}
.container-category-choice {
  background: var(--lightblue);
}
.remove-category-choice {
  display: inline-flex;
  width: 100%;
}
.added-category-choice {
  background: var(--mediumblueopaque);
  padding: 2px 6px;
  margin: 8px 6px;
  border-radius: 6px;
  color: black;
}
</style>
