<template>
  <!-- Main template setting up cross-site UI and components -->
  <div
    :class="{
      'page-container': true,
    }"
  >
    <div v-if="error" class="main-container full-page">
      <!-- If failing to fetch Strapi, show error message -->
      <FetchError
        errorMsg="Der er desværre sket en teknisk fejl, og Produkter.dk er derfor ikke tilgængelig lige nu. Vi arbejder på højtryk på at løse fejlen! Du kan prøve at genindlæse siden her:"
        btnText="Genindlæs Produkter.dk"
        class="mt-5"
      />
    </div>

    <div v-else class="main-img-container">
       <!-- Background image -->
       <img
        :src="require('/src/assets/graphics/produkterdkSIK_Krone_DARK.svg')"
        alt="Baggrundsbillede, krone"
        :class="{
          'bg-img-main': true,
        }"
        :style="{
          top: navBarHeight + 40 + 'px',
          right: '100px',
          height: '30px',
        }"
      />
      <NavBar :hidden="isRouteInputPages" />
      <div :class="{ 'main-container': true, 'full-page': useFullPage }">
        <BreadCrumbs :hidden="isRouteInputPages" />
        <!-- Below router-view renders all main content -->
        <router-view />
      </div>
      <Footer :hidden="isRouteInputPages" />
    </div>
  </div>
</template>

<script>
// Import components visible on the full site
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import FetchError from "@/components/FetchError.vue";
// Import composable to get data from Strapi
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  components: { NavBar, Footer, BreadCrumbs, FetchError },
  data() {
    return {
      navBarHeight: 0,
    };
  },
  setup() {
    // Check to see if Strapi-server responds by fetching dummy data
    const { strapiData, error, load } = getStrapiData("do-not-delete");
    load();
    return { strapiData, error };
  },
  mounted() {
    this.navBarHeight = this.getNavBarHeight;
  },

  computed: {
    getNavBarHeight() {
      return document.getElementById("nav").offsetHeight;
    },
    isRouteInputPages() {
      // Handles UI change at small screen sizes if in report-flow
      let inputPages = [
        "InputProductInfo",
        "InputReason",
        "InputProductNumbers",
        "InputWhereInfo",
        "InputContactInfo",
        "InputConfirmation",
        "InputProducts",
        "InputSituation",
        "InputGuidelines",
      ];

      let evalList = [];
      if (this.$route.matched.length) {
        this.$route.matched.forEach((route) => {
          // Check if current route is in inputPages
          let isMatch = inputPages.some((element) =>
            route.name.includes(element)
          );
          evalList.push(isMatch);
        });
      }
      // Check to see if any elements are true - if so, return true, else, return false
      return evalList.some((element) => element === true);
    },
    useFullPage() {
      // If parent route passed to fullPageNames,
      // all children routes will also be full page
      let fullPageNames = ["Home", "ListProducts", "ProductInfo", "NotFound", "Legislation"];

      let evalList = [];
      fullPageNames.some((element) => {
        if (this.$route.matched.length) {
          // Check if current route is in fullPageNames
          let isMatch = this.$route.matched[0].name === element;
          evalList.push(isMatch);
        }
      });
      // Check to see if any elements are true - if so, return true, else, return false
      return evalList.some((element) => element === true);
    },
  },
};
</script>

<style lang="scss">
/** Importing fonts */
@font-face {
  font-family: sora-bold;
  src: url("/fonts/Sora-Bold.ttf");
}
@font-face {
  font-family: sora-extrabold;
  src: url("/fonts/Sora-ExtraBold.ttf");
}
@font-face {
  font-family: sora-extralight;
  src: url("/fonts/Sora-ExtraLight.ttf");
}
@font-face {
  font-family: sora-light;
  src: url("/fonts/Sora-Light.ttf");
}
@font-face {
  font-family: sora-medium;
  src: url("/fonts/Sora-Medium.ttf");
}
@font-face {
  font-family: sora-regular;
  src: url("/fonts/Sora-Regular.ttf");
}
@font-face {
  font-family: sora-semibold;
  src: url("/fonts/Sora-SemiBold.ttf");
}
@font-face {
  font-family: sora-thin;
  src: url("/fonts/Sora-Thin.ttf");
}

/** Applying fonts and styles globally */
h1 {
  color: black;
  font-family: sora-extrabold;
}

h2,
h3 {
  font-family: sora-semibold;
}

p,
a,
div,
label,
span,
select,
option {
  font-family: sora-light;
}

p,
span,
label,
h1,
h2,
h3,
h4,
h5 {
  // Decision by UI designer to have no hyphenation
  hyphens: none;
  overflow-wrap: break-word;
}

a {
  text-decoration: none !important;
  color: var(--deepblue) !important;
}

/** Defining global colors */
:root {
  --deepblue: rgb(5, 64, 240) ;
  --deepblueopaque: rgba(0, 55, 240, 0.32);
  --mediumblue: rgb(110, 190, 255);
  --mediumblueopaque: rgba(110, 190, 255, 0.5);
  --lightblue: rgba(110, 190, 255, 0.2);
  --white: rgb(255, 255, 255);
  --lightgrey: rgb(239, 239, 239);
  --mediumgrey: rgb(153, 153, 153);
  --darkgrey: rgb(100, 100, 100);
  --darkergrey: rgb(87, 87, 87);
  --lightgreyopaque: rgba(239, 239, 239, 0.9);
  --blackopaque: rgba(10, 9, 9, 0.5);
  --superlightblue: rgb(219, 239, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /** Define colors for vue3-datepicker */
  --vdp-hover-bg-color: var(--deepblue);
  --vdp-selected-bg-color: var(--deepblue);
}

/* Defining page setup and background images (decorations) */
.main-img-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.main-img-container .bg-img-main {
  pointer-events: none;
  position: absolute;
  max-height: 90%;
  max-width: 50%;
  z-index: -1;
  right: 0;
}
.main-img-container .bg-img-sidepanel {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  object-fit: cover;
}
.main-container {
  min-height: 80vh;
  margin: 30px 5% 70px 5%;
}
.card {
  background: transparent !important;
  border: none !important;
}

/* Defining styles of info-pages */
.page-title {
  margin-bottom: 30px;
  color: black;
}
p.page-description {
  font-size: 1.2em;
  // color: var(--deepblue);
}
.inherit-link-styling a,
.info-page a {
  cursor: pointer;
  font-family: sora-semibold;
  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}
.info-page h2 {
  // color: var(--deepblue);
  margin-top: 30px !important;
}
.info-page h3 {
  margin-top: 20px !important;
  font-size: 1.2em;
}
.info-page ul,
.input-contact-info ul {
  list-style: none;
}
.info-page ul li::before,
.input-contact-info ul li::before,
.product-info .fact-box ul li::before {
  content: "\25CF"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  // color: var(--deepblue); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */
  margin-left: -1.5em; /* Also needed for space (tweak if needed) */
  border: none;
  font-size: 1.25em;
}

/* Defining styles of buttons */
.btn {
  border-radius: 10px !important;
  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}
.btn:focus {
  box-shadow: none !important;
}
.btn-close {
  opacity: 0.8 !important;
}
.btn.disabled {
  pointer-events: unset;
  cursor: not-allowed;
}
.btn-primary {
  background-color: var(--deepblue) !important;
  color: white !important;
}
.btn-secondary {
  background-color: white !important;
  color: var(--deepblue) !important;
  border: 1px solid var(--deepblue) !important;
}

/* Defining media queries for responsiveness */
@media only screen and (min-width: 768px) {
  p.page-description {
    font-size: 1.35em;
  }
  p.page-description-shrink {
    padding-right: 25%;
  }
  .main-container {
    margin-left: 26%;
    margin-right: 26%;
  }
  .main-container.full-page {
    margin-left: 5%;
    margin-right: 5%;
  }
  .col.left-panel-full-page {
    flex: unset;
    width: 40%;
  }
  .col.right-panel-full-page {
    flex: unset;
    width: 60%;
  }
  .main-img-container .bg-img-sidepanel {
    height: 100%;
    width: 25%;
  }
  .main-img-container .bg-img-sidepanel.hide {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .main-img-container .bg-img-sidepanel {
    display: none;
  }
  .bg-img-main {
    display: none;
  }
}
@media only screen and (min-width: 1600px) {
  p.page-description-shrink {
    padding-right: 50%;
  }
}
</style>
