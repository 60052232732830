<template>
  <nav
    id="breadcrumbs"
    role="navigation"
    aria-label="breadcrumbs"
    :class="{
      'breadcrumb-container': true,
      'py-4': true,
      'hide-during-report': hidden,
    }"
    style="--bs-breadcrumb-divider: '/'"
    v-if="$route.name != 'Home'"
  >
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item"
        v-for="(name, index) in breadcrumbPaths"
        :key="index"
      >
        <router-link
          :class="{ current: index == breadcrumbPaths.length - 1 }"
          :to="{ name: name }"
          :aria-label="displayName(name)"
          >{{ displayName(name) }}</router-link
        >
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayName(name) {
      let displayName = null;
      this.$router.getRoutes().forEach((element) => {
        if (name == element.name) {
          displayName = element.meta.displayName;
        }
      });
      return displayName;
    },
  },
  computed: {
    breadcrumbPaths() {
      let names = ["Home"];
      this.$route.matched.forEach((element) => {
        // This is a small hack to ensure ListProducts is shown
        if (element.name == "ProductInfo") {
          names.push("ListProducts");
        } // End of small hack
        names.push(element.name);
      });
      return names;
    },
  },
};
</script>

<style>
.breadcrumb-container a {
  color: var(--darkergrey) !important;
}
.breadcrumb-container a:hover {
  font-family: sora-semibold;
  color: var(--darkergrey) !important;
}
.breadcrumb-item {
  font-size: 0.85em;
}
.breadcrumb-container .breadcrumb-item .current {
  font-family: sora-semibold;
  cursor: default;
}
@media only screen and (min-width: 576px) {
  .breadcrumb-item {
    font-size: 1em;
  }
}
</style>
