<template>
  <button
    class="help-info-icon"
    type="button"
    aria-label="Info"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="hover = !hover"
    @keyup.enter="hover = !hover"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="var(--deepblue)"
      class="bi bi-info-square"
      viewBox="-4 -4 24 24"
    >
      <path
        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
      />
      <path
        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
      />
    </svg>
    <div class="help-text" v-if="hover" v-html="helpText"></div>
  </button>
</template>

<script>
export default {
  props: {
    helpText: {
      type: String,
      default: "Hjælpetekst",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style>
.help-text {
  position: absolute;
  background: var(--mediumblue);
  color: black;
  padding: 10px;
  z-index: 20;
  left: 3%;
  right: 3%;
}
.help-info-icon {
  margin: 0px 10px 0px 10px;
  background-color: transparent;
  border: none;
  padding: 0;
}
</style>
