//Initialize default state for report submission
const getDefaultState = () => {
  return {
    productName: "",
    brandName: "",
    productCategory: { category: "", type: "", group: "", email: "" },
    isMappedCategoryChosen: false,
    reasonForReporting: "",
    reasonFiles: [],
    idNumbers: [],
    idFiles: [],
    boughtWhere: null,
    boughtOnline: false,
    boughtInStore: false,
    linkInfos: [],
    storeInfos: [],
    contactInfo: { name: "", phoneNumber: "", email: "" },
    myStepsProgress: [
      "InputProductInfo",
      "InputReason",
      "InputProductNumbers",
      "InputWhereInfo",
      "InputContactInfo",
    ],
  };
};

// Define state (allows for reset of state to default state)
const state = getDefaultState();

const mutations = {
  setProductName(state, payload) {
    state.productName = payload.productName;
  },
  setBrandName(state, payload) {
    state.brandName = payload.brandName;
  },
  setProductCategory(state, payload) {
    state.productCategory = payload.productCategory;
  },
  setIsMappedCategoryChosen(state, payload) {
    state.isMappedCategoryChosen = payload.isMappedCategoryChosen;
  },
  setReason(state, payload) {
    state.reasonForReporting = payload.reasonForReporting;
  },
  setReasonFiles(state, payload) {
    state.reasonFiles = payload.reasonFiles;
  },
  setIdNumbers(state, payload) {
    state.idNumbers = payload.idNumbers;
  },
  setIdFiles(state, payload) {
    state.idFiles = payload.idFiles;
  },
  setBoughtWhere(state, payload) {
    state.boughtWhere = payload.boughtWhere;
  },
  setBoughtOnline(state, payload) {
    state.boughtOnline = payload.boughtOnline;
  },
  setBoughtInStore(state, payload) {
    state.boughtInStore = payload.boughtInStore;
  },
  setLinkInfos(state, payload) {
    state.linkInfos = payload.linkInfos;
  },
  setStoreInfos(state, payload) {
    state.storeInfos = payload.storeInfos;
  },
  setContactInfoName(state, name) {
    state.contactInfo.name = name;
  },
  setContactInfoPhoneNumber(state, phoneNumber) {
    state.contactInfo.phoneNumber = phoneNumber;
  },
  setContactInfoEmail(state, email) {
    state.contactInfo.email = email;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_STATE_PRODUCTCATEGORY(state) {
    Object.assign(state.productCategory, getDefaultState().productCategory);
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  resetProductCategory({ commit }) {
    commit("RESET_STATE_PRODUCTCATEGORY");
  },
};

export default { namespaced: true, state, mutations, actions };
