/**
 * Read the contents of a file as a base64 string. 
 *
 * @param {file} file
 * @returns {string} in base64 format.
 */
function readFile(file) {
    return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = () => {
            //split is used to split "data:image/png;base64,......." from the rest of the content
            resolve(fr.result.split(',')[1]);
        };
        fr.onerror = reject;
        fr.readAsDataURL(file);
    });
}

export default readFile