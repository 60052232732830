<template>
  <div class="products-filter-modal">
    <!-- Button trigger modal -->
    <div class="d-flex row">
      <a
        data-bs-toggle="modal"
        data-bs-target="#filterPanelModal"
        style="
          cursor: pointer;
          text-align: right;
          font-family: sora-semibold;
          font-size: 12pt;
        "
        class="filter-link float-end mt-2"
      >
        {{ title }}
      </a>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="filterPanelModal"
      tabindex="-1"
      aria-labelledby="filterPanelModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        style="width: 100vw; margin: 0; position: absolute; bottom: 0"
      >
        <div class="modal-content rounded-0" style="border: none">
          <div
            class="modal-header rounded-0"
            style="
              background-color: var(--deepblue);
              color: white;
              padding: 10px 20px;
              border: none;
            "
          >
            <h5
              class="modal-title"
              id="filterPanelModalLabel"
              style="font-family: sora-semibold; font-size: 14pt"
            >
              {{ title }}
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="border: none">
            <slot></slot>
          </div>
          <div
            class="modal-footer rounded-0 justify-content-around"
            style="border-top: none; border-bottom: 10px solid var(--deepblue)"
          >
            <button
              @click="clearFilters"
              @keyup.enter="clearFilters"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              style="width: 40%"
            >
              {{ clearFiltersTitle }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              style="width: 40%"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    clearFiltersTitle: {
      type: String,
      default: "",
    },
  },
  emits: ["filtersUpdated"],
  methods: {
    clearFilters() {
      if (
        this.$store.state.products.filtersInput.complianceFilters.length > 0 ||
        this.$store.state.products.filtersInput.categoryFilters.length > 0 ||
        this.$store.state.products.filtersInput.authorityFilters.length > 0
      ) {
        this.$store.commit("products/setComplianceFilters", {
          complianceFilters: [],
        });
        this.$store.commit("products/setCategoryFilters", {
          categoryFilters: [],
        });
        this.$store.commit("products/setAuthorityFilters", {
          authorityFilters: [],
        });
        this.$emit("filtersUpdated");
      }
    },
  },
};
</script>

<style>
.products-filter-modal .modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
}
.products-filter-modal .modal.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s ease-out;
}
.products-filter-modal .modal-dialog {
  max-width: unset !important;
}
.products-filter-modal .filter-link::before {
  content: "\2807 ";
}
.products-filter-modal .modal-title::before {
  content: "\2807 ";
}
</style>
