<template>
  <button
    type="button"
    class="btn btn-primary btn-floating"
    id="btn-back-to-top"
    @click="backToTop"
    @keyup.enter="backToTop"
  >
    <ArrowTitle
      :title="btnText"
      fontSize="0.65em"
      pixelSize="25px"
      paddingTop="6px"
      :altColors="true"
    />
  </button>
</template>

<script>
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";

export default {
  props: {
    btnText: {
      type: String,
      default: "Gå til top",
    },
  },
  components: { ArrowTitle },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  updated() {
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      const toTopBtn = document.getElementById("btn-back-to-top");
      const productCardList = document.getElementsByClassName(
        "product-card-list"
      )[0];
      const navBar = document.getElementById("nav");
      const paginationContainer = document.getElementsByClassName(
        "pagination-container"
      )[0];

      if (toTopBtn && productCardList && navBar && paginationContainer) {
        // backToTopBtn should be visible when list view hits bottom of navBar
        let displayStart = productCardList.offsetTop - navBar.offsetHeight; // account for navBar
        // backToTopBtn should be hidden when it hits bottom of pagination container
        let displayEnd =
          productCardList.offsetTop + // account for start of list view
          productCardList.offsetHeight - // account for height of list view
          0.8 * document.documentElement.clientHeight + // account for backToTopBtn starting at 20vh - change if CSS "bottom: 20vh" is changed!
          paginationContainer.offsetHeight; // account for pagination component

        if (
          (document.body.scrollTop > displayStart &&
            document.body.scrollTop < displayEnd) ||
          (document.documentElement.scrollTop > displayStart &&
            document.documentElement.scrollTop < displayEnd)
        ) {
          toTopBtn.style.display = "block";
        } else {
          toTopBtn.style.display = "none";
        }
      }
    },
  },
};
</script>

<style lang="scss">
#btn-back-to-top {
  position: fixed;
  bottom: 20vh;
  right: -35px;
  display: none;
  z-index: 1;
  transform: rotate(-90deg);
  border-radius: 10px 10px 0 0 !important;
  border: 2px solid white;
  &:hover,
  &:focus {
    transform: rotate(-90deg) scale(1.05);
  }
}
</style>
