<template>
    <div v-for="(guideBlock, index) in guideData" :key="index">
        <div class="title-block d-flex align-items-center">
            <ArrowTitle
                fontSize="1em"
                paddingTop="0px"
                pixelSize="55px"
                />
            <div class="title-format d-flex">
                {{ guideBlock.title }}
            </div>
        </div>
        <div v-for="(accordion, index) in guideBlock.accordions" 
        :key="index"
        :class="{'border-top': notFirst(index)}"
        >
        <GuideAccordion
        :title="accordion.title"
        :description="accordion.description"
        :accordionId="accordion.id"
        />
        </div>
    </div>
</template>

<script>
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";
import GuideAccordion from "@/components/legislation/GuideAccordion.vue"

export default {
    props: ["guideData"],
    components: { 
        ArrowTitle,
        GuideAccordion,
    },
    methods: {
        notFirst(index) {
            return index;
        },
    },
};
</script>

<style>
.title-block {
    margin-top: 70px;
}
.title-format {
    font-family: sora-bold;
    color: var(--deepblue);
    font-size: 1.5rem;
    margin-left: 20px;
}
.st0 {
  fill: none;
  stroke: #6ebfff;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: #0038f0;
  stroke-width: 14;
  stroke-miterlimit: 10;
}




</style>