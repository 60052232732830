<template>
  <div
    class="data-policy info-page"
    v-html="strapiData.content"
    role="main"
  ></div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  name: "DataPolicy",
  components: {},
  setup() {
    const { strapiData, error, load } = getStrapiData("data-policy");
    load();
    return { strapiData, error };
  },
};
</script>
