<template>
  <nav
    id="nav"
    role="navigation"
    aria-label="main menu"
    :class="{
      navbar: true,
      'navbar-expand-lg': true,
      'navbar-light': true,
      'hide-during-report': hidden,
    }"
  >
    <div class="container-fluid" style="padding: 0">
      <div class="col-4 navbar-brand-container">
        <router-link
          :to="{ name: 'Home' }"
          class="navbar-brand"
          aria-label="Forside"
        >
          <img
            :src="require('/src/assets/graphics/logo_langt.svg')"
            alt="Produkter.dk logo"
            style="height: 35px"
          />
        </router-link>
      </div>
      <button
        class="navbar-toggler"
        style="border: none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img
          :src="require('/src/assets/graphics/burgermenu.svg')"
          alt="burgermenu.svg"
          style="height: 25px"
        />
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        style="flex-grow: unset"
      >
      <!-- TODO combine sortPages -->
        <ul
          class="navbar-nav me-3 mb-2 mb-lg-0"
          v-for="subPage in sortPagesSingle(navBarContent.sub_pages)"
          :key="subPage.id"
        >
          <li class="nav-item" v-if="!checksubsubpage(subPage)">
            <router-link
              v-if="doesPageExist(subPage)"
              :to="{ name: subPage.attributes.name }"
              class="nav-link active no-border"
              aria-current="page"
              @click="toggleNavBar"
              >{{ subPage.attributes.display_name }}</router-link
            >
          </li>
          <li class="nav-item dropdown" v-else>
            <a
              class="nav-link dropdown-toggle"
              :id="subPage.attributes.display_name"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="javascript:void(0)"
            >
              {{ subPage.attributes.display_name }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li
                v-for="subSubPage in subSubPages(subPage)"
                :key="subSubPage.id"
                @click="toggleNavBar"
                class="mt-1"
              >
                <router-link
                  :to="{ name: subSubPage.attributes.name }"
                  class="dropdown-item"
                  >{{ subSubPage.attributes.display_name }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import * as bootstrap from "bootstrap";
import { useStore } from "vuex";

export default {
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const {
      strapiData: navBarContent,
      error: errorNavBarContent,
      load: loadNavBarContent,
    } = getStrapiData("nav-bar-content");

    if (!store.state.general.navBarContent.length > 0) {
      loadNavBarContent().then(() => {
        store.commit("general/setNavBarContent", {
          navBarContent: navBarContent.value,
        });
      });
    }

    const {
      strapiData: subPages,
      error: errorSubPages,
      load: loadSubPages,
    } = getStrapiDataCollection("sub-pages");

    if (!store.state.general.subPages.length > 0) {
      loadSubPages().then(() => {
        store.commit("general/setSubPages", { subPages: subPages });
      });
    }

    return { errorNavBarContent, errorSubPages };
  },
  methods: {
    doesPageExist(subPage) {
      try {
        this.$router.resolve({ name: subPage.attributes.name });
      } catch (error) {
        return false;
      }
      return this.$router.resolve({ name: subPage.attributes.name }).href !== "#/";
    },
    sortPages(pages) {
      if (pages) {
        return pages.sort((a, b) => (a.attributes.order > b.attributes.order ? 1 : -1));
      } else {
        return pages;
      }
    }, 
    sortPagesSingle(pages) {
      if (pages) {
        let data = pages.data;
        return data.sort((a, b) => (a.attributes.order > b.attributes.order ? 1 : -1));
      } else {
        return pages;
      }
    },
    subSubPages(subPage) {
      let subSubPages = [];
      if (this.subPages) {
        this.subPages.forEach((element) => {
          if (element.name == subPage.attributes.name) {
            subSubPages = element.sub_sub_pages.data;
          }
        });
      }
      return this.sortPages(subSubPages);
    },
    checksubsubpage(subPage){
      let subSubPages = [];
      if (this.subPages) {
        this.subPages.forEach((element) => {
          if (element.name == subPage.attributes.name) {
            subSubPages = element.sub_sub_pages.data;
            return;
          }
        });
      }
      return subSubPages.length == 0 ? false: true;
    },
    toggleNavBar() {
      let navbarSupportedContent = document.getElementById(
        "navbarSupportedContent"
      );
      let collapseNavBar = new bootstrap.Collapse(navbarSupportedContent);
      collapseNavBar.toggle();
    },
  },
  computed: {
    subPages: {
      get() {
        return this.$store.state.general.subPages;
      },
    },
    navBarContent: {
      get() {
        return this.$store.state.general.navBarContent;
      },
    },
  },
};
</script>

<style lang="scss">
#nav {
  background: white;
  -webkit-box-shadow: 0px 3px 5px var(--mediumgrey);
  -moz-box-shadow: 0px 3px 5px var(--mediumgrey);
  box-shadow: 0px 3px 5px var(--mediumgrey);
  width: 100%;
  padding: 20px 5% 20px 5%;
  border-top: 10px solid var(--deepblue);
  position: sticky;
  top: 0;
  z-index: 10;
}
#nav a {
  color: var(--deepblue);
  text-decoration: none;
  font-family: sora-semibold;
  font-size: small;
  &:hover,
  &:focus {
    color: var(--mediumblue) !important;
  }
}
#nav a.dropdown-item {
  color: var(--darkgrey) !important;
  font-family: sora-light !important;
  &:hover,
  &:focus {
    font-family: sora-semibold !important;
    background-color: unset !important;
  }
}
button.navbar-toggler:active {
  color: white;
}
button.navbar-toggler:focus {
  color: white;
}
.no-border {
  border: white !important;
}
@media only screen and (max-width: 991.98px) {
  .collapse.navbar-collapse {
    margin-top: 20px;
  }
}
</style>
