<template>
  <!-- Fourth step in report-flow, path: '/anmeldelser/4' -->
  <div class="card report input-where-info" role="form">
    <form class="form" @keydown.enter.prevent @submit.prevent>
      <FormTop
        :title="strapiData.title"
        :description="strapiData.description"
      />
      <div class="form-body">
        <div class="label-with-help">
          <label>{{ strapiData.where_label }}</label>
          <span class="report-input-required">Obligatorisk</span>
        </div>

        <div class="terms">
          <input
            type="radio"
            id="online"
            value="boughtOnline"
            v-model="boughtWhere"
            required
            style="margin-top: -12px"
          />
          <label class="sub-label" for="online" style="margin-top: 10px">{{
            strapiData.bought_online
          }}</label>
        </div>
        <div class="terms">
          <input
            type="radio"
            id="store"
            value="boughtInStore"
            v-model="boughtWhere"
            required
            style="margin-top: -12px"
          />
          <label class="sub-label" for="store" style="margin-top: 10px">{{
            strapiData.bought_store
          }}</label>
        </div>

        <div class="error-container" v-if="!boughtWhere && showErrorMsg">
          <div class="error">
            {{ strapiData.error_submit }}
          </div>
        </div>

        <div class="input-bought-where" v-if="boughtWhere == 'boughtOnline'">
          <div class="label-with-help">
            <label>{{ strapiData.when_label }}</label>
            <span class="report-input-required">Obligatorisk</span>
          </div>
          <label class="sub-label">{{
            strapiData.when_sub_label_online
          }}</label>
          <label id="linkInfoLabel">{{ strapiData.online_label }}</label>
          <div class="input-with-help">
            <input
              class="input-link"
              required
              type="text"
              v-model="linkAddress"
              :placeholder="strapiData.link_placeholder"
              aria-labelledby="linkInfoLabel"
              @blur="toggleErrorMsg('linkAddress')"
            />
            <HelpBtn :helpText="strapiData.when_help_online" />
          </div>
          <div
            v-if="
              linkAddress && showLinkAddressErrorMsg && !isValidUrl(linkAddress)
            "
            class="error"
          >
            {{ strapiData.link_error }}
          </div>

          <label id="linkDateLabel" style="margin-top: 15px">{{
            strapiData.date_label
          }}</label>
          <div class="input-with-help">
            <!-- Native HTML-5 date input  
            <input
              class="date-picker"
              type="text"
              v-model="pickedDate"
              :placeholder="strapiData.date_placeholder"
              onfocus="(this.type='date')"
              :max="today"
              aria-labelledby="linkDateLabel"
              @blur="toggleErrorMsg('date')"
            /> -->
            <Datepicker
              class="date-picker"
              :placeholder="strapiData.date_placeholder"
              :modelValue="pickedDate"
              :upperLimit="today"
              :typeable="true"
              :clearable="true"
              inputFormat="dd-MM-yyyy"
              :locale="locale"
              @blur="toggleErrorMsg('date')"
              aria-labelledby="linkDateLabel"
              @update:modelValue="handleValidDate"
              ><template v-slot:clear="{ onClear }"
                ><button
                  type="button"
                  class="btn"
                  @click="onClear"
                  :aria-label="strapiData.clear_date"
                  style="white-space: nowrap; color: var(--deepblue)"
                >
                  {{ strapiData.clear_date }}
                </button></template
              ></Datepicker
            >
            <HelpBtn :helpText="strapiData.when_help_date" />
          </div>
          <div
            class="error"
            v-if="showDateErrorMsg && !isValidDate(pickedDate)"
          >
            {{ strapiData.date_error }}
          </div>

          <button
            type="button"
            @click="addLinkInfo"
            @keyup.enter="addLinkInfo"
            :class="{
              btn: true,
              'btn-primary': true,
              'my-3': true,
              disabled: !isValidUrl(linkAddress) || !isValidDate(pickedDate),
            }"
          >
            {{ strapiData.button_text_online }}
          </button>
          <div class="error" v-if="showLinkErrorMsg && !linkInfos.length">
            {{ strapiData.no_links_error }}
          </div>

          <div v-if="linkInfos.length">
            <div class="label-with-help">
              <label class="your-choices">{{
                strapiData.added_links_label
              }}</label>
              <HelpBtn :helpText="strapiData.added_links_help" />
            </div>
          </div>
          <div v-for="linkInfo in linkInfos" :key="linkInfo">
            <div class="remove-choices d-flex align-items-center">
              <div class="added-choices">
                {{ stringDisplayLink(linkInfo) }}
              </div>
              <button
                class="btn btn-close btn-sm"
                type="button"
                @click="
                  removeLinkInfo(linkInfo, strapiData.remove_link_message)
                "
                @keyup.enter="
                  removeLinkInfo(linkInfo, strapiData.remove_link_message)
                "
                aria-label="Fjern"
              ></button>
            </div>
          </div>
        </div>

        <div class="input-bought-where" v-if="boughtWhere == 'boughtInStore'">
          <div class="label-with-help">
            <label>{{ strapiData.when_label }}</label>
            <span class="report-input-required">Obligatorisk</span>
          </div>
          <label class="sub-label">{{ strapiData.when_sub_label_store }}</label>

          <label id="storeInfoLabel">{{ strapiData.store_label }}</label>
          <div class="input-with-help">
            <input
              required
              type="text"
              v-model="storeName"
              :placeholder="strapiData.store_name_placeholder"
              aria-labelledby="storeInfoLabel"
            />
            <HelpBtn :helpText="strapiData.when_help_store" />
          </div>

          <input
            class="input-no-label"
            style="background-color: var(--lightblue) !important"
            type="text"
            v-model="storeAddress"
            :placeholder="strapiData.store_address_placeholder"
            aria-labelledby="storeInfoLabel"
          />

          <div class="zip-city-container">
            <input
              class="input-no-label zip"
              style="background-color: var(--lightblue) !important"
              type="number"
              v-model="storeZip"
              :placeholder="strapiData.store_zip_placeholder"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxLength="4"
              aria-labelledby="storeInfoLabel"
              @blur="toggleErrorMsg('storeZip')"
            />
            <div
              v-if="isZipValid(storeZip)"
              class="city-display input-no-label"
            >
              {{ getCity(storeZip) }}
            </div>
          </div>
          <div
            class="error"
            v-if="storeZip && showZipErrorMsg && !isZipValid(storeZip)"
          >
            {{ strapiData.zip_error }}
          </div>

          <label id="storeDateLabel" style="margin-top: 15px">{{
            strapiData.date_label
          }}</label>
          <div class="input-with-help">
            <Datepicker
              class="date-picker"
              :placeholder="strapiData.date_placeholder"
              :modelValue="pickedDate"
              :upperLimit="today"
              :typeable="true"
              :clearable="true"
              inputFormat="dd-MM-yyyy"
              :locale="locale"
              @blur="toggleErrorMsg('date')"
              aria-labelledby="storeDateLabel"
              @update:modelValue="handleValidDate"
              ><template v-slot:clear="{ onClear }"
                ><button
                  type="button"
                  class="btn"
                  @click="onClear"
                  :aria-label="strapiData.clear_date"
                  style="white-space: nowrap; color: var(--deepblue)"
                >
                  {{ strapiData.clear_date }}
                </button></template
              ></Datepicker
            >
            <HelpBtn :helpText="strapiData.when_help_date" />
          </div>
          <div
            class="error"
            v-if="showDateErrorMsg && !isValidDate(pickedDate)"
          >
            {{ strapiData.date_error }}
          </div>

          <button
            type="button"
            @click="addStoreInfo"
            @keyup.enter="addStoreInfo"
            :class="{
              btn: true,
              'btn-primary': true,
              'my-3': true,
              disabled:
                !storeName ||
                !storeAddress ||
                !isZipValid(storeZip) ||
                !isValidDate(pickedDate),
            }"
          >
            {{ strapiData.button_text_store }}
          </button>
          <div class="error" v-if="showStoreErrorMsg && !storeInfos.length">
            {{ strapiData.no_stores_error }}
          </div>

          <div v-if="storeInfos.length">
            <div class="label-with-help">
              <label class="your-choices">{{
                strapiData.added_stores_label
              }}</label>
              <HelpBtn :helpText="strapiData.added_stores_help" />
            </div>
          </div>
          <div v-for="storeInfo in storeInfos" :key="storeInfo">
            <div class="remove-choices d-flex align-items-center">
              <div class="added-choices">
                {{ stringDisplayStore(storeInfo) }}
              </div>
              <button
                class="btn btn-close btn-sm"
                type="button"
                @click="
                  removeStoreInfo(storeInfo, strapiData.remove_store_message)
                "
                @keyup.enter="
                  removeStoreInfo(storeInfo, strapiData.remove_store_message)
                "
                aria-label="Fjern"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-bottom">
        <div class="row align-items-center">
          <div class="col">
            <ReportBackBtn />
          </div>
          <div class="col-8 justify-content-center" style="max-width: 450px">
            <StepProgress
              :steps="this.$store.state.report.myStepsProgress"
              :current-step="3"
            />
          </div>
          <div class="col">
            <ReportForwardBtn
              :disable="!allowNext"
              @click="handleForward"
              @keyup.enter="handleForward"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HelpBtn from "@/components/widgets/HelpBtn.vue";
import Datepicker from "vue3-datepicker";
import { da } from "date-fns/locale";
import moment from "moment";
import getStrapiData from "@/composables/getStrapiDataSingle";
import ZipCodesDK from "@/assets/zip-codes-dk.json";
import ReportBackBtn from "@/components/widgets/ReportBackBtn.vue";
import ReportForwardBtn from "@/components/widgets/ReportForwardBtn.vue";
import StepProgress from "@/components/input-cards/StepProgress.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import { ref } from "vue";

export default {
  zipCodes: ZipCodesDK,
  components: {
    HelpBtn,
    Datepicker,
    ReportBackBtn,
    ReportForwardBtn,
    StepProgress,
    FormTop,
  },
  data() {
    return {
      linkAddress: "",
      storeName: "",
      storeAddress: "",
      storeZip: "",
      locale: da,
      showErrorMsg: false,
      showLinkErrorMsg: false,
      showStoreErrorMsg: false,
      showDateErrorMsg: false,
      showLinkAddressErrorMsg: false,
      showZipErrorMsg: false,
      today: new Date(),
      formHeight: null,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("input-where-info");
    load();

    const pickedDate = ref(null);

    return { strapiData, error, pickedDate };
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    handleValidDate(e) {
      if (e) {
        if (e.getFullYear().toString().length === 4) {
          this.pickedDate = e;
        }
      } else {
        this.pickedDate = null;
      }
    },
    getCity(zip) {
      let city = "";
      this.$options.zipCodes.forEach((element) => {
        if (element.zip == zip) {
          city = element.city;
        }
      });
      return city;
    },
    isValidUrl(url) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(url);
    },
    isValidDate(date) {
      if (date) {
        if (this.pickedDate <= this.today) {
          return true;
        }
      } else {
        return false;
      }
    },
    addLinkInfo() {
      if (!this.isValidDate(this.pickedDate)) {
        this.toggleErrorMsg("date");
      } else if (
        this.isValidUrl(this.linkAddress) &&
        this.isValidDate(this.pickedDate)
      ) {
        let linkInfo = {
          date: moment(this.pickedDate).format(),
          link: this.linkAddress,
        };
        this.linkInfos.push(linkInfo);
        console.log("Adding link: ", linkInfo);

        //Refresh to default selection
        this.pickedDate = null;
        this.linkAddress = "";
        this.showLinkAddressErrorMsg = false;
        this.showDateErrorMsg = false;
      }
    },
    removeLinkInfo(linkInfo, textAlert) {
      if (confirm(textAlert)) {
        this.linkInfos = this.linkInfos.filter((item) => {
          return linkInfo !== item;
        });
        console.log("Removing link: ", linkInfo);
      }
    },
    isZipValid(zip) {
      return this.$options.zipCodes.some((item) => item.zip == zip);
    },
    addStoreInfo() {
      if (!this.isValidDate(this.pickedDate)) {
        this.toggleErrorMsg("date");
      } else if (
        this.isZipValid(this.storeZip) &&
        this.isValidDate(this.pickedDate)
      ) {
        if (this.storeName && this.storeAddress) {
          let storeInfo = {
            name: this.storeName,
            address: this.storeAddress,
            zipCode: this.storeZip,
            date: moment(this.pickedDate).format(),
          };
          this.storeInfos.push(storeInfo);
          console.log("Adding store: ", storeInfo);

          //Refresh to default selection
          this.pickedDate = null;
          this.storeName = "";
          this.storeAddress = "";
          this.storeZip = "";
          this.showZipErrorMsg = false;
          this.showDateErrorMsg = false;
        }
      }
    },
    removeStoreInfo(storeInfo, textAlert) {
      if (confirm(textAlert)) {
        this.storeInfos = this.storeInfos.filter((item) => {
          return storeInfo !== item;
        });
        console.log("Removing store: ", storeInfo);
      }
    },
    toggleErrorMsg(error) {
      if (error === "where") {
        this.showErrorMsg = true;
      } else if (error === "link") {
        this.showLinkErrorMsg = true;
      } else if (error === "store") {
        this.showStoreErrorMsg = true;
      } else if (error === "date") {
        !this.isValidDate(this.pickedDate)
          ? (this.showDateErrorMsg = true)
          : (this.showDateErrorMsg = false);
      } else if (error === "linkAddress") {
        if (this.linkAddress) {
          !this.isValidUrl(this.linkAddress)
            ? (this.showLinkAddressErrorMsg = true)
            : (this.showLinkAddressErrorMsg = false);
        }
      } else if (error === "storeZip") {
        if (this.storeZip) {
          !this.isValidUrl(this.storeZip)
            ? (this.showZipErrorMsg = true)
            : (this.showZipErrorMsg = false);
        }
      }
    },
    stringDisplayStore(storeInfo) {
      let string = "";
      if (storeInfo.date && storeInfo.name && storeInfo.address) {
        string =
          moment(storeInfo.date).format("DD-MM-YYYY") +
          ": " +
          storeInfo.name +
          ", " +
          storeInfo.address;
      } else if (storeInfo.date && storeInfo.name) {
        string =
          moment(storeInfo.date).format("DD-MM-YYYY") + ": " + storeInfo.name;
      }
      return string;
    },
    stringDisplayLink(linkInfo) {
      return moment(linkInfo.date).format("DD-MM-YYYY") + ": " + linkInfo.link;
    },
    handleForward() {
      if (this.boughtWhere) {
        if (this.boughtWhere === "boughtOnline") {
          this.boughtOnline = true;
          this.boughtInStore = false;
          if (!this.linkInfos.length) {
            this.toggleErrorMsg("link");
          }
        } else if (this.boughtWhere === "boughtInStore") {
          this.boughtOnline = false;
          this.boughtInStore = true;
          if (!this.storeInfos.length) {
            this.toggleErrorMsg("store");
          }
        }
      } else {
        this.toggleErrorMsg("where");
      }
    },
  },
  computed: {
    allowNext() {
      if (this.boughtWhere === "boughtOnline" && this.linkInfos.length) {
        return true;
      } else if (
        this.boughtWhere === "boughtInStore" &&
        this.storeInfos.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    boughtWhere: {
      get() {
        return this.$store.state.report.boughtWhere;
      },
      set(value) {
        this.$store.commit("report/setBoughtWhere", { boughtWhere: value });
      },
    },
    boughtOnline: {
      get() {
        return this.$store.state.report.boughtOnline;
      },
      set(value) {
        this.$store.commit("report/setBoughtOnline", { boughtOnline: value });
      },
    },
    boughtInStore: {
      get() {
        return this.$store.state.report.boughtInStore;
      },
      set(value) {
        this.$store.commit("report/setBoughtInStore", { boughtInStore: value });
      },
    },
    linkInfos: {
      get() {
        return this.$store.state.report.linkInfos;
      },
      set(value) {
        this.$store.commit("report/setLinkInfos", { linkInfos: value });
      },
    },
    storeInfos: {
      get() {
        return this.$store.state.report.storeInfos;
      },
      set(value) {
        this.$store.commit("report/setStoreInfos", { storeInfos: value });
      },
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.input-where-info .error {
  width: 100%;
}
.zip-city-container {
  display: inline-flex;
  width: 100%;
}
.input-no-label.zip {
  width: 100%;
  min-width: 60px;
}
.zip-city-container .city-display {
  margin-left: 10px;
  padding: 10px;
  background: var(--lightblue);
  min-width: 140px;
}
.error-container {
  margin-bottom: 20px;
}
.v3dp__datepicker {
  width: 100% !important;
}
.v3dp__input_wrapper {
  display: flex !important;
  align-items: center;
}
.v3dp__clearable {
  position: unset;
  margin: 0 15px 0 5px;
}
</style>
