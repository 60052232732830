<template>
  <div class="card prod-cat-card clickable" @click="checked = !checked"  >
    <div class="align-card">
          <div class="checkbox" >
            <input v-model="checked" :value="productCard.id" type="checkbox" :id="productCard.title" :aria-label="'produkttype-' + productCard.title" style="height: 20px; width: 20px;" />
          </div>
          <div class="fixed-image-box">
            <div v-if="productCard.image.data" class="card-img-container">
              <img :src="useSmallSize(productCard.image.data.attributes.formats.small.url)" alt="Produktbillede" class="card-img" />
            </div>
          <div v-else class="card-img-container">
            <img
              :src="require('/src/assets/graphics/placeholder.svg')"
              alt="Placeholder, produktbillede"
              class="card-img"
            />
    </div>
          </div>
          <div>
            <div class="clickable" style="margin-left: 25px;">
              <label :for="productCard.id" style="font-size: 1em !important;">
                <p class="product-name clickable">{{ productCard.title }}</p>
                <p class="product-description clickable">{{ productCard.description }}</p>
              </label>
            </div>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  props: ["productCard"],
  mounted() {
  },
  methods: {
    useSmallSize(url) {
      return process.env.VUE_APP_STRAPI_API + url.substring(1);
    },
    checkProductSelected(array){
      return array.length === 0 ? false: array.includes(this.productCard.id);
    },
    updateSelectedStore(selected){
      if(selected) this.$store.commit("legislation/addSelectedProduct", { id: this.productCard.id });
        else this.$store.commit("legislation/removeSelectedProduct", { id: this.productCard.id });
    },
  },
  computed: {
    checked: {
      get() {
        return this.checkProductSelected(this.$store.state.legislation.selectedProducts);
      },
      set(value) {
        this.updateSelectedStore(value);
      },
    },
  },
};
</script>

<style lang="scss">

.product-name {
  font-size: 1em;
  padding: 15px 0 0 0;
  margin: 0;
  font-family: sora-semibold;
  color: var(--deepblue);
}
.product-description {
  font-size: 1em;
  padding: 5px 0 0 0;
  margin: 0;
  color: black;
  max-width: 650px;
}
.prod-cat-card .card-img {
  border-radius: 0px;
}
.card-img-container {
  position: relative;
  width: 100%;
}
.card-img-container .card-img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}
.checkbox{
  margin: 30px 20px;
}
.card {
  margin: 25px 0px;
}

.fixed-image-box{
  width:80px; /* or whatever width you want. */
  display: inline-block;
}

.align-card{
  display: flex; 
  align-items: center;
}

.clickable {
  cursor: pointer;
}

@media only screen and (max-width: 767.98px) {
  .fixed-image-box {
    display: none;
  }
  .product-description {
    padding-right: 5px;
  }
}
</style>
