/**
 * Remove special characters for URL. 
 *
 * @param {string} text
 * @returns {string} 
 */
function normaliseText(text) {
    text = text.toLowerCase();
    text = text.replace("å","aa");
    text = text.replace("æ","ae");
    text = text.replace("ø","oe");
    return text;
}

export default normaliseText