<template>
  <div v-if="productImageUrls.length" class="row">
    <div
      v-for="(url, index) in productImageUrls"
      :key="index"
      :class="{ 'mt-4': index > 0, 'col-4': index > 0 }"
    >

      <div
        @click="moveToFirstIndex(productImageUrls, index)"
        @keyup.enter="moveToFirstIndex(productImageUrls, index)"
        :class="{
          card: true,
          'product-card': true,
          'product-card-no-animation': index == 0,
        }"
        style="cursor: pointer"
        tabindex="0"
      >
        <div v-if="url" class="card-img-container">
          <img :src="url" alt="Produktbillede" class="card-img" />
        </div>

        <div v-else class="card-img-container">
          <img
            :src="require('/src/assets/graphics/placeholder.svg')"
            alt="Placeholder, produktbillede"
            class="card-img"
          />
        </div>
        <div v-if="index == 0 && complianceType" class="card-img-overlay" @click="toggleModal(url)">
          <div
            :class="{
              'product-compliance': true,
              'product-compliance-dangerous':
                productCard.complianceType.name == 'FAR',
            }"
          >
            {{ complianceType }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card product-card product-card-no-animation">
      <div class="card-img-container">
        <img
          :src="require('/src/assets/graphics/placeholder.svg')"
          alt="Placeholder, produktbillede"
          class="card-img"
        />
      </div>

      <div class="card-img-overlay" v-if="complianceType">
        <div
          :class="{
            'product-compliance': true,
            'product-compliance-dangerous':
              productCard.complianceType.name == 'FAR',
          }"
        >
          {{ complianceType }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal" class="modalTest">
    <Modal v-if="showModal" @close="toggleModal">
      <template v-slot:full-size-image>
        <img :src="currentImageUrl" alt="Produktbillede" class="card-img" />
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import Modal from '../FullSizeImage.vue'

export default {
  props: ["productCard", "complianceType"],
  components: { Modal },
  data() {
    return { 
      productImageUrls: [],
      showModal: false,
      currentImageUrl: null,
    };
  },
  mounted() {
    if (this.productCard.productPictures.length) {
      this.productCard.productPictures.forEach((element) => {
        if (this.productImageDict[element.pictureId]) {
          if (element.productPictureCategory.name === "PRODUKT") {
            this.productImageUrls.unshift(
              this.productImageDict[element.pictureId]
            );
          } else {
            this.productImageUrls.push(
              this.productImageDict[element.pictureId]
            );
          }
        } else {
          this.getProductPicture(
            element.pictureId,
            element.productPictureCategory.name
          );
        }
      });
    }
  },
  methods: {
    toggleModal(url) {
      this.showModal = !this.showModal
      if (url) {
        this.currentImageUrl = url
      }
    },
    moveToFirstIndex(array, fromIndex) {
      if (fromIndex > 0) {
        [array[0], array[fromIndex]] = [array[fromIndex], array[0]];
      }
    },
    getProductPicture(pictureId, pictureCategory) {
      axios
        .get(process.env.VUE_APP_PRODUCTS_API + "picture/" + pictureId, {
          responseType: "blob",
        })
        .then((response) => {
          console.log(
            "Response from " +
              process.env.VUE_APP_PRODUCTS_API +
              "picture/" +
              pictureId,
            response.data
          );
          let productImageUrl = URL.createObjectURL(response.data);
          if (pictureCategory === "PRODUKT") {
            this.productImageUrls.unshift(productImageUrl);
          } else {
            this.productImageUrls.push(productImageUrl);
          }
          this.$store.commit("products/addImageUrl", {
            id: pictureId,
            url: productImageUrl,
          });
        })
        .catch((error) => {
          console.log(
            "Error from " +
              process.env.VUE_APP_PRODUCTS_API +
              "picture/" +
              pictureId,
            error
          );
        });
    },
  },
  computed: {
    productImageDict: {
      get() {
        return this.$store.state.products.productImageDict;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.card-img-overlay .product-compliance {
  font-size: 12pt;
  padding: 7px 15px;
}
.card.product-card.product-card-no-animation {
  &:hover,
  &:focus {
    transform: none;
    cursor: default !important;
  }
}
.card-img-overlay:hover {
  cursor: zoom-in;
}
</style>
