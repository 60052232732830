<template>
  <!-- Second step in report-flow, path: '/anmeldelser/2' -->
  <div class="card report input-reason" role="form">
    <form class="form" @keydown.enter.prevent @submit.prevent>
      <FormTop
        :title="strapiData.title"
        :description="strapiData.description"
      />
      <div class="form-body">
        <div class="label-with-help">
          <label>{{ strapiData.reason }}</label>
          <span class="report-input-required">Obligatorisk</span>
        </div>
        <label class="sub-label">{{ strapiData.reason_sub_label }}</label>
        <div class="input-with-help" style="align-items: baseline !important">
          <textarea
            required
            class="reason"
            v-model="reason"
            :placeholder="strapiData.reason_placeholder"
            @keyup="liveCountDown"
            @input="assertLimitCount"
          />
          <HelpBtn :helpText="strapiData.reason_help" />
        </div>
        <div class="reason-container">
          <div v-if="!reason && showErrorMsg" class="error">
            {{ strapiData.error_submit }}
          </div>
          <div class="textarea-counter">{{ remainCount }}/{{ limitCount }}</div>
          <div style="clear: both"></div>
        </div>
        <FileUpload @updatefiles="updateFiles" :fileList="reasonFiles" />
      </div>
      <div class="form-bottom">
        <div class="row align-items-center">
          <div class="col">
            <ReportBackBtn />
          </div>
          <div class="col-8 justify-content-center" style="max-width: 450px">
            <StepProgress
              :steps="this.$store.state.report.myStepsProgress"
              :current-step="1"
            />
          </div>
          <div class="col">
            <ReportForwardBtn
              :disable="!allowNext"
              @click="handleForward"
              @keyup.enter="handleForward"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HelpBtn from "@/components/widgets/HelpBtn.vue";
import FileUpload from "@/components/FileUpload.vue";
import getStrapiData from "@/composables/getStrapiDataSingle";
import ReportBackBtn from "@/components/widgets/ReportBackBtn.vue";
import ReportForwardBtn from "@/components/widgets/ReportForwardBtn.vue";
import StepProgress from "@/components/input-cards/StepProgress.vue";
import FormTop from "@/components/input-cards/FormTop.vue";

export default {
  components: {
    HelpBtn,
    FileUpload,
    ReportBackBtn,
    ReportForwardBtn,
    StepProgress,
    FormTop,
  },
  data() {
    return {
      limitCount: 1000,
      remainCount: 1000,
      showErrorMsg: false,
      formHeight: null,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("input-reason");
    load();
    return { strapiData, error };
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    liveCountDown() {
      this.remainCount = this.limitCount - this.reason.length;
      this.generateErr = this.remainCount < 0;
    },
    assertLimitCount() {
      if (this.reason.length >= this.limitCount) {
        this.reason = this.reason.slice(0, this.limitCount);
      }
    },
    toggleErrorMsg() {
      this.showErrorMsg = true;
    },
    updateFiles(event) {
      console.log("Files updated:", event);
      this.reasonFiles = event;
    },
    handleForward() {
      if (!this.reason) {
        this.toggleErrorMsg();
      }
    },
  },
  computed: {
    allowNext() {
      if (this.reason) {
        return true;
      } else {
        return false;
      }
    },
    reason: {
      get() {
        return this.$store.state.report.reasonForReporting;
      },
      set(value) {
        this.$store.commit("report/setReason", { reasonForReporting: value });
      },
    },
    reasonFiles: {
      get() {
        return this.$store.state.report.reasonFiles;
      },
      set(value) {
        this.$store.commit("report/setReasonFiles", { reasonFiles: value });
      },
    },
  },
};
</script>

<style>
.input-reason .error {
  width: 60%;
  float: left;
}
.input-reason .reason-container {
  width: 100%;
}
.input-reason .textarea-counter {
  color: grey;
  font-size: 0.8em;
  text-align: right;
  margin-top: 5px;
  float: right;
  width: 30%;
}
</style>
