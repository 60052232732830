<template>
  <div
    class="modal"
    id="errorModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="errorModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="errorModalLabel">
            {{ strapiData.title }}
          </h5>
        </div>
        <div class="modal-body">
          <p>{{ strapiData.description_01 }}</p>
          <p>{{ strapiData.description_02 }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ strapiData.button_cancel }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="handleSubmit"
            @keyup.enter="handleSubmit"
          >
            {{ strapiData.button_try_again }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  setup() {
    const { strapiData, error, load } = getStrapiData("error-modal");
    load();
    return { strapiData, error };
  },
  methods: {
    handleSubmit() {
      this.$parent.handleSubmit();
    },
  },
};
</script>

<style>
.report .modal {
  width: 100%;
}
.report .modal-content {
  border-radius: 30px;
  padding: 20px;
}
.report .form-top h2.modal-title {
  color: var(--deepblue);
}
.report h5.modal-title {
  color: var(--deepblue);
}
.report .modal-body p {
  color: var(--deepblue);
}
.report .modal-footer .btn-secondary {
  background: var(--deepblue);
  color: white;
  border-radius: 15px;
}
.report .modal-footer .btn-primary {
  background: white;
  color: var(--deepblue);
  border-color: var(--deepblue);
  border-radius: 15px;
}
</style>
