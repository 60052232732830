<template>
    <SearchFunction
        @searchUpdated="
            updateProducts({ goToFirstPage: true, scrollToTop: false })
            "
        :placeholder="strapiData.search_caption"
        />
          <!-- Situation -->
        <div class="mt-5 border-bottom" role="region" :aria-label="strapiData.guide_title">
          <p :style="{
          'font-family': 'sora-semibold',
          'font-size': '1em',
          'padding-top': '4px',
          }"
          >
          {{ strapiData.guide_title }}
          </p>
          <div class="m-3 row">
            <PageMarker 
            :show=guide
            />
            <router-link
                  class="col"
                  :to="{ name:'InputProducts'}"
                  :aria-label="strapiData.guide_link"
                  >{{ strapiData.guide_link }}</router-link
                >
          </div>
        </div>
        <!-- Lovområder -->
        <div class="mt-3" role="region" :aria-label="strapiData.product_title">
          <p :style="{
          'font-family': 'sora-semibold',
          'font-size': '1em',
          'padding-top': '4px',
          }"
          >
          {{ strapiData.product_title }}
          </p>
          <div class="m-3">
            <!-- upper part -->
            <div v-if="isShowLess">
              <div v-for="(category,index) in productCards"  :key="category.id" role="navigation">
                <div v-if="index <= (strapiData.visible_product_number-1)">
                  <button class="row product-button" @click="goToCategoryPage(category.title)" :aria-label="'Lovområder-' + category.title">
                    <PageMarker 
                      :show="checkCategory(category.title)"
                    />
                    <a class="col link" >{{ category.title }}</a>
                  </button>
                </div>
              </div>
              <div class="mx-3 link bold" @click="isShowLess = !isShowLess">Se alle</div>
            </div> 
            <div v-else> 
              <div v-for="(category) in productCards" :key="category.id" role="navigation">
                <button class="row product-button" @click="goToCategoryPage(category.title)" :aria-label="'Lovområder-' + category.title">
                    <PageMarker 
                      :show="checkCategory(category.title)"
                    />
                    <a class="col link" >{{ category.title }}</a>
                  </button>
              </div>
              <div class="mx-3 link bold" @click="isShowLess = !isShowLess">Se færre</div>
            </div> 
            <router-view></router-view>
          </div>
        </div>
</template>

<script>
import SearchFunction from "@/components/legislation/SearchFunction.vue";
import PageMarker from "@/components/widgets/PageMarker.vue";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import getStrapiData from "@/composables/getStrapiDataSingle";
import normaliseText from "@/composables/normaliseText";
import { useStore } from "vuex";


export default {
  props: {
    guide: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["categoryUpdated"],
  components: {
    SearchFunction,
    PageMarker,
  },
  setup() {
    const store = useStore();

    const { strapiData, error, load } = getStrapiData("left-panel-legislation");

    const {
      strapiData: allProductCards,
      error: errorProductCard,
      load: loadProductCards
    } = getStrapiDataCollection("products", true);

    load();

    if (!store.state.legislation.productCards.length > 0) {
      loadProductCards().then(() => {

        // find UKATEGORISERET
        // let ukategoriseretIndex = -1;
        let ukategoriseretId = 0;
        // allProductCards.value.forEach(function callback(value, index) {
        allProductCards.value.forEach(function callback(value) {
          if(value.name_code == "GPSD_GUIDE") {
            // ukategoriseretIndex = index;
            ukategoriseretId = value.id;
          }
        });
        // allProductCards.value.push(allProductCards.value.splice(ukategoriseretIndex, 1)[0]);
        store.commit("legislation/setProductCards", { productCards: allProductCards.value });
        store.commit("legislation/setTotalNumberProducts", { totalNumberProducts: allProductCards.value.length });
        store.commit("legislation/setNoCategoryId", { id: ukategoriseretId });
      });
    }
    return {errorProductCard, strapiData, error};
  },
  data() {
    return {
      isShowLess: true,
    };
  },
  computed: {
    productCards: {
      get() {
        return this.$store.state.legislation.productCards;
      },
    },
    selectedCategory: {
      get() {
        return this.$store.state.legislation.selectedCategory;
      },
    },
  },
  methods: {
    checkCategory(title) {
      return normaliseText(title) === this.selectedCategory && this.$route.name.includes("LegislationCategory");
    },
    refreshPage() {
      location.reload();
    },
    goToCategoryPage(category) {
      this.$router.push({ name: "LegislationCategory",  params: { categoryId: normaliseText(category) },});
      this.$store.commit("legislation/setSelectedCategory", { selectedCategory: normaliseText(category) });
      this.$store.commit("legislation/setUpdateFlag", { updateFlag: true });
    }
  },
};
</script>

<style>
.link {
  cursor: pointer;
}

.bold {
  font-family: sora-bold;
  color: var(--deepblue);
}

.product-button {
  border: none;
  background-color: white;
  width: 100%;
  text-align: left;
}
</style>