<template>
    <!-- Home page view, path: '/' -->
    <div class="home inherit-link-styling wide-container" role="main" aria-label="description"> 
      <div>
        <img
        :src="require('/src/assets/graphics/produkterdkSIK_Logo_DARK.svg')"
        alt="Sikkerhedsstyrelsen logo"
        style="height: 90px;"
        class="image2 mx-auto pb-5"
        />
      </div> 
      <div class="image-box d-flex flex-column justify-content-between"> 
        <div
        class="bg-image-home"
        :style="{'background-image': 'url(' + getRandomUrl() +')' }"
        v-if="this.strapiDataImage.images"
        >
        <div class="main-img-overlay"></div>
      </div>
        <div class="px-md-5 text-left shadow-1-strong text-white mobile-style">
          <div class="row align-items-baseline pb-lg-5">
            <div class="title-box col-12 col-md-8">
              <h1 class="h1 title">{{ strapiData.title }}</h1>
            </div>
            <div class="col-md-4 display-flex justify-content-end px-4 pb-2" style="display: flex !important;">
              <img
              :src="require('/src/assets/graphics/produkterdkSIK_Logo_NEG.svg')"
              alt="Sikkerhedsstyrelsen logo"
              style="height: 40px;"
              class="image1"
              />
            </div>
          </div>
          <div class="row">
            <div class="description col-md-4">{{ strapiData.description }}</div>
          </div>
        </div>
        <div class="bottom-box">
          Hvad vil du?
        </div>
      
      </div>
    </div> 
  <!-- Show cards on front page linking to the different tools -->
  <ContentInfoCardList />
</template>

<script>
import ContentInfoCardList from "@/components/content/ContentInfoCardList.vue";
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  name: "Home",
  components: { ContentInfoCardList },

  setup() {
    const { strapiData, error, load } = getStrapiData("home-page");

    const { 
      strapiData: strapiDataImage,
      error: errorImage, 
      load: loadHomePageImage
    } = getStrapiData("home-page-image");
    
    loadHomePageImage();
    load();
    let randomSeed = Math.random();
    return { 
      strapiData, 
      error,
      strapiDataImage, 
      errorImage,
      randomSeed,
     };
  },
  methods: {
    getRandomUrl() {
      let url = this.strapiDataImage.images.data;
      let randomIndex = Math.floor(this.randomSeed*url.length); 
      url = url[randomIndex].attributes.formats.large.url;
      return process.env.VUE_APP_STRAPI_API + url.substring(1) ;
    }
  },
};
</script>

<style>
.title-box .title {
  font-family: sora-bold;
}

.title-box .description{
  font-size: small;
  width: 300px;
}


.bottom-box {
  background-color: white;
  color: black;
  width: 31%;
  margin: 0px 25px;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: sora-bold;
  font-size: 1.5em;
}

.bg-image-home {
  position: absolute; 
  bottom: 1px;
  width: 100%;
  z-index: -1; 
  height: 575px;
  /* object-fit: cover; */
  background-size: cover;
  background-position: center;
}

.image-box{
  position: relative;
  height: 525px;
  width: 100%;
  /* z-index: -1; */
}

.image2 {
  display: none;
}

.description {
  font-size: 1.2em;
}


.main-img-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--blackopaque); */
  background-image: linear-gradient(0deg,rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.8) 100%);
  z-index: 2;
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* laptop */
@media (max-width: 1600px) {
  .description {
    font-size: medium;
  }

  .image-box {
    height: 375px;
  }
  .bg-image-home {
    height: 425px;
  }

  .bottom-box {
    width: 30%;
  }
}

/* phone */
@media (max-width: 767.98px) {
  .bg-image-home{
    display: none;
  }
  .title-box .title {
  color: black;
    /* font-family: sora-bold; */
  /* font-size: 1.6em; */
  padding: 0 !important;
  } 

  .description{
  color: black;
  width: 100% !important;
  /* font-size: small; */
  /* width: 300px; */
  }

  .image1 {
  display: none;
  }

  .image2 {
  display: block;
  }

  .bottom-box {
    width: 100% !important;
    padding-top: 40px;
    padding-bottom: 0px;
    margin: 0px;
  }

  .image-box{
    height: fit-content;
  }
  .mobile-style{
    /* text-align: center; */
    padding: 0px 10px !important;
  }
}
</style>
