<template>
  <!-- Main report view (starting view), path: '/anmeldelser' -->
  <div class="report">
    <div v-if="$route.name == 'Report'" role="main">
      <div class="info-page" v-html="strapiData.content"></div>
      <router-link :to="{ name: 'InputProductInfo' }">
        <button class="btn btn-primary" style="margin: 20px 0" >
          Start anmeldelse
        </button>
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  name: "Report",
  components: {},
  setup() {
    const { strapiData, error, load } = getStrapiData("report");
    load();
    return { strapiData, error };
  },
};
</script>

<style>
/* Definitions of styles applying to the full report-flow */
/* Font-sizes */
.report select,
.report option,
.report .added-choices,
.report .added-category-choice,
.report input,
.report textarea,
.report label.sub-label,
.report .form-body p,
.report span {
  font-size: 0.9em !important;
}
.report label,
.report label.your-choices {
  font-size: 1.15em !important;
  font-family: sora-semibold;
}
/* End of font-sizes */

.report .report-input-required {
  color: var(--deepblue);
  margin-left: 10px;
}
.report .input-no-label {
  margin-top: 10px;
}
.report span.red {
  color: red;
}
.report .terms {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.report .label-with-help {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
}
.report .form-bottom {
  padding: 20px 0 0 0;
}
.report .form .added-choices {
  padding: 10px 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.report .form .remove-choices {
  margin: 10px 0 0 0;
  background-color: var(--lightblue);
}
.report label {
  display: block;
  margin: 10px 0 10px;
}
.report label.sub-label {
  margin-top: 0px;
  font-family: sora-light;
}
.report label.your-choices {
  margin: 0;
}
.report input,
select,
textarea {
  display: block;
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;
  border: none;
}
.report input,
textarea {
  background-color: transparent;
}
.report select {
  background-color: var(--lightblue);
}
.report .input-with-help {
  display: flex;
  align-items: center;
  background-color: var(--lightblue);
}
.report textarea.reason {
  height: 300px;
  width: 100%;
  resize: none;
}
.report select.id-number-type {
  margin: 0px 0px 10px;
}
.report input[type="radio"] {
  zoom: 1.3;
  width: 16px;
  margin: 0 6px 0 0;
  position: relative;
  top: 6px;
}
.report .error {
  color: red;
  margin-top: 5px;
  font-family: sora-semibold;
  font-size: 0.8em;
}
.report .remove-choices .btn-close {
  margin: 0 14px 0 0;
}
.form-top-img-container {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.form-top-img-container .background-img-form-top {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 767.98px) {
  .report .form-body {
    padding: 0 15px 0 15px;
  }
  .report .form-bottom {
    padding: 20px 15px 20px 15px;
  }
  .report.card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  /* Only if route includes report */
  #nav.hide-during-report {
    display: none;
  }
  #footer.hide-during-report {
    display: none;
  }
  #breadcrumbs.hide-during-report {
    display: none;
  }
  .main-img-container .bg-img-main.hide-during-report {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .report select,
  .report option,
  .report .added-choices,
  .report .added-category-choice,
  .report input,
  .report textarea,
  .report label.sub-label,
  .report .form-body p,
  .report span {
    font-size: 1em !important;
  }
  .report label,
  .report label.your-choices {
    font-size: 1.25em !important;
  }
  .report .error {
    font-size: 0.9em;
  }
}
</style>
