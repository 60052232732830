<template>
  <div class="legislation">
    <div v-if="$route.name == 'Legislation'" role="main">
      <div class="row g-4">

        <!-- Left panel start -->
        <div
        class="col-md-5 left-panel-full-page search-filter"
        style="padding-right: 10% !important"
        >
        <LeftPanel/>
      </div>
      <!-- Left panel end -->
      <!-- Right panel -->
      <div class="col-md-7 right-panel-full-page">
        
        <div class="info-page" v-html="strapiData.title_dev"></div>
        <div style="background-color: var(--lightblue); padding: 15px;">
          <div class="info-page" v-html="strapiData.guide_intro_dev"></div>
          <router-link :to="{ name: 'InputProducts' }">
            <button class="btn btn-primary" style="margin: 20px 0">
              {{ strapiData.btn_text_start_guide_dev }}
            </button>
          </router-link>
        </div>
        <div class="info-page" v-html="strapiData.legislation_content_dev"></div>
      </div>
      <!-- Right panel end  -->
    </div>
    </div>
    <router-view></router-view>
  </div>
  

</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import LeftPanel from "@/components/legislation/LeftPanel.vue";


export default {
  name: "Legislation",
  components: { 
    LeftPanel,
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("legislation");
    load();
    return { strapiData, error };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
  },
};
</script>

<style>
.legislation .table ul {
  list-style: unset;
  font-size: 10pt;
}
.legislation .table ul li::before {
  display: none;
}
.legislation .table-responsive {
  background-color: var(--lightgrey) !important;
}
.table-container {
  padding: 10px 15px 10px 15px;
  background-color: var(--lightgrey);
}
</style>
