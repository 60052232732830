<template>
  <div class="aime info-page" v-html="strapiData.content" role="main"></div>
  <FactBox
    :title="strapiData.quote_box_title"
    :body="strapiData.quote_box_body"
    role="complementary"
    :aria-label="strapiData.quote_box_title"
  />
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import FactBox from "@/components/info/FactBox.vue";

export default {
  name: "Aime",
  components: {
    FactBox,
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("aime");
    load();
    return { strapiData, error };
  },
};
</script>
