<template>
  <div class="arrow-title-container d-inline-flex">
    <!-- <div class="arrow-title-img-container">
      <img
        v-if="!altColors"
        :src="require('/src/assets/graphics/pil.svg')"
        alt="pil.svg"
        :style="{ height: pixelSize }"
      />
      <img
        v-if="altColors"
        :src="require('/src/assets/graphics/pil_alt_colors.svg')"
        alt="pil.svg"
        :style="{ height: pixelSize }"
      />
    </div> -->
    <p
      :style="{
        'font-family': 'sora-semibold',
        'font-size': fontSize,
        'padding-top': paddingTop,
      }"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: " ",
    },
    pixelSize: {
      type: String,
      default: "30px",
    },
    paddingTop: {
      type: String,
      default: "4px",
    },
    fontSize: {
      type: String,
      default: "1em",
    },
    altColors: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
