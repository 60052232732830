// Contains async/await fetch to get a single product
// from the unique product id (defined by route)
import { ref } from "vue";

const getSingleProduct = (id) => {
  // Make reactive in template when used in setup()
  const productCard = ref(null);
  const errorSingleProduct = ref(null);

  const loadSingleProduct = async () => {
    try {
      let data = await fetch(
        // Get API URL dependent on environment variable (test or prod)
        process.env.VUE_APP_PRODUCTS_API + "product/" + id
      );
      if (!data.ok) {
        throw Error("Product data not available");
      }
      productCard.value = await data.json();
    } catch (err) {
      errorSingleProduct.value = err.message;
      console.log(errorSingleProduct.value);
    }
  };
  // Returns the ref'ed data and error as well as the load() function
  return { productCard, errorSingleProduct, loadSingleProduct };
};

export default getSingleProduct;
