<template>
  <div class="product-card-list my-5" v-if="!loading">
    <div class="row">
      <div
        v-for="(productCard, index) in productCards"
        :key="productCard.id"
        :class="{'border-top': notFirst(index)}"
      >
          <ProductCard 
          :productCard="productCard"
          :holder="selectedId"
           />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/legislation/ProductCard.vue";

export default {
  props: ["productCards", "loading"],
  components: { ProductCard },
  data() {
    return {
      isWindowSmall: false,
      selectedId:[],
    };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    notFirst(index) {
      return index;
    },
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 1200;
    },
  },
};
</script>

<style>

</style>