// Contains async/await fetch to data in Strapi (our CMS)
import { ref } from "vue";
import normaliseData from "./normaliseData";

const getStrapiDataCollection = (path, sort = false) => {
  // Make reactive in template when used in setup()
  const strapiData = ref([]);
  const error = ref(null);
  const headers = {'Content-Type': 'application/json'};
  const sortquery = sort ? "&sort=rank:asc" : "";
  const load = async () => {
    try {
      const data = await fetch(process.env.VUE_APP_STRAPI_API + 'v1/'+ path + '?populate=*' + sortquery , {
        method: 'GET',
        headers: headers,
      }).then(checkStatus)
        .then(parseJSON);

      strapiData.value = normaliseData(data);

    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };
  // Returns the ref'ed data and error as well as the load() function
  return { strapiData, error, load };
};

function parseJSON (resp) {
  return (resp.json ? resp.json() : resp);
};

function checkStatus (resp) {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return this.parseJSON(resp).then((resp) => {
    throw resp;
  });
};


export default getStrapiDataCollection;
