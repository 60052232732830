<template>
  <!-- page that display search result, path: '/regler/search:searchTerm' -->
    <div class="row g-4">
        <!-- Left panel start -->
        <div class="col-md-5 left-panel-full-page search-filter" style="padding-right: 10% !important"
            v-if="!isWindowSmall">
            <LeftPanel />
        </div>
        <!-- Left panel end -->
        <!-- Right panel -->
        <div role="main" class="col-md-7 right-panel-full-page">
            <h1>Søgeresultater for "{{ searchTerm }}"</h1>
            <p>Viser {{ filteredList.length }} resultater</p>
            <div v-for="(item, index) in filteredList"
              :key="index"
              :class="{'border-top': notFirst(index)}"
              role="navigation"
            >
              <router-link
                :to="{
                  name: 'LegislationCategory',
                  params: { categoryId: item.category },
                  query: {role: item.role.toLowerCase() },
                }"
                :aria-label="item.displayCategory + ' ' + item.role + ' ' + item.text"
                :alt="item.category"
              >
          <SearchCard :searchCard="item" />
        </router-link>
      </div>
        </div>
    </div>
</template>

<script>

import LeftPanel from "@/components/legislation/LeftPanel.vue";
import SearchCard from "@/components/legislation/SearchCard.vue";
import normaliseText from "@/composables/normaliseText";
import normaliseData from "@/composables/normaliseData";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import getStrapiData from "@/composables/getStrapiDataSingle";
import { useStore } from "vuex";
import { ref } from "vue";

async function getGuideBlocks() {

const { 
  strapiData: guidelineBlocks,
  error: errorGuideBlocks,
  load: loadGuideBlocks 
} = getStrapiDataCollection("guideline-blocks");

let guideBlockDict = ref({});

await loadGuideBlocks().then( () => {
  if (errorGuideBlocks.value) {
    console.log(errorGuideBlocks.value);
  }
  guidelineBlocks.value.forEach(guideBlock => {
    guideBlockDict.value[guideBlock.id] = guideBlock;
  });
});
return { guideBlockDict };

}

export default {
  name: "LegislationCategory",
  components: { 
    LeftPanel,
    SearchCard,
  },
  setup() {
    const store = useStore();

    const { 
      strapiData, 
      error, 
      load
    } = getStrapiData("legislation-product");
    
    load();
    
    if (Object.keys(store.state.legislation.guidelineBlocksDict).length === 0) {
        let guideBlockDict = ref({});
  
        getGuideBlocks().then((data) => {
          guideBlockDict = data.guideBlockDict.value;
          store.commit("legislation/setGuidelineBlocks", {
            guidelineBlocksDict: guideBlockDict,
          });
        });
      }

    const { 
      strapiData: accordions, 
      error: errorAccordions, 
      load: loadAccordions 
    } = getStrapiDataCollection("guideline-accordions");

    loadAccordions();
    
    const { 
      strapiData: roles, 
      error: errorRoles, 
      load: loadRoles 
    } = getStrapiDataCollection("situation-roles");

    loadRoles();
    
    const {
      strapiData: situationCards,
      error: errorSituationCards,
      load: loadSituationCards
    } = getStrapiDataCollection("situations", true);
    
    var mainSituationCards = [];
    var extraSituationCards = [];
    if (!store.state.legislation.mainSituationCards.length > 0) {

    loadSituationCards().then(() => {
      situationCards.value.forEach(element => {
        if (element.extra_situation) {
          extraSituationCards.push(element);
        } else {
          mainSituationCards.push(element);
        }
      });
      store.commit("legislation/setMainSituationCards", {mainSituationCards: mainSituationCards});
      store.commit("legislation/setExtraSituationCards", {extraSituationCards: extraSituationCards});
    });
  } else {
    mainSituationCards = store.state.legislation.mainSituationCards;
    extraSituationCards = store.state.legislation.extraSituationCards;
  }

    return { strapiData, error, accordions, errorAccordions, roles, errorRoles, errorSituationCards };
  },
  beforeMount() {
    this.getCards();
  },
  data() {
    return {
      resultCards: [],
    };
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.legislation.searchTerm;
      }
    },
    roleDict: {
      get(){
        return this.$store.state.legislation.roleDict;
      },
    },
    guidelineBlocks: {
      get() {
        return this.$store.state.legislation.guidelineBlocksDict;
      },
    },
    productCards: {
      get() {
        return this.$store.state.legislation.productCards;
      },
    },
    mainSituation: {
      get(){
        return this.$store.state.legislation.mainSituationCards;
      },
    },
    extraSituation: {
      get(){
        return this.$store.state.legislation.extraSituationCards;
      },
    },
    allCategoryCard: {
      get() {
        return this.$store.state.legislation.productCards;
      },
    },
    // search within list of options
    filteredList() {
      // prepare data if not yet exist
      if(JSON.stringify(this.roleDict) === '{}'){
        this.computeData();
        this.getCards();
      } 
      
      var lowerCasedSearch = this.searchTerm.toLowerCase();
      return this.resultCards.filter((el) => {
        if(lowerCasedSearch === '') return el;
        else { return el.category.includes(lowerCasedSearch); }
      });
    }
  },
  methods: {
    someText(id) {
      let text = this.guidelineBlocks[id];
      if(text === undefined ) return "klik her for at læse mere"
      return text.title;
    },
    notFirst(index) {
      return index;
    },
    getCards() {
      
      let list = [];
      for (const [category, roles] of Object.entries(this.roleDict)) {
        let display = this.getActualCategory(category);
        for (const [roletitle, value] of Object.entries(roles)) {
          let guideId = value["guideline_block_id"].flat();
            let result = {};
            result["category"] = category;
            result["displayCategory"] = display;
            result["role"] = roletitle;
            result["text"] = this.someText(guideId);
            list.push(result);
        }
      }
      this.resultCards = list;
    },
    getActualCategory(text) {
      return this.productCards.find(product => normaliseText(product.title) === text).title;
    },
    computeData() {

  // blocks per role for all products
  let roleBlocks = {};
  let roleId = {};
  let roleDescription = {};
  if(this.roles.length > 0) {
    this.roles.forEach(element => {
      let guideBlocks = [];
      
      // main situations
      let situations = this.mainSituation.filter( situation => situation.role.data.id === element.id);
      situations.forEach( element => {
        element.guideline_blocks.data.forEach( guide => {
          if(guideBlocks.indexOf(guide.id) === -1) guideBlocks.push(guide.id);
        })
      });

      // extra situations
      let extrasituations = this.extraSituation.filter( situation => situation.role.data.id === element.id);
      extrasituations.forEach( element => {
        element.guideline_blocks.data.forEach( guide => {
          if(guideBlocks.indexOf(guide.id) === -1) guideBlocks.push(guide.id);
        })
      });

      roleBlocks[element.role] = guideBlocks;
      roleId[element.role] = element.id;
      roleDescription[element.role] = element.description;

    });
  }

  let roleDictionary = {};
  this.allCategoryCard.forEach(product => {
    let allguide = normaliseData(product.guideline_blocks);
    let allguideId = [];
    allguideId.push(allguide.map(el => el.id));
    let roleTab = {};
    for (const [key, value] of Object.entries(roleBlocks)) {
      let filteredArray = allguideId.flat().filter(el => value.includes(el));
      if(filteredArray.length > 0){
        let data = {};
        data["description"] = roleDescription[key];
        data["role_id"] = roleId[key];
        data["guideline_block_id"] = filteredArray;
        data["role"] = key;
        roleTab[key] = data;
      }
    }
    if(roleTab != {}){
      roleDictionary[normaliseText(product.title)] = roleTab;
    }

  });

  console.log("roleDictionary");
  console.log(roleDictionary);
  this.$store.commit("legislation/setRoleDict", {roleDict: roleDictionary});

},
  },
};
</script>

<style></style>