<template>
  <!-- Successful submission of report, path: '/anmeldelser/success' -->
  <div class="card report input-confirmation" role="form">
    <div
      :class="{
        'input-confirmation-img-container': true,
        'mobile-view': isWindowSmall,
      }"
    >
      <img
        :src="require('/src/assets/graphics/bg_slutskærm_mobile.svg')"
        alt="bg_slutskærm_mobile.svg"
        class="background-img"
      />
      <form class="form" @keydown.enter.prevent @submit.prevent>
        <FormTop
          :title="strapiData.title"
          :description="strapiData.description"
          :isConfirmation="true"
        />
        <div class="form-body">
          <div class="d-flex btn-redirect-container justify-content-center">
            <button
              type="button"
              class="btn btn-secondary m-3"
              @click="redirect('InputProductInfo')"
              @keyup.enter="redirect('InputProductInfo')"
            >
              {{ strapiData.button_new_report }}
            </button>
            <button
              type="button"
              class="btn btn-primary m-3"
              @click="redirect('Home')"
              @keyup.enter="redirect('Home')"
              style="border: 1px solid white"
            >
              {{ strapiData.button_back_to_home }}
            </button>
          </div>
          <div
            :class="{
              'container-notes-report': true,
              'mobile-view': isWindowSmall,
              'mt-3': !isWindowSmall,
            }"
          >
            <p>{{ strapiData.label_01 }}</p>
            <p>{{ strapiData.label_02 }}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import FormTop from "@/components/input-cards/FormTop.vue";

export default {
  components: { FormTop },
  data() {
    return {
      isWindowSmall: true,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("input-confirmation");
    load();
    return { strapiData, error };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    redirect(pathName) {
      this.$router.push({ name: pathName });
    },
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
  },
};
</script>

<style>
.input-confirmation .mobile-view {
  padding-top: 150px;
}
.input-confirmation .container-notes-report.mobile-view {
  position: absolute;
  bottom: 0;
}
.input-confirmation .container-notes-report.mobile-view p {
  font-size: 0.75em !important;
}
.input-confirmation .form-body p {
  color: white;
}
.input-confirmation .form-body h2 {
  color: white;
}
.input-confirmation .form-body .btn {
  font-family: sora-semibold;
  font-size: 0.75em;
}
.input-confirmation-img-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100vh;
}
.input-confirmation-img-container .background-img {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  left: 0;
  top: 0;
}
@media only screen and (min-width: 768px) {
  .input-confirmation .form-body p {
    color: black;
  }
  .input-confirmation .form-body .btn {
    font-family: sora-regular;
    font-size: 1em;
  }
  .input-confirmation-img-container .background-img {
    display: none;
  }
  .input-confirmation .btn-redirect-container button {
    margin: 40px 0 40px 0;
  }
}
</style>
