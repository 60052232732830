<template>
  <div
    :class="{
      'px-1': true,
      'filter-panel-container': true,
      'filter-panel-container-mobile-view': isWindowSmall,
    }"
  >
    <div
      :class="{
        row: true,
        'container-sort-type': true,
        'py-3': true,
        'mt-4': !isWindowSmall,
        'border-bottom': true,
      }"
    >
      <ArrowTitle class="mb-3" :title="filterTypeTitle" />
      <div
        class="col-12"
        v-for="(value, key, index) in sortObject(productTypes)"
        :key="key"
      >
        <div class="d-flex form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="complianceFilters"
            :value="String(value.wzIds)"
            @change="$emit('filtersUpdated')"
            :aria-labelledby="'produkttype-' + index"
            :id="'input-produkttype-' + index"
          />
          <label
            class="form-check-label"
            style="padding-left: 10px"
            :id="'produkttype-' + index"
            :for="'input-produkttype-' + index"
            >{{ key }}</label
          >
        </div>
      </div>
    </div>
    <div
      :class="{
        row: true,
        'container-sort-category': true,
        'py-3': true,
        'border-bottom': true,
      }"
    >
      <ArrowTitle class="mb-3" :title="filterCategoryTitle" />
      <div
        class="col-12"
        v-for="(value, key, index) in sortObject(productCategories)"
        :key="key"
      >
        <div class="d-flex form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="categoryFilters"
            :value="String(value.wzIds)"
            @change="$emit('filtersUpdated')"
            :aria-labelledby="'produktkategori-' + index"
            :id="'input-produktkategori-' + index"
          />
          <label
            class="form-check-label"
            style="margin-left: 10px"
            :id="'produktkategori-' + index"
            :for="'input-produktkategori-' + index"
            >{{ key }}</label
          >
        </div>
      </div>
    </div>
    <div
      :class="{
        row: true,
        'container-sort-authority': true,
        'py-3': true,
        'border-bottom': !isWindowSmall,
      }"
    >
      <ArrowTitle class="mb-3" :title="filterAuthorityTitle" />
      <div
        class="col-12"
        v-for="authority in sortArray(authorityNames)"
        :key="authority.id"
      >
        <div class="d-flex form-check">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="authorityFilters"
            :value="Number(authority.id)"
            @change="$emit('filtersUpdated')"
            :aria-labelledby="'myndighed-' + authority.id"
            :id="'input-myndighed-' + authority.id"
          />
          <label
            class="form-check-label"
            style="margin-left: 10px"
            :id="'myndighed-' + authority.id"
            :for="'input-myndighed-' + authority.id"
            >{{ authority.name }}</label
          >
        </div>
      </div>
    </div>
    <button
      v-if="!isWindowSmall"
      @click="clearFilters"
      @keyup.enter="clearFilters"
      class="btn btn-secondary my-4"
    >
      {{ clearFiltersTitle }}
    </button>
  </div>
</template>

<script>
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";

export default {
  props: [
    "filterTypeTitle",
    "productTypes",
    "filterCategoryTitle",
    "productCategories",
    "filterAuthorityTitle",
    "authorityNames",
    "isWindowSmall",
    "clearFiltersTitle",
  ],
  emits: ["filtersUpdated"],
  components: { ArrowTitle },
  methods: {
    clearFilters() {
      if (
        this.$store.state.products.filtersInput.complianceFilters.length ||
        this.$store.state.products.filtersInput.categoryFilters.length ||
        this.$store.state.products.filtersInput.authorityFilters.length
      ) {
        this.$store.commit("products/setComplianceFilters", {
          complianceFilters: [],
        });
        this.$store.commit("products/setCategoryFilters", {
          categoryFilters: [],
        });
        this.$store.commit("products/setAuthorityFilters", {
          authorityFilters: [],
        });
        this.$emit("filtersUpdated");
      }
    },
    sortObject(object) {
      let sorted = {};
      Object.keys(object)
        .sort()
        .forEach((key) => {
          sorted[key] = object[key];
        });
      return sorted;
    },
    sortArray(array) {
      return array.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  computed: {
    complianceFilters: {
      get() {
        return this.$store.state.products.filtersInput.complianceFilters;
      },
      set(value) {
        this.$store.commit("products/setComplianceFilters", {
          complianceFilters: value,
        });
      },
    },
    categoryFilters: {
      get() {
        return this.$store.state.products.filtersInput.categoryFilters;
      },
      set(value) {
        this.$store.commit("products/setCategoryFilters", {
          categoryFilters: value,
        });
      },
    },
    authorityFilters: {
      get() {
        return this.$store.state.products.filtersInput.authorityFilters;
      },
      set(value) {
        this.$store.commit("products/setAuthorityFilters", {
          authorityFilters: value,
        });
      },
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 2px solid var(--lightgrey) !important;
}
.border-bottom {
  border-bottom: 2px solid var(--lightgrey) !important;
}
.filter-panel-container-mobile-view {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: visible;
}
</style>
