<template>
  <!-- Second step in Legislation-guide, path: '/regler/guide/2' -->
     <div class="legislation input-situation row g-4">
   <!-- Left panel start -->
   <div
        class="col-md-5 left-panel-full-page search-filter"
        style="padding-right: 10% !important"
        >
        <LeftPanel
        :guide="true"
        />
      </div>
      <!-- Left panel end -->
      <!-- Right panel -->
      <div class="col-md-7 right-panel-full-page" role="main">
        <div>
          <h1 class="form-top-title">{{ strapiData.shared_guide_title }}</h1>
          <p class="form-top-description">{{ strapiData.shared_guide_subtitle }}</p>

        </div>
        <div class="card report input-product-info" role="form">
          <form class="form" @keydown.enter.prevent @submit.prevent>
            <div class="form-top">
              <div class="row">
                <div class="col">
                </div>
                <div class="col-8 justify-content-center" style="max-width: 450px; display: flex">
                  <ProgressTracker
                    :activeStep="2"
                    :labels="labels"
                    :stepRoutes="stepRoutes"
                    />
                </div>
                <div class="col inline-flex" >
                  <LegislationCloseBtn/>
                </div>
              </div>
            </div>
          
            <FormTop
              :title="strapiData.title"
              :description="strapiData.description"
              :hideCloseBtn=true
            />
            <div class="form-body">

              <!-- TODO: change this to be a component that can be paginated -->
              <div v-for="(situation, index) in situationCardsList"
                class="terms situation-card"
                :key="situation.id"
                :class="{'border-top': notFirst(index)}"
              >
                <input
                  type="radio"
                  v-model="selectedSituation"
                  :value="situation.id"
                  style="margin-right: 15px;"
                  v-bind:id="situation.id"
                />
                <label :for="situation.id" style="font-size: 18px !important;" class="clickable">{{ situation.title }}</label>
              </div>
              <div class="error" v-if="showErrorMsg">
                {{ strapiData.no_situation_error }}
              </div>
                <ExtraQuestionCard
                  v-if="getExtraSituation()"
                  :question="extraSituationQuestion"
                  :answers="extraSituation"
                />
            </div>

            <div class="form-bottom">
              <div class="d-flex justify-content-between">
                <LegislationBackBtn />
                <LegislationForwardBtn
                  :disable="!allowNext"
                  @click="handleForward"
                  @keyup.enter="handleForward"
                />
              </div>
            </div>
          </form>
          <BackToTopBtn :btnText="strapiData.back_to_top_btn" />
        </div>
      </div>
      <!-- Right panel end -->
     </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import LegislationBackBtn from "@/components/widgets/LegislationBackBtn.vue";
import LegislationForwardBtn from "@/components/widgets/LegislationForwardBtn.vue";
import LegislationCloseBtn from "@/components/widgets/LegislationCloseBtn.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import ProgressTracker from "@/components/widgets/ProgressTracker.vue";
import ExtraQuestionCard from "@/components/legislation/ExtraQuestionCard.vue";
import BackToTopBtn from "@/components/widgets/BackToTopBtn.vue";
import { useStore } from "vuex";
import normaliseData from "@/composables/normaliseData";
import LeftPanel from "@/components/legislation/LeftPanel.vue";
import { ref } from "vue";


async function getGuideBlocks() {

const { 
  strapiData: guidelineBlocks,
  error: errorGuideBlocks,
  load: loadGuideBlocks 
} = getStrapiDataCollection("guideline-blocks");

let guideBlockDict = ref({});

await loadGuideBlocks().then( () => {
  if (errorGuideBlocks.value) {
    console.log(errorGuideBlocks.value);
  }
  guidelineBlocks.value.forEach(guideBlock => {
    guideBlockDict.value[guideBlock.id] = guideBlock;
  });
});
return { guideBlockDict };

}

export default {
  components: {
    LegislationBackBtn,
    LegislationForwardBtn,
    ProgressTracker,
    LegislationCloseBtn,
    FormTop,
    ExtraQuestionCard,
    BackToTopBtn,
    LeftPanel,
  },
  data() {
    return {
      showErrorMsg: false,
      current: 0,
      listRefs: [],
      selectedProductCategory: this.$store.state.legislation.selectedProducts,
      stepRoutes: this.$store.state.legislation.stepsProgress,
      labels: this.$store.state.legislation.stepLabels,
      extraSituation:{},
      extraSituationQuestion: "",
    };
  },
  setup() {
    const store = useStore();
    const { strapiData, error, load } = getStrapiData("input-situation");
    const {
      strapiData: situationCards,
      error: errorSituationCards,
      load: loadSituationCards
    } = getStrapiDataCollection("situations", true);
    
    var mainSituationCards = [];
    var extraSituationCards = [];
    loadSituationCards().then(() => {
      situationCards.value.forEach(element => {
        if (element.extra_situation) {
          extraSituationCards.push(element);
        } else {
          mainSituationCards.push(element);
        }
      });
      store.commit("legislation/setMainSituationCards", {mainSituationCards: mainSituationCards});
      store.commit("legislation/setExtraSituationCards", {extraSituationCards: extraSituationCards});
    });
    load();

    if (Object.keys(store.state.legislation.guidelineBlocksDict).length === 0) {
      let guideBlockDict = ref({});

      getGuideBlocks().then((data) => {
        guideBlockDict = data.guideBlockDict.value;
        store.commit("legislation/setGuidelineBlocks", {
          guidelineBlocksDict: guideBlockDict,
        });
      });
    }

    return { 
      strapiData, 
      error,
      errorSituationCards,
    };
  },
  beforeUpdate() {
    this.listRefs = [];
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  unmounted() {
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    handleForward() {
      if(this.allowNext) {
        this.$router.push({ name: "InputGuidelines" });
      } else {
        this.showErrorMsg = true;
      }
    },
    notFirst(index) {
      return index;
    },
    getExtraSituation() {
      this.extraSituationCards = this.$store.state.legislation.extraSituationCards;
      var foundFlag = false;
      if (this.extraSituationCards.length > 0) {
        this.extraSituationCards.forEach(element => {
          var extraSituationTrigger = normaliseData(element.extra_situation_trigger);
          if (extraSituationTrigger != null) {
            var index = this.selectedProductCategory.indexOf(extraSituationTrigger.id);
            if (index > -1) { 
              this.extraSituation[element.id] = element;
              this.extraSituationQuestion = extraSituationTrigger.question_for_extra_situation;
              foundFlag = true;
            }
          }
        });
      }

      if(!foundFlag) this.resetExtraSituation();

      return foundFlag;
    },
    resetExtraSituation() {
      this.$store.commit("legislation/setSelectedExtraSituation", {
          id: 0, card: {}
        });
    },
  },
  computed: {
    allowNext() {
      if(this.selectedSituation != 0) return true;
      return false;
    },
    selectedSituation: {
      get() {
        return this.$store.state.legislation.selectedMainSituation.id;
      },
      set(value) {
        console.log(value);
        var currentCard = {};
        this.situationCardsList.forEach(situationCard => {
            if(situationCard.id == value) {
              currentCard = situationCard;
            } 
        });
        this.$store.commit("legislation/setSelectedSituation", {
          id: value, card: currentCard
        });
      },
    },
    situationCardsList: {
      get() {
        return this.$store.state.legislation.mainSituationCards;
      }
    },
  },
};
</script>

<style>
.situation-card {
  padding: 10px 50px 10px 0px;
  margin-right: 50px;
}

@media only screen and (max-width: 767.98px) {
  .situation-card {
  padding: 10px 0px 10px 0px;
}
}
</style>
