<template>
  <!-- page that display all product regulation, path: '/regler/lovomraader/:categoryid?role=:role' -->
  <div class="legislationCategory">
    <div v-if="$route.name == 'LegislationCategory'" role="main">
      <div class="row g-4">

        <!-- Left panel start -->
        <div class="col-md-5 left-panel-full-page search-filter" style="padding-right: 10% !important"
          v-if="!isWindowSmall">
          <LeftPanel
          />
        </div>
        <!-- Left panel end -->
        <!-- Right panel -->
        <div v-if="selectedCategoryCard" class="col-md-7 right-panel-full-page">
          <div>
            <h1>{{ strapiData.title_before_product }} {{ selectedCategoryCard.title }}</h1>
            <div class="mt-5 mb-5">
              <p> {{ selectedCategoryCard.description }} </p>
            </div>

          </div>
          <div class="limitation-card">
            <h2>{{ strapiData.sub_title }}</h2>
            <div class="m-2 pb-3">
              <p>{{ strapiData.disclaimer }}</p>
            </div>
            <div class="roleBox" style="background-color: white;">
              <h3 class="p-3">{{ strapiData.sub_sub_title }}</h3>
              <div style="padding-right: 35px; padding-left:5px;">
                <div class="role-and-description-box">
                </div>
                <div v-if="computeData()">
                  <div class="row d-flex justify-content-start">
                    <div class="col role-tab m-3 link" v-for="(data, index) in selectedCategoryData" :key="index" style="padding-right: 20px;"
                     @click="select(index)" :class="{'border-select': check(index)}">{{ data.role }}</div>
                  </div>
                  <div v-for="(data, index) in selectedCategoryData" :key="index" 
                  > 
                  <div v-if="check(index)">
                    <div class="m-3">{{ data.description }}</div>
                    <div v-for="(accordion, index) in getAccordions(data.guideline_block_id)" :key="index" style="padding-right: 20px;"
                    :class="{ 'border-top': notFirst(index) }" class="m-2">
                    <GuideAccordion :title="accordion.attributes.title" :description="accordion.attributes.description"
                      :accordionId="accordion.id" />
                    </div>
                  </div>
                </div>
                </div>
                <div v-else>
                  No data
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          Henter data...
        </div>
        <!-- Right panel end  -->
      </div>
    </div>
    <!-- check this -->
    <router-view></router-view>
  </div>
</template>

<script>
// import getStrapiData from "@/composables/getStrapiDataSingle";
import LeftPanel from "@/components/legislation/LeftPanel.vue";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import getStrapiData from "@/composables/getStrapiDataSingle";
import normaliseData from "@/composables/normaliseData";
import normaliseText from "@/composables/normaliseText";
import GuideAccordion from "@/components/legislation/GuideAccordion.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";

async function getGuideBlocks() {

const { 
  strapiData: guidelineBlocks,
  error: errorGuideBlocks,
  load: loadGuideBlocks 
} = getStrapiDataCollection("guideline-blocks");

let guideBlockDict = ref({});

await loadGuideBlocks().then( () => {
  if (errorGuideBlocks.value) {
    console.log(errorGuideBlocks.value);
  }
  guidelineBlocks.value.forEach(guideBlock => {
    guideBlockDict.value[guideBlock.id] = guideBlock;
  });
});
return { guideBlockDict };

}

export default {
  name: "LegislationCategory",
  components: { 
    LeftPanel,
    GuideAccordion,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const { 
      strapiData, 
      error, 
      load
    } = getStrapiData("legislation-product");
    
    load();
    
    if (Object.keys(store.state.legislation.guidelineBlocksDict).length === 0) {
        let guideBlockDict = ref({});
  
        getGuideBlocks().then((data) => {
          guideBlockDict = data.guideBlockDict.value;
          store.commit("legislation/setGuidelineBlocks", {
            guidelineBlocksDict: guideBlockDict,
          });
        });
      }

    const { 
      strapiData: accordions, 
      error: errorAccordions, 
      load: loadAccordions 
    } = getStrapiDataCollection("guideline-accordions");

    loadAccordions();
    
    const { 
      strapiData: roles, 
      error: errorRoles, 
      load: loadRoles 
    } = getStrapiDataCollection("situation-roles", true);

    loadRoles();
    
    const {
      strapiData: situationCards,
      error: errorSituationCards,
      load: loadSituationCards
    } = getStrapiDataCollection("situations", true);
    
    var mainSituationCards = [];
    var extraSituationCards = [];
    if (!store.state.legislation.mainSituationCards.length > 0) {

    loadSituationCards().then(() => {
      situationCards.value.forEach(element => {
        if (element.extra_situation) {
          extraSituationCards.push(element);
        } else {
          mainSituationCards.push(element);
        }
      });
      store.commit("legislation/setMainSituationCards", {mainSituationCards: mainSituationCards});
      store.commit("legislation/setExtraSituationCards", {extraSituationCards: extraSituationCards});
    });
  } else {
    mainSituationCards = store.state.legislation.mainSituationCards;
    extraSituationCards = store.state.legislation.extraSituationCards;
  }

  let productName = route.params.categoryId;
  let roleName = route.query.role;

  const { 
      strapiData: specialRoles,
      error: errorSpecialRoles,
      load: loadSpecialRoles 
    } = getStrapiDataCollection("product-role-pairs");
   
    let specialrolePairDict = [];
    loadSpecialRoles().then(() => {
      // build and save the special role library
      specialRoles.value.forEach(pair => {
          let data = {};
          data["role"] = pair.role_title.data.attributes.role;
          data["productId"] = pair.product_category_guideline.data.id;
          data["productName"] = pair.product_category_guideline.data.attributes.title;
          data["id"] = pair.id;
          specialrolePairDict.push(data);
        });
      
    });

    return { 
      strapiData, 
      error, 
      accordions, 
      errorAccordions, 
      roles, 
      errorRoles, 
      errorSituationCards, 
      productName, 
      roleName,
      errorSpecialRoles,
      specialrolePairDict,
    };
  },
  data() {
    return {
      selectedRole: this.roleName,
    };
  },
  computed: {
    selectedCategoryTitle: {
      get() {
        return this.$store.state.legislation.selectedCategory;
      },
    },
    selectedCategoryCard: {
      get() {
        let allProducts = this.$store.state.legislation.productCards;
        return allProducts.find((element) => normaliseText(element.title) == this.selectedCategoryTitle);
      },
    },
    allCategoryCard: {
      get() {
        return this.$store.state.legislation.productCards;
      },
    },
    mainSituation: {
      get(){
        return this.$store.state.legislation.mainSituationCards;
      },
    },
    extraSituation: {
      get(){
        return this.$store.state.legislation.extraSituationCards;
      },
    },
    roleDict: {
      get(){
        return this.$store.state.legislation.roleDict;
      },
    },
    selectedCategoryData: {
      get() {
        return this.roleDict[this.selectedCategoryTitle];
      }
    },
    guidelineBlocks: {
      get() {
        return this.$store.state.legislation.guidelineBlocksDict;
      },
    },
    updatedFlag: {
      get() {
        return this.$store.state.legislation.updateFlag;
      },
    },
  },
  methods: {
    check(index){
      
      // delete role on new category
      if(this.updatedFlag){
        this.$store.commit("legislation/setUpdateFlag", { updateFlag: false });
        this.selectedRole = '';
      }

      // initial role if none
      if(!this.selectedRole) {
        this.select(index);
      }     
      return index.toLowerCase() === this.selectedRole;  
    
    },
    select(index) {
      let role = index.toLowerCase();
      this.selectedRole = role;
      this.$router.push({ 
        name: "LegislationCategory",  
        params: { categoryId: this.selectedCategoryTitle }, 
        query: {role: `${role}`} });
    },
    notFirst(index) {
        return index;
    },
    computeData() {

      // blocks per role for all products
      let roleBlocks = {};
      let roleId = {};
      let roleDescription = {};
      let specialRoleMapper = [];
      if(this.roles.length > 0) {
        this.roles.forEach(element => {
          let guideBlocks = [];
          
          // main situations
          let situations = this.mainSituation.filter( situation => situation.role.data.id === element.id);
          situations.forEach( element => {
            element.guideline_blocks.data.forEach( guide => {
              if(guideBlocks.indexOf(guide.id) === -1) guideBlocks.push(guide.id);
            })

            // get the special mapping
            if(element.exception_role_naming.data.length != 0){
              let specialRole = normaliseData(element.exception_role_naming);
              specialRole.forEach( pair => {
                let specialRoleMap = {};
                specialRoleMap["special_role_id"] = pair.id;
                specialRoleMap["role"] = element.role.data.attributes.role;
                specialRoleMapper.push(specialRoleMap);
              });
            }
          });

          // extra situations
          let extrasituations = this.extraSituation.filter( situation => situation.role.data.id === element.id);
          extrasituations.forEach( element => {
            element.guideline_blocks.data.forEach( guide => {
              if(guideBlocks.indexOf(guide.id) === -1) guideBlocks.push(guide.id);
            })
          });

          roleBlocks[element.role] = guideBlocks;
          roleId[element.role] = element.id;
          roleDescription[element.role] = element.description;

        });
      }

      let roleDictionary = {};
      this.allCategoryCard.forEach(product => {
        let allguide = normaliseData(product.guideline_blocks);
        let allguideId = [];
        allguideId.push(allguide.map(el => el.id));
        let roleTab = {};
        for (const [key, value] of Object.entries(roleBlocks)) {
          let filteredArray = allguideId.flat().filter(el => value.includes(el));
          if(filteredArray.length > 0){
            let data = {};
            data["description"] = roleDescription[key];
            data["role_id"] = roleId[key];
            data["guideline_block_id"] = filteredArray;
            data["role"] = key;
            roleTab[key] = data;
          }
        }
        if(roleTab != {}){
          roleDictionary[normaliseText(product.title)] = roleTab;
        }

      });

      // handle special role naming
      // complete mapper
      specialRoleMapper.forEach(element => {
        let pair = this.specialrolePairDict.find(el => el.id === element.special_role_id);
        if(pair && pair.role) {
          element.special_role_name = pair.role;
          element.product_name = normaliseText(pair.productName);
        }
      });


      // add extra role dictionary
      specialRoleMapper.forEach(element => {
        let product = roleDictionary[element.product_name];

        if(product && product[element.role]){
          // create new role
          let newRoleName = element.special_role_name;
          let oldRoleData = product[element.role];
          let data = {};
          data["role"] = newRoleName;
          data["role_id"] = roleId[newRoleName];
          data["description"] = roleDescription[newRoleName];
          data["guideline_block_id"] = oldRoleData.guideline_block_id;
          product[newRoleName] = data;
          

          roleDictionary[element.product_name] = product;
        }
      });

      // delete old role dictionary
      specialRoleMapper.forEach(element => {
        let product = roleDictionary[element.product_name];

        // delete old role
        if(product && product[element.role]){
          delete product[element.role];
        }
      });


      console.log("roleDictionary");
      console.log(roleDictionary);
      this.$store.commit("legislation/setRoleDict", {roleDict: roleDictionary});

      return true;
    },
    // assumes one guide block
    getAccordions(guideIdArray){
      let allAccordionsId = [];
      guideIdArray.forEach(id => {
        allAccordionsId = this.guidelineBlocks[id].guideline_accordions;
      });
      return allAccordionsId.data;
    },
  },
};
</script>

<style>
.legislation .table ul {
  list-style: unset;
  font-size: 10pt;
}
.legislation .table ul li::before {
  display: none;
}
.legislation .table-responsive {
  background-color: var(--lightgrey) !important;
}
.table-container {
  padding: 10px 15px 10px 15px;
  background-color: var(--lightgrey);
}
.role-tab {
  font-family: sora-bold;
  color: var(--deepblue);
}

.border-select {
  border-bottom: 3px var(--bs-border-style) black!important;
}

.role-and-description-box {
  overflow-y: auto;
}

.link {
  cursor: pointer;
}
</style>
