import * as passwordGenerator from "generate-password";
import * as CryptoJS from "crypto-js";
import readFile from "@/composables/readFile"
import JSEncrypt from "jsencrypt";

/**
 * To be run before using the main functions of this library: encryptFileAES and
 * encryptElementAES. init returns a "keyObject" - one argument to be used 
 * when calling the aforementioned functions. 
 * @param {String} publicKey An RSA public key.
 * @returns A keyObject, to be used as argument when using the exported functions in the library.
 */
export function init(publicKey) {
    const AESKey = generateAESKey();
    const encryptedAESKey = encryptRSA(AESKey, publicKey)
    return {
        AESKey : AESKey,
        encryptedAESKey : encryptedAESKey
    }
}

export async function encryptFileAES (file, keyObj) {
    if (!file || !keyObj.AESKey) {
        console.log("Argument is missing");
        return file
    }
    let stringFileContent = await readFile(file);    
    let encryptedContent = encryptFileUtil(stringFileContent, keyObj);
    let encryptedFile = new File([encryptedContent], file.name, {
        type: file.type,
        lastModified: file.lastModified,
    });
    return encryptedFile
}

/**
 * Polymorphic encryption function. In the case of objects,
 * it encrypts only the values, not the keys.
 * @param {*} element Polymorphic argument: either string, object, array, number or boolean.
 * @param {*} keyObj A key object made with init().
 * @returns The encrypted object.
 */
export function encryptElementAES (element, keyObj) {
    if (element == null) {
        return element
    }

    if (typeof element === "string") {
        if (!element || element.length == 0) {
            return element
        }
        return encryptValue(element, keyObj);
    }

    if (typeof element === "number" || typeof element === "boolean") {
        return encryptValue(String(element), keyObj);
    }
    
    if (typeof element === "object") {
        if (Array.isArray(element)) {
            let encryptedList = element.map(e => encryptElementAES(e, keyObj))
            return ("[" + encryptedList + "]")
        }

        let encryptedObject = {}
        for (const [objectKey, objValue] of Object.entries(element)) {
            encryptedObject[objectKey] = encryptElementAES(objValue, keyObj)
        }
        return JSON.stringify(encryptedObject)
    }
}

function generateAESKey() {
    const pass = passwordGenerator.generate({
            //This is 32 bytes so the key length is 256 bits
            length: 32,
            numbers: true,
            symbols: "+/",
          });

    const iv = passwordGenerator.generate({
        length:16,
        numbers: true,
        symbols: "+/",
      });

    return iv + pass;
    }

function encryptRSA (string, publicKey) {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    let encryptedString = encrypt.encrypt(string);
    return encryptedString
}

function encryptValue(string, keyObj) {
    const ivSize = 16;
    const iv = CryptoJS.enc.Utf8.parse(keyObj.AESKey.slice(0,ivSize));
    const key = CryptoJS.enc.Utf8.parse(keyObj.AESKey.slice(ivSize));

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), key,
        {
            // It means 256 bits
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return encrypted.toString();
}

/**
 * @returns The encrypted file content as Array Buffer. 
 */
function encryptFileUtil(stringFileContent, keyObj) {
    let encryptedContent = encryptValue(stringFileContent, keyObj)
    return base64ToArrayBuffer(encryptedContent)
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}