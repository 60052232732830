<template>
  <div :class="{ 'form-top-img-container': !isConfirmation }">

    <div class="form-top">
      <!-- Form top with close button -->
      <div class="d-flex justify-content-between">
        <h2 class="form-top-title">{{ title }}</h2>
        <button 
          :class="{
            btn: true,
            'btn-close': true,
            'btn-close-white': isWindowSmall,
          }"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          type="button"
          aria-label="Luk anmeldelse"
          v-if="!hideCloseBtn"
        ></button>
      </div>
      <p class="form-top-description">{{ description }}</p>

      <!-- Modal for closing report -->
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        v-if="!hideCloseBtn"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="staticBackdropLabel">
                {{ strapiData.modal_title }}
              </h2>
            </div>
            <div class="modal-body">
              <p>{{ strapiData.modal_description }}</p>
            </div>
            <div class="modal-footer d-flex justify-content-around">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                @click="closeReport"
                @keyup.enter="closeReport"
              >
                {{ strapiData.modal_btn_close }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {{ strapiData.modal_btn_continue }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  props: {
    title: {
      type: String,
      default: "Titel",
    },
    description: {
      type: String,
      default: "Beskrivelse",
    },
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWindowSmall: false,
    };
  },
  setup() {
    const { strapiData, error, load } = getStrapiData("close-report-modal");
    load();
    return { strapiData, error };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    closeReport() {
      this.$store.dispatch("report/resetState");
      this.$router.push({ name: "Home" });
    },
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
  },
};
</script>

<style lan="scss">
.form-top-description {
  font-family: sora-regular;
  font-size: 1.25em;
  color: black;
}
.form-top-title {
  font-size: 2.35em;
  font-family: sora-bold;
  color: black;
}

@media only screen and (max-width: 767.98px) {
  .form-top-description {
    font-size: 0.85em;
  }
  .form-top-title {
    font-size: 1.65em;
  }
  .report .form-top {
    padding: 30px 15px 10px 15px;
  }
}
</style>
