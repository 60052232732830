<template>
  <div
    class="modal"
    id="loadingModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="loadingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex justify-content-center" style="margin-bottom: 20px">
          <h5 class="modal-title" id="loadingModalLabel">
            Indsender anmeldelse...
          </h5>
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="spinner-border"
            role="status"
            style="color: var(--deepblue)"
          >
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
