<template>
  <div class="accordion" id="accordionMoreInfoProducts">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne" style="margin: 0 !important">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <div style="margin-right: 15px; font-family: sora-regular">
            {{ buttonText }}
          </div>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionMoreInfoProducts"
      >
        <div
          class="accordion-body inherit-link-styling"
          v-html="bodyText"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.accordion-item {
  border: 1px solid var(--mediumblueopaque) !important;
  background: transparent !important;
}
.accordion-button {
  background: transparent !important;
  color: var(--deepblue) !important;
  &:not(.collapsed) {
    background: transparent !important;
    color: var(--deepblue) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    font-weight: bold;
  }
  &::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Lag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 62 70' style='enable-background:new 0 0 62 70;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%236EBFFF;stroke-width:14;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%230038F0;stroke-width:14;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='61.9' y1='35' x2='-0.3' y2='35'/%3E%3Cpath class='st0' d='M61.9,35c-13.8,0-25,11.2-25,25'/%3E%3Cpath class='st1' d='M61.9,35c-13.8,0-25-11.2-25-25'/%3E%3C/g%3E%3C/svg%3E") !important;
    transform: rotate(90deg) !important;
    height: 20px;
  }
  &:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Lag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 62 70' style='enable-background:new 0 0 62 70;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%236EBFFF;stroke-width:14;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%230038F0;stroke-width:14;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='61.9' y1='35' x2='-0.3' y2='35'/%3E%3Cpath class='st0' d='M61.9,35c-13.8,0-25,11.2-25,25'/%3E%3Cpath class='st1' d='M61.9,35c-13.8,0-25-11.2-25-25'/%3E%3C/g%3E%3C/svg%3E") !important;
    transform: rotate(270deg) !important;
    height: 20px;
  }
}
</style>
