// Contains routes to all views
// Views related to home- and info pages are explicitly imported here
// Import views
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/info/About.vue";
import Contact from "@/views/info/Contact.vue";
import Aime from "@/views/aime/Aime.vue";
import Icsms from "@/views/icsms/Icsms.vue";
import DataPolicy from "@/views/info/DataPolicy.vue";
import PrivacyPolicy from "@/views/info/PrivacyPolicy.vue";
import Cookies from "@/views/info/Cookies.vue";
import NotFound from "@/views/NotFound.vue";
import reportRoutes from "@/router/report/reportRoutes.js";
import legislationRoutes from "@/router/legislation/legislationRoutes.js";
import productsRoutes from "@/router/products/productsRoutes.js";
import { nextTick } from "@vue/runtime-core";

// Define routes and display namess
const baseRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      displayName: "Forside",
    },
  },
  {
    path: "/om",
    name: "About",
    component: About,
    meta: {
      displayName: "Om Produkter.dk",
    },
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: Contact,
    meta: {
      displayName: "Kontakt",
    },
  },
  {
    path: "/aime",
    name: "Aime",
    component: Aime,
    meta: {
      displayName: "AIME søgeværktøj",
    },
  },
  {
    path: "/icsms",
    name: "Icsms",
    component: Icsms,
    meta: {
      displayName: "ICSMS API",
    },
  },
  {
    path: "/dataansvar",
    name: "DataPolicy",
    component: DataPolicy,
    meta: {
      displayName: "Dataansvar",
    },
  },
  {
    path: "/privatlivspolitik",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      displayName: "Privatlivspolitik",
    },
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: Cookies,
    meta: {
      displayName: "Cookies",
    },
  },
  //catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      displayName: "Side ikke fundet",
    },
  },
];

// Create router
const router = createRouter({
  // Enable history mode
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // Scroll to top of page when navigating to new page
    document.getElementById("app").scrollIntoView();
    // Scroll to savedPosition when going back to a previously visited page
    nextTick(() => {
      if (savedPosition) {
        window.scrollTo({
          top: savedPosition.top,
          left: savedPosition.left,
          behavior: "smooth",
        });
      }
    });
  },
  // Use all routes
  routes: [...baseRoutes, ...reportRoutes, ...productsRoutes, ...legislationRoutes],
});

export default router;
