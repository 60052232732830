<template>
  <form
    class="d-flex input-group"
    style="flex-wrap: nowrap"
    @keydown.enter.prevent
    @submit.prevent
  >
    <button
      type="button"
      class="btn search-icon"
      aria-label="Søg"
      @click="search(searchTerm)"
      @keyup.enter="search(searchTerm)"
    >
      <img :src="require('/src/assets/graphics/search.svg')" alt="search.svg" />
    </button>
    <div class="form-outline flex-fill">
      <input
        type="search"
        id="searchInput"
        ref="searchInput"
        class="form-control"
        aria-label="Søg"
        :placeholder="placeholder"
        :value="searchTerm"
        @input="handleInput"
        @keyup.enter="search(searchTerm)"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["searchUpdated"],
  data() {
    return {
      searchTerm: this.$store.state.products.searchTerm,
    };
  },
  methods: {
    search(searchTerm) {
      this.$router.push({ name: "LegislationSearch",  params: { search: searchTerm },});
      this.$store.commit("legislation/setSearchTerm", { searchTerm: searchTerm });
      // this.$emit("searchUpdated");
    },
    handleInput(e) {
      // Instead of v-model, use combination of :value and @input to
      // get full functionality of dynamic search on mobile, see
      // https://github.com/vuejs/vue/issues/8231
      this.searchTerm = e.target.value;
    },
  },
};
</script>

<style>
#searchInput {
  background-color: var(--lightgrey);
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 10px 15px;
}
.btn.search-icon {
  border-radius: 10px 0px 0px 10px !important;
  background: var(--deepblue);
  margin-right: 10px;
}
</style>
