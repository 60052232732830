<template>
  <div
    class="cookies info-page"
    v-html="strapiData.content"
    role="main"
    aria-label="Cookies"
  ></div>
  <div class="cookies info-page">
    <h2>{{ strapiData.declaration_title }}</h2>
    <div
      ref="cookieDeclaration"
      role="main"
      :aria-label="strapiData.declaration_title"
    ></div>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";

export default {
  name: "Cookies",
  components: {},
  setup() {
    const { strapiData, error, load } = getStrapiData("cooky");
    load();
    return { strapiData, error };
  },
  mounted() {
    let cookieDeclaration = document.createElement("script");
    cookieDeclaration.setAttribute(
      "src",
      "https://consent.cookiebot.com/f8b58e95-30e9-47cb-991e-30fd2b5c6140/cd.js"
    );
    cookieDeclaration.setAttribute("id", "CookieDeclaration");
    cookieDeclaration.setAttribute("type", "text/javascript");
    cookieDeclaration.setAttribute("async", true);
    this.$refs.cookieDeclaration.appendChild(cookieDeclaration);
  },
};
</script>

<style>
.cookie-link-box {
  display: inline-block;
  border-radius: 10px;
  color: white;
  padding: 5px 10px 5px 10px;
  margin: 10px 0 10px 0;
  background-color: var(--mediumblue);
}
.cookie-link-box p {
  margin: 0;
}
.cookie-link-box:hover {
  background-color: var(--deepblue);
}
</style>
