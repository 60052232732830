<template>
  <router-link :to="{ name: infoCard.sub_page.data.attributes.name }" class="content-info-card">
    <div
      class="card info-card border-0 mt-3"
      role="main"
      :aria-label="infoCard.sub_page.display_name"
    >
      <div class="card-body info-card-body">
        <div class="info-card-content">
          <h2>{{ infoCard.title }}</h2>
          <p v-html="infoCard.body"></p>
        </div>
        <div class="info-card-footer">
          <button class="btn btn-primary card-btn m-1" style="font-size:small; font-family: sora-semibold;">{{ infoCard.btn_text }}</button>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["infoCard"],
};
</script>

<style lang="scss">

.card.info-card .card-img {
  height: 150px;
  object-fit: cover;
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.card.info-card .card-img-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: var(--lightblue);
  z-index: 2;
  border-radius: 0;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
}
.card-body.info-card-body {
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
}
.info-card-footer {
  display: flex;
  
}

.content-info-card .info-card-footer {
  font-size: 0.5em;
}
.content-info-card .info-card-content p {
  font-size: 0.85em;
}
.content-info-card h2 {
  font-size: 0.95em;
  padding-bottom: 20px;
  font-family: sora-bold;
}

.btn .btn-primary .card-btn {
  font-size: small;
  font-family: sora-bold;
}

// big screens
@media (min-width: 1600px) {
  .content-info-card .info-card-content p {
    font-size: 1.2em !important;
  }
  .card-body .info-card-body {
    padding: 45px;
  }
  .info-card-footer {
    justify-content: end;
}
}

// laptop
@media (min-width: 768px) {
  .content-info-card .info-card-footer {
    font-size: 0.5em;
  }
  .content-info-card .info-card-content p {
    font-size: medium;
    margin-bottom: 0;
  }
  .content-info-card h2 {
    font-size: 1.2em;
  }
  .card.info-card .card-img {
    height: 200px;
  }
  .card.info-card .card-img-overlay {
    height: 200px;
  }
  .info-card-footer {
  justify-content: end;
}
}
</style>
