<template>
  <div class="card prod-cat-card" >
    <div class="align-card">
          <div class="fixed-image-box">
            <div v-if="falseFlag" class="card-img-container">
              <img :src="require('/src/assets/graphics/placeholder.svg')" alt="Produktbillede" class="card-img" />
            </div>
          <div v-else class="card-img-container">
            <img
              :src="require('/src/assets/graphics/placeholder.svg')"
              alt="Placeholder, produktbillede"
              class="card-img"
            />
    </div>
          </div>
          <div>
            <div style="margin-left: 25px;">
              <p class="search-name">{{ searchCard.displayCategory }} > {{ searchCard.role }}</p>
              <p class="search-description">{{ ".." + searchCard.text.substring(0,160)+".." }}</p>
            </div>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  props: ["searchCard"],
  computed: {
    checked: {
      get() {
        return this.checkProductSelected(this.$store.state.legislation.selectedProducts);
      },
      set(value) {
        this.updateSelectedStore(value);
      },
    },
  },
};
</script>

<style lang="scss">

.search-name {
  font-size: 1em;
  padding: 15px 0 0 0;
  margin: 0;
  font-family: sora-semibold;
  color: black;
}
.search-description {
  font-size: 1em;
  padding: 5px 0 0 0;
  margin: 0;
  color: black;
  max-width: 650px;
}
.prod-cat-card .card-img {
  border-radius: 0px;
}
.card-img-container {
  position: relative;
  width: 100%;
}
.card-img-container .card-img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}
.checkbox{
  margin: 30px 20px;
}
.card {
  margin: 25px 0px;
}

.fixed-image-box{
  width:80px; /* or whatever width you want. */
  display: inline-block;
}

.align-card{
  display: flex; 
  align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .fixed-image-box {
    display: none;
  }
  .product-description {
    padding-right: 5px;
  }
}
</style>
