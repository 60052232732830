<template>
  <div class="question-card d-flex-lg-column" >
    <div class="question-format">
    {{ question }}
    </div>
    <div v-for="(situation, id) in answers" :key="id" class="d-flex align-items-center" style="margin: 10px;">
      <input
        type="radio"
        v-model="chosenAnswer"
        style="margin-right: 15px;"
        :value="id"
        v-bind:id="id"
      />
      <label :for="id" class="answer-format">{{ situation.title }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: String,
    },
    answers: {
      type: Object,
    },
  },
  computed: {
    chosenAnswer: {
      get() {
        return this.$store.state.legislation.selectedExtraSituation.id;
      },
      set(value) {
        console.log(value);
        this.$store.commit("legislation/setSelectedExtraSituation", {
          id: value, card:this.answers[value]
        });
      },
    },
  },

};
</script>

<style lang="scss">

.question-card {
  background-color: var(--lightgrey);
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 15px;
}

.question-format {
  margin-left: 10px;
  font-family: sora-bold;
  font-size: 1em;
  color: var(--deepblue);
}

.answer-format {
  font-family: sora-regular;
  font-size: 0.8em;
}
</style>
