<template>
  <div >
    <button
      :class="{
        'btn-next': true,
        'btn-next--not-allowed': disable,
      }"
      @click="navigateNext"
      @keyup.enter="navigateNext"
      :disable="disable"
      type="button"
      aria-label="Næste"
      class="btn btn-primary"
      style="padding: 5px 35px;"
    > Næste
    </button>
  </div>
</template>

<script>

export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigateNext() {
      if (!this.disable) {
        if (this.$route.name === "InputProducts") {
          this.$router.push({ name: "InputSituation" });
        } else if (this.$route.name === "InputSituation") {
          this.$router.push({ name: "InputGuidelines" });
        }
      }
    },
  },
};
</script>

<style>
.btn-next {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.btn-next--not-allowed {
  cursor: not-allowed !important;
}
.btn-next.opaque {
  opacity: 0.5;
  cursor: default !important;
}
.btn-next .st0 {
  fill: none;
  stroke: #6ebfff;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
.btn-next .st1 {
  fill: none;
  stroke: #0038f0;
  stroke-width: 14;
  stroke-miterlimit: 10;
}
</style>
