//Initialize default state for product listings
const getDefaultState = () => {
  return {
    productPage: 1,
    situationPage: 1,
    cardsPerPage: 8,
    totalNumberProducts: 0,
    noCategoryId: -1,
    productCards: [],
    selectedProducts: [],
    mainSituationCards:[],
    extraSituationCards:[],
    selectedMainSituation: { id:0, card:{ role:{ role: ""} } },
    selectedExtraSituation: { id:0, card:{} },
    guidelineBlocksDict: {},
    stepsProgress: [
      "InputProducts",
      "InputSituation",
      "InputGuidelines",
    ],
    stepLabels: [
      "Produkt",
      "Situation",
      "Vejledning",
    ],
    pageVisited: [
      false,
      false,
      false,
    ],
    searchTerm: "",
    selectedCategory: "",
    roleDict: {},
    updateFlag: false,
    guideRoleBox: {},
    specialRolePairDict: [],
  };
};

// Define state (allows for reset of state to default state)
const state = getDefaultState();

const mutations = {
  setProductPage(state, payload) {
    state.productPage = payload.productPage;
  },
  setSituationPage(state, payload) {
    state.situationPage = payload.situationPage;
  },
  setTotalNumberProducts(state, payload) {
    state.totalNumberProducts = payload.totalNumberProducts;
  },
  setProductCards(state, payload) {
    state.productCards = payload.productCards;
  },
  addSelectedProduct(state, payload) {
    state.selectedProducts.push(payload.id);
  },
  removeSelectedProduct(state, payload) {
    var index = state.selectedProducts.indexOf(payload.id);
    if (index > -1) { // only splice array when item is found
      state.selectedProducts.splice(index, 1); 
    }
  },
  setMainSituationCards(state, payload) {
    state.mainSituationCards = payload.mainSituationCards;
  },
  setExtraSituationCards(state, payload) {
    state.extraSituationCards = payload.extraSituationCards;
  },
  setSelectedSituation(state, payload) {
    state.selectedMainSituation.id = payload.id;
    state.selectedMainSituation.card = payload.card;
  },
  setSelectedExtraSituation(state, payload) {
    state.selectedExtraSituation.id = payload.id;
    state.selectedExtraSituation.card = payload.card;
  },
  setNoCategoryId(state, payload) {
    state.noCategoryId = payload.id;
  },
  setGuidelineBlocks(state, payload) {
    state.guidelineBlocksDict = payload.guidelineBlocksDict;
  },
  setSearchTerm(state, payload) {
    state.searchTerm = payload.searchTerm;
  },
  setSelectedCategory(state, payload) {
    state.selectedCategory = payload.selectedCategory;
  },
  setRoleDict(state, payload) {
    state.roleDict = payload.roleDict;
  },
  setUpdateFlag(state, payload) {
    state.updateFlag = payload.updateFlag;
  },
  setguideRoleBox(state, payload) {
    state.guideRoleBox = payload.guideRoleBox;
  },
  setSpecialRolePairDict(state, payload) {
    state.specialRolePairDict = payload.specialRolePairDict;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

export default { namespaced: true, state, mutations, actions };
