<template>
  <!-- 404-page with hard-coded content -->
  <!-- TODO: Refactor hard-coded content to Strapi Single type -->
  <div class="page-not-found-container" role="main">
    <div class="row d-flex justify-content-center">
      <img
        :src="require('/src/assets/graphics/Pagenotfound_illu.svg')"
        alt="Illustration, siden ikke fundet"
        style="width: 80%; max-height: 50vh"
      />
    </div>
    <div style="max-width: 100vh; margin: auto">
      <h1 class="page-title" style="margin-top: 50px; text-align: center">
        Siden blev ikke fundet
      </h1>
      <p class="page-description" style="text-align: center">
        Siden blev ikke fundet. Dette kan skyldes tekniske problemer, eller at
        siden simpelthen ikke eksisterer. Du kan gå tilbage til forsiden her:
      </p>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-primary"
        aria-label="Gå til forsiden"
        @click="$router.push({ name: 'Home' })"
        @keyup.enter="$router.push({ name: 'Home' })"
      >
        Gå til forsiden
      </button>
    </div>
  </div>
</template>
