<template>
  <div class="list-products" role="main">
    <div class="row g-4 container-list-products">
      <!-- Left panel start -->
      <div
        class="col-md-5 left-panel-full-page search-filter"
        style="padding-right: 10% !important"
        v-if="!isWindowSmall"
      >
        <SearchFunction
          :placeholder="strapiData.placeholder_search"
          @searchUpdated="
            updateProducts({ goToFirstPage: true, scrollToTop: false })
          "
        />
        <FilterPanel
          :filterTypeTitle="strapiData.filter_type_title"
          :productTypes="complianceDict"
          :filterCategoryTitle="strapiData.filter_category_title"
          :productCategories="categoryDict"
          :filterAuthorityTitle="strapiData.filter_authority_title"
          :authorityNames="authorityNames"
          @filtersUpdated="
            updateProducts({ goToFirstPage: true, scrollToTop: false })
          "
          :isWindowSmall="isWindowSmall"
          :clearFiltersTitle="strapiData.clear_filters_title"
        />
        <div class="row container-more-info py-3">
          <ArrowTitle class="mb-3" :title="strapiData.more_info_title" />
          <div class="info-page" v-html="strapiData.more_info_body"></div>
          <div class="download-csv-container mt-2 d-flex justify-content-start">
            <button
              type="button"
              class="btn btn-secondary"
              style="margin-left: 32px"
              @click="getCSVFile"
              @keyup.enter="getCSVFile"
            >
              Download CSV
            </button>
          </div>
        </div>
      </div>

      <!-- Left panel end -->
      <!-- Right panel -->
      <div class="col-md-7 right-panel-full-page">
        <h1 class="page-title">{{ strapiData.title }}</h1>
        <p>{{ strapiData.description_01 }}</p>
        <p>{{ strapiData.description_02 }}</p>

        <Accordion
          :buttonText="strapiData.accordion_more_info_btn"
          :bodyText="strapiData.accordion_more_info_text"
        />

        <div v-if="isWindowSmall">
          <SearchFunction
            :placeholder="strapiData.placeholder_search"
            @searchUpdated="
              updateProducts({ goToFirstPage: true, scrollToTop: false })
            "
            style="margin-top: 40px"
          />
          <FilterModal
            :title="strapiData.filter_pop_up_title"
            btnText="Gem"
            :isWindowSmall="isWindowSmall"
            :clearFiltersTitle="strapiData.clear_filters_title"
            @filtersUpdated="
              updateProducts({ goToFirstPage: true, scrollToTop: false })
            "
          >
            <FilterPanel
              :filterTypeTitle="strapiData.filter_type_title"
              :productTypes="complianceDict"
              :filterCategoryTitle="strapiData.filter_category_title"
              :productCategories="categoryDict"
              :filterAuthorityTitle="strapiData.filter_authority_title"
              :authorityNames="authorityNames"
              @filtersUpdated="
                updateProducts({ goToFirstPage: true, scrollToTop: false })
              "
              :isWindowSmall="isWindowSmall"
              :clearFiltersTitle="strapiData.clear_filters_title"
            />
          </FilterModal>
        </div>

        <FetchError v-if="fetchError" class="mt-5" />
        <ProductCardList
          v-else
          :productCards="productCards"
          :loading="loadingProducts"
        />

        <div
          class="pagination-container d-flex justify-content-center"
          v-if="productCards.length > 0 && !loadingProducts"
        >
          <VPagination
            v-model="page"
            :pages="getNumberPages"
            :range-size="1"
            @update:modelValue="
              updateProducts({ goToFirstPage: false, scrollToTop: true })
            "
            :hideFirstButton="true"
            :hideLastButton="true"
          />
        </div>
        <NoResultsBox
          :title="strapiData.no_search_results_title"
          :subTitle="strapiData.no_search_results_sub_title"
          :body="strapiData.no_search_results_body"
          :loading="loadingProducts"
          v-else-if="
            !(productCards.length > 0) &&
            ($store.state.products.searchTerm ||
              $store.state.products.filtersInput.complianceFilters.length > 0 ||
              $store.state.products.filtersInput.categoryFilters.length > 0 ||
              $store.state.products.filtersInput.authorityFilters.length > 0)
          "
        />
        <div v-if="isWindowSmall">
          <div class="row container-more-info py-3">
            <ArrowTitle class="mt-4" :title="strapiData.more_info_title" />
            <div class="info-page" v-html="strapiData.more_info_body"></div>
            <div
              class="download-csv-container mt-2 d-flex justify-content-start"
            >
              <button
                type="button"
                class="btn btn-secondary"
                style="margin-left: 32px"
                @click="getCSVFile"
                @keyup.enter="getCSVFile"
              >
                Download CSV
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Right panel end -->
    </div>
    <BackToTopBtn :btnText="strapiData.back_to_top_btn" />
  </div>
</template>

<script>
import Accordion from "@/components/products/Accordion.vue";
import ProductCardList from "@/components/products/ProductCardList.vue";
import VPagination from "@hennge/vue3-pagination";
import getStrapiData from "@/composables/getStrapiDataSingle";
import getStrapiDataCollection from "@/composables/getStrapiDataCollection";
import getFilters from "@/composables/getFilters";
import SearchFunction from "@/components/products/SearchFunction.vue";
import ArrowTitle from "@/components/widgets/ArrowTitle.vue";
import FilterPanel from "@/components/products/FilterPanel.vue";
import FilterModal from "@/components/products/FilterModal.vue";
import axios from "axios";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";
import BackToTopBtn from "@/components/widgets/BackToTopBtn.vue";
import FetchError from "@/components/FetchError.vue";
import { ref } from "vue";
import NoResultsBox from "@/components/products/NoResultsBox.vue";
import { useStore } from "vuex";

async function getComplianceFilters() {
  const {
    filterData: productTypes,
    error: errorProductTypes,
    load: loadProductTypes,
  } = getFilters("compliance-type");

  const {
    strapiData: productTypeDisplay,
    error: errorProductTypeDisplay,
    load: loadProductTypeDisplay,
  } = getStrapiDataCollection("compliance-types");

  await loadProductTypeDisplay().then(() => {
    if (errorProductTypeDisplay.value) {
      console.log(errorProductTypeDisplay.value);
    }
  });
  await loadProductTypes().then(() => {
    if (errorProductTypes.value) {
      console.log(errorProductTypes.value);
    }
  });

  // Initialize dictionary
  let complianceDict = ref({});

  if (productTypeDisplay.value.length > 0) {
    productTypeDisplay.value.forEach((strapi) => {
      if (!(strapi.display_name_plural in complianceDict.value)) {
        // Populate dictionary with keys and correct structure
        complianceDict.value[strapi.display_name_plural] = {
          wzCodes: [],
          wzIds: [],
        };
      }
      if (productTypes.value.length > 0) {
        productTypes.value.forEach((wz) => {
          if (strapi.wz_code.toLowerCase() == wz.name.toLowerCase()) {
            // Push wz_codes and wz_ids to dictionary
            complianceDict.value[strapi.display_name_plural].wzCodes.push(
              wz.name
            );
            complianceDict.value[strapi.display_name_plural].wzIds.push(wz.id);
          }
        });
      }
      if (
        complianceDict.value[strapi.display_name_plural].wzCodes.length === 0 &&
        complianceDict.value[strapi.display_name_plural].wzIds.length === 0
      ) {
        delete complianceDict.value[strapi.display_name_plural];
      }
    });
  }
  return { complianceDict };
}

async function getCategoryFilters() {
  const {
    filterData: productCategories,
    error: errorProductCategories,
    load: loadProductCategories,
  } = getFilters("product-category-type");

  const {
    strapiData: productCategoryDisplay,
    error: errorProductCategoryDisplay,
    load: loadProductCategoryDisplay,
  } = getStrapiDataCollection("product-category-displays");

  // Initialize dictionary
  let categoryDict = ref({});

  await loadProductCategories().then(() => {
    if (errorProductCategories.value) {
      console.log(errorProductCategories.value);
    }
  });
  await loadProductCategoryDisplay().then(() => {
    if (errorProductCategoryDisplay.value) {
      console.log(errorProductCategoryDisplay.value);
    }
  });

  // Create dictionary
  if (productCategoryDisplay.value.length > 0) {
    productCategoryDisplay.value.forEach((strapi) => {
      if (!(strapi.display_name in categoryDict.value)) {
        // Populate dictionary with keys and correct structure
        categoryDict.value[strapi.display_name] = {
          wzCodes: [],
          wzIds: [],
        };
      }
      if (strapi.product_categories.data.length > 0) {
        strapi.product_categories.data.forEach((category) => {
          console.log(category);
          if (productCategories.value.length > 0) {
            productCategories.value.forEach((wz) => {
              if (category.attributes.wz_code.toLowerCase() == wz.name.toLowerCase()) {
                // Push wz_codes and wz_ids to dictionary
                categoryDict.value[strapi.display_name].wzCodes.push(wz.name);
                categoryDict.value[strapi.display_name].wzIds.push(wz.id);
              }
            });
          }
        });
      }
      if (
        categoryDict.value[strapi.display_name].wzCodes.length === 0 &&
        categoryDict.value[strapi.display_name].wzIds.length === 0
      ) {
        delete categoryDict.value[strapi.display_name];
      }
    });
  }
  return { categoryDict };
}

function getAuthorityFilters() {
  const {
    filterData: authorityNames,
    error: errorAuthorityNames,
    load: loadAuthorityNames,
  } = getFilters("source-type");

  loadAuthorityNames().then(() => {
    if (errorAuthorityNames.value) {
      console.log(errorAuthorityNames.value);
    }
  });

  return { authorityNames };
}

export default {
  name: "ListProducts",
  components: {
    Accordion,
    ProductCardList,
    VPagination,
    SearchFunction,
    ArrowTitle,
    FilterPanel,
    FilterModal,
    BackToTopBtn,
    FetchError,
    NoResultsBox,
  },
  data() {
    return {
      csvData: "",
      isWindowSmall: true,
      fetchError: false,
      loadingProducts: false,
    };
  },
  setup() {
    const store = useStore();

    const { strapiData, error, load } = getStrapiData("list-product");
    load();

    if (Object.keys(store.state.products.complianceDict).length === 0) {
      let complianceDict = ref({});

      getComplianceFilters().then((data) => {
        complianceDict.value = data.complianceDict.value;
        store.commit("products/setComplianceDict", {
          complianceDict: complianceDict,
        });
      });
    }

    if (Object.keys(store.state.products.categoryDict).length === 0) {
      let categoryDict = ref({});

      getCategoryFilters().then((data) => {
        categoryDict.value = data.categoryDict.value;
        store.commit("products/setCategoryDict", {
          categoryDict: categoryDict,
        });
      });
    }

    return {
      strapiData,
      error,
      ...getAuthorityFilters(),
    };
  },
  watch: {
    // Watching dictionaries to not call API more often than necessary
    // and in order to not doing it until both dictionaries are populated
    categoryDict() {
      if (
        !this.$store.state.products.productCards.length > 0 &&
        Object.keys(this.complianceDict).length > 0
      ) {
        this.updateProducts({ goToFirstPage: false });
      }
    },
    complianceDict() {
      if (
        !this.$store.state.products.productCards.length > 0 &&
        Object.keys(this.categoryDict).length > 0
      ) {
        this.updateProducts({ goToFirstPage: false });
      }
    },
    productCards() {
      if (this.productCards.length > 0) {
        this.productCards.forEach((element) => {
          element.complianceType.name = this.getDisplayName(
            element.complianceType.name,
            this.complianceDict
          );
          const productCategoryNames = element.productCategoryRelations.map(
            (relation) => {
              if (relation.productCategoryType) {
                return this.getDisplayName(
                  relation.productCategoryType.name,
                  this.categoryDict
                );
              }
            }
          );
          element.productCategoryNames = productCategoryNames;
        });
      }
      this.loadingProducts = false;
    },
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
  },
  methods: {
    getDisplayName(wzCode, dict) {
      if (!(Object.keys(dict).length === 0)) {
        for (const [key, value] of Object.entries(dict)) {
          if (value.wzCodes.includes(wzCode)) {
            return key;
          }
        }
      } else {
        return wzCode;
      }
    },
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth < 768;
    },
    getCSVFile() {
      console.log("Total number of products:", this.totalNumberProducts);
      const callObject = JSON.stringify({
        page: this.page,
        //NOTE: total number of products used here to get all results returned
        pageSize: this.$store.state.products.totalNumberProducts,
        searchText: this.$store.state.products.searchTerm,
        productCategoryTypeIds: this.parseStrings(
          this.$store.state.products.filtersInput.categoryFilters
        ),
        complianceTypeIds: this.parseStrings(
          this.$store.state.products.filtersInput.complianceFilters
        ),
        sourceTypeIds: this.$store.state.products.filtersInput.authorityFilters,
      });

      //Options for json2csv conversion, see https://github.com/mrodrig/json-2-csv/wiki/json2csv-Documentation
      let options = { keys: null, excelBOM: true };

      axios
        .post(process.env.VUE_APP_PRODUCTS_API + "product/search", callObject, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(
            "Response from " + process.env.VUE_APP_PRODUCTS_API,
            response
          );
          let data = response.data.results;
          if (data.length > 0) {
            data.forEach((element) => {
              if (element.reason.length > 0) {
                element.reason = this.strip(element.reason);
              }
            });
          }
          json2csv(
            data,
            (err, csv) => {
              if (!err) {
                console.log("CSV file generated");
                let blob = new Blob([csv], { type: "text/csv" });
                saveAs(blob, "produkterdk_farlige_mangelfulde.csv");
              }
            },
            options
          );
        })
        .catch((error) => {
          console.log(
            "Response from " + process.env.VUE_APP_PRODUCTS_API,
            error
          );
        });
    },
    strip(html) {
      let doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    },
    updateProducts(event) {
      this.loadingProducts = true;
      if (event.goToFirstPage) {
        this.page = 1;
      }
      if (event.scrollToTop) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      const callObject = JSON.stringify({
        page: this.page,
        pageSize: this.productsPerPage,
        searchText: this.$store.state.products.searchTerm,
        productCategoryTypeIds: this.parseStrings(
          this.$store.state.products.filtersInput.categoryFilters
        ),
        complianceTypeIds: this.parseStrings(
          this.$store.state.products.filtersInput.complianceFilters
        ),
        sourceTypeIds: this.$store.state.products.filtersInput.authorityFilters,
      });

      console.log(callObject);

      axios
        .post(process.env.VUE_APP_PRODUCTS_API + "product/search", callObject, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(
            "Response from " + process.env.VUE_APP_PRODUCTS_API,
            response
          );
          this.totalNumberProducts = response.data.rowCount;
          this.productCards = response.data.results;
        })
        .catch((error) => {
          console.log(
            "Response from " + process.env.VUE_APP_PRODUCTS_API,
            error
          );
          this.fetchError = true;
        });
    },
    parseStrings(stringArray) {
      let numberArray = [];
      if (stringArray.length > 0) {
        stringArray.forEach((element) => {
          if (element) {
            element.split(",").map((item) => numberArray.push(Number(item)));
          }
        });
      }
      return numberArray;
    },
  },
  computed: {
    getNumberPages() {
      return Math.ceil(this.totalNumberProducts / this.productsPerPage);
    },
    page: {
      get() {
        return this.$store.state.products.page;
      },
      set(value) {
        this.$store.commit("products/setPage", { page: value });
      },
    },
    totalNumberProducts: {
      get() {
        return this.$store.state.products.totalNumberProducts;
      },
      set(value) {
        this.$store.commit("products/setTotalNumberProducts", {
          totalNumberProducts: value,
        });
      },
    },
    complianceDict: {
      get() {
        return this.$store.state.products.complianceDict;
      },
    },
    categoryDict: {
      get() {
        return this.$store.state.products.categoryDict;
      },
    },
    productCards: {
      get() {
        return this.$store.state.products.productCards;
      },
      set(value) {
        this.$store.commit("products/setProductCards", {
          productCards: value,
        });
      },
    },
    productsPerPage: {
      get() {
        return this.$store.state.products.productsPerPage;
      },
    },
  },
};
</script>

<style lang="scss">
.pagination-container .Pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pagination-container .PaginationControl {
  display: flex;
  align-items: center;
}
.pagination-container .Control {
  position: relative;
  display: block;
  width: 26px;
  height: 22px;
  fill: var(--darkergrey);

  &-active {
    fill: var(--deepblue);
    cursor: pointer;
  }
  &-active:hover {
    color: var(--deepblue);
    transform: scale(1.2);
  }
  &-active:focus {
    color: var(--deepblue);
  }
}
.pagination-container .Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 0 2px;
  color: var(--darkergrey);
  background-color: var(--lightgreyopaque);
  border-radius: 3px;
  border-color: transparent;
  cursor: pointer;
  outline: 0;
  user-select: none;
  font-family: sora-semibold;
  font-size: 1em;

  &-active {
    color: var(--deepblue);
    background-color: transparent !important;
    text-decoration: underline;
  }
  &:hover {
    color: var(--deepblue);
    transform: scale(1.2);
  }
  &:focus {
    color: var(--deepblue);
  }
}
.pagination-container .DotsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 0 2px;
}
.pagination-container .Dots {
  width: 12px;
  fill: var(--deepblue);
}
.pagination-container li {
  background-color: var(--white);
  padding: 10px;
  margin: 5px;
}
</style>
