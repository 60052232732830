<template>
    <div class="col p-0 " v-if="show" style="height:25px; max-width: 6px; background-color: var(--deepblue)"></div>
    <div class="col p-0 invisible" v-else style="height:25px; max-width: 6px; background-color: var(--deepblue)"></div>
</template>

<script>
export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      }
    },
}
</script>