/**
 * Flatten object to array to match v3 data format. 
 *
 * @param {object} objectGroup
 * @returns {Array} .
 */
function normaliseData (objectGroup) {
    if(objectGroup === null || objectGroup.data === null) return;

    let allData = [];
     // one level data  
    if(objectGroup.data.attributes) {
        // return {}
        allData = objectGroup.data.attributes;
        allData.id = objectGroup.data.id; 
        
    } else if(objectGroup != undefined && objectGroup.data != undefined) {
        // make id same level as attributes to not change too many code
        objectGroup.data.forEach(element => {
            let oneEntry = element.attributes;
            oneEntry.id = element.id;
            allData.push(oneEntry);
        });
    }

    return allData;
  }

export default normaliseData
