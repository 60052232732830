// Contains routes to all views related to dangerous and deficient products
// Import views
import ListProducts from "@/views/products/ListProducts";
import ProductInfo from "@/views/products/ProductInfo";

// Define routes and display names
const productsRoutes = [
  {
    path: "/mangelfuldeprod",
    name: "ListProducts",
    component: ListProducts,
    meta: {
      displayName: "Se farlige og mangelfulde produkter",
    },
  },
  {
    // Use unique id from database as route
    path: "/mangelfuldeprod/produkt/:productId",
    props: true,
    name: "ProductInfo",
    component: ProductInfo,
    meta: {
      displayName: "Produktinformation",
    },
  },
];

export default productsRoutes;
